import { LAYOUT, MENU_BEHAVIOUR, NAV_COLOR, MENU_PLACEMENT, RADIUS, THEME_COLOR, USER_ROLE } from '../constants.js';

export const IS_DEMO = false;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = true;

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
  defaultTitle: 'AI Vetting',
  titleTemplate: '%s | AI Vetting',
  copyrightCompany: 'Spectrum Network Intl',
  brandLogo: '/img/logo/logo-white.png',
  logo: '/img/logo/logo-white.png',
  background: '',
  countryCode: '+254',
  currency: 'Kes.',
  dayFormat: 'dd',
  dayMonthFormat: 'MMM dd',
  yearMonthFormat: 'MMM yyyy',
  yearFormat: 'yyyy',
  dateFormat: 'yyyy-MM-dd',
  dateFormatUK: 'dd MMM yyyy',
  dateTimeFormat: 'yyyy-MM-dd hh:mm a',
  dateTimeFormatUK: 'dd MMM yyyy hh:mm a',
  dateMomentFormat: 'YYYY-MM-DDTHH:mm:ss',
  timeFormat: 'hh:mm a',
  pageSize: 10
};

export const DEFAULT_PATHS = {
  APP: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  TWO_FACTOR: '/two-factor',
  CHANGE_MY_PASSWORD: '/change-my-password',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  USER_WELCOME: '/dashboard/home',
  NOTFOUND: '/page-not-found',
  UNAUTHORIZED: '/unauthorized',
  INVALID_ACCESS: '/invalid-access',
  SIGN_CONSENT: '/sign-consent/:candidateID'
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Vertical,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Pinned,
  LAYOUT: LAYOUT.Boxed,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.LightGreen,
  NAV_COLOR: NAV_COLOR.LightGreen,
  USE_SIDEBAR: true,
};

export const DEFAULT_USER = {
  id: 1,
  name: 'Kemmy Duck',
  thumb: '/img/profile/profile-2.webp',
  role: USER_ROLE.Admin,
  email: 'kemmyduck@gmail.com',
};

export const REDUX_PERSIST_KEY = 'aivetting-1';
export const USER_LOGOUT_ACTION_KEY = 'USER_LOGOUT';
export const SELECTED_CUSTOMER_KEY = 'selected-customer-id';
export const TENANT_KEY = 'x-tenant-id'; 