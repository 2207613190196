import React, { useEffect, useState } from 'react';
import { Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import moment from 'moment';
import axios from 'axios';
import { RelativeTimes } from 'constants.js';

export const dateDifference = ({startDate,endDate,cycleType}) => {
    const a = moment((startDate instanceof Date)? startDate : new Date(startDate));
    const b = moment((endDate instanceof Date)? endDate : new Date(endDate));
    return startDate && endDate? b.diff(a, cycleType) :  0;
}

export const addDaysToDate = ({date,cycle}) => {
    const {cycleType=null, cycleCount=0} = cycle;
    const dateObject = (date instanceof Date)? date : new Date(date);
    return date && cycleType && cycleCount>0
        ? new Date(moment(dateObject)?.add(cycleCount, cycleType)?.format('YYYY-MM-DDTHH:mm:ss')) 
        : null;
}

export const yearsBefore = (limit)=>{
    let years = [];
    let max = (new Date()).getFullYear();
    let min = max - limit;

    for (let i = max; i > min; i--) {
      years.push(i)
    }	 
    return years;
}

export const getDays = (lastDay)=>{
    let days = [];

    for (let i = 1; i <= lastDay; i++) {
      days.push(i)
    }	 
    return days;
}

export const getContinents = async() => {
    const {data} = await axios.get('/json/countries.min.json'); 
    const {continents} = data;
    return continents;
}

export const getCountries = async() => {
    const {data} = await axios.get('/json/countries.min.json'); 
    const {countries} = data;
    return countries;
}

export const getNationalities = async() => {
    const {data} = await axios.get('/json/nationalities.min.json'); 
    return data;
}

export const getCurrencies = async() => {
    const {data} = await axios.get('/json/currencies.min.json');
    return data;
}
export const getMonths = async() => {
    return axios.get('/json/months.min.json');
}

export const getRelativeTimes = () => new Map([
    [ RelativeTimes.CustomDate, { period_span: 'D',ranges: null} ],
    [ RelativeTimes.Today, { period_span: 'D',ranges: [moment(), moment()] } ],
    [ RelativeTimes.Yesterday, { period_span: 'D',ranges: [moment().subtract(1, 'days'), moment().subtract(1, 'days')] } ],
    [ RelativeTimes.Last7Days,  { period_span: 'W',ranges: [moment().subtract(6, 'days'), moment()] } ],
    [ RelativeTimes.ThisWeek,  { period_span: 'W',ranges: [moment().startOf('week'), moment().endOf('week')] } ],
    [ RelativeTimes.LastWeek,  { period_span: 'W',ranges: [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')] } ],
    [ RelativeTimes.ThisMonth,  { period_span: 'M',ranges: [moment().startOf('month'), moment().endOf('month')] } ],
    [ RelativeTimes.LastMonth,  { period_span: 'M',ranges: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')] } ],
    [ RelativeTimes.ThisYear,  { period_span: 'Y',ranges: [moment().startOf('year'), moment().endOf('year')] } ],
    [ RelativeTimes.LastYear, { period_span: 'Y',ranges: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')] } ]
]);

export const setPeriodSpan = (filter,period_span,time_range,time_key)=>{ 
    let timeJson = JSON.parse('{"' + time_key + '":"0"}');			
    if(time_range) 
        timeJson[time_key] = time_range;
    else
        timeJson[time_key] = {startDate:null,endDate:null};
    
    let filter_span = (time_range && !period_span)? {period_span:'X'} : {period_span:period_span};
                
    Object.assign(filter,filter_span,timeJson);  
    
    return true;
}

export const getRemoteFilter = (filter) => {
    let object = {};
    for(let key in filter)
    {
        let item = filter[key];
        if(item)
        {
            item = (item==='#' || item===':' || item==='/' || item==='\\')? encodeURIComponent(item) : item;
            let child = null;
            
            if(key==='global')
            {
                child = {type : 'global', value : item};
            }
            else if(item.startDate && item.endDate && item.startDate._i && item.endDate._i)
            {
                child = {type : 'daterange', value : [item.startDate.unix(),item.endDate.unix()] };
            }
            else if(item.range && (item.range.lower || item.range.upper))
            {
                child = {type : 'range', value : [item.range.lower,item.range.upper] };
            }
            else if((item && typeof item === 'string') || !isNaN(item) || Array.isArray(item))
            {
                child = {type : 'val', value : item};
            }
            
            if(child) object[key] = child;
        }
    } 
    return object;
}

export const getMongoDateRange = (filter,dateField,greaterFn='gte',lesserFn='lte') => {
    return (
        filter[dateField]?.value[0]
        ? { 
          and: [
            {
              [dateField]: {
                [greaterFn]: new Date(filter[dateField]?.value[0]*1000)
              }
            },
            {
              [dateField]: {
                [lesserFn]: new Date(filter[dateField]?.value[1]*1000)
              }
            }
          ]
        }
        : {}
    );
}

export const getDatesArray = (date,nx,daysCount,isInitial)=>{
    isInitial = (typeof isInitial !=='undefined')? isInitial : false;
    let values=[];
    let i = 0;
    let daysInMs = 86400000;
    let time = (new Date(date)).getTime() + (isInitial? daysInMs : 0);
    
    if(nx){
        time = time + (daysInMs*1);
    }else{
        time = time - (daysInMs*daysCount);
    }
    
    for(i=0;i<daysCount;i++){
        let dt = time + (i*daysInMs);
        values.push(new Date(dt));
    }
    
    return values;
}

export const DateSearchWidget = (props) => {
    const { formatMessage: f } = useIntl();
    const relativeTimes = getRelativeTimes();
    const years = yearsBefore(10);
    const {dateField} = props;
    const [months,setMonths] = useState([]); 
    const [days,setDays] = useState([]); 
    const [selectedYear,setSelectedYear] = useState(null);
    const [selectedMonth,setSelectedMonth] = useState(null);
    const [selectedDay,setSelectedDay] = useState(null);
    const [selectedRelativeTime,setSelectedRelativeTime] = useState(null);

    useEffect(()=>{ initDateWidget(); getMonths().then(({data})=> setMonths(data)); },[dateField]);

    const initDateWidget = ()=>{
        const t = relativeTimes.get(RelativeTimes.ThisYear);
        let obj = {period_span: t.period_span};
        obj[dateField] = {startDate: t.startDate, endDate: t.endDate};
        
        setSelectedRelativeTime(RelativeTimes.ThisYear);
        setSelectedYear(null);
        setSelectedMonth(null);
        setSelectedDay(null);

        props.setDateRange(obj);
    };

    const onTriggerYear = (e,year) => {
        let obj = {period_span:'Y'};
        if(year && Number(year)>0){ 
            let startDate = new moment(new Date(year + '-01-01T00:00:01'));
            let endDate = new moment(new Date(year + '-12-31T11:59:59'));
            obj[dateField] = {startDate:startDate,endDate:endDate};
            
            setSelectedYear(year);
            setSelectedMonth(null);
            setSelectedDay(null);

            props.setDateRange(obj);
        }
        e.preventDefault();
    };

    const onTriggerMonth = (e,month) => { 
        let obj = {period_span:'M'};
        if(selectedYear && month && Number(selectedYear)>0 && Number(month)>=0){
            let lastDay = (String((new Date(selectedYear, Number(month), 0)).getDate())).padStart(2,'0');
            month = (String(month)).padStart(2,'0'); 	
            let startDate = new moment(new Date(selectedYear + '-' + month + '-01T00:00:01'));
            let endDate = new moment(new Date(selectedYear + '-' + month + '-' + lastDay + 'T11:59:59'));
            obj[dateField] = {startDate:startDate,endDate:endDate};
            
            setSelectedMonth(Number(month));
            setSelectedDay(null);
            setDays(getDays(lastDay));

            props.setDateRange(obj);
        }else{
            onTriggerYear(e,selectedYear);
        }
        e.preventDefault();
    };

    const onTriggerDay = (e,day) => { 
        let obj = {period_span:'D'};
        if(selectedYear && selectedMonth && Number(selectedYear)>0 && Number(selectedMonth)>=0 && Number(day)>0){
            day = (String(day)).padStart(2,'0'); 	
            let startDate = new moment(new Date(selectedYear + '-' + selectedMonth + '-' + day + 'T00:00:01'));
            let endDate = new moment(new Date(selectedYear + '-' + selectedMonth + '-' + day + 'T11:59:59'));
            obj[dateField] = {startDate:startDate,endDate:endDate};
            
            setSelectedDay(day);
            props.setDateRange(obj);
        }else{
            onTriggerMonth(e,selectedMonth);
        }
        e.preventDefault();
    };

    const onTriggerRelativeTime = (e,relativeTime,ranges) => { 
        if(relativeTime===RelativeTimes.CustomDate)
        {
            setSelectedRelativeTime(relativeTime); 
            return;
        }
        else
        {
            let month1 = (String(ranges[0]?.month()+1)).padStart(2,'0');
            let month2 = (String(ranges[1]?.month()+1)).padStart(2,'0');
            let year1 = ranges[0]?.year();
            let year2 = ranges[1]?.year();
            let day1 = (String(ranges[0]?.date())).padStart(2,'0');
            let day2 = (String(ranges[1]?.date())).padStart(2,'0');
            let obj = {period_span: relativeTimes.get(relativeTime)?.period_span};

            if(Number(year1)>=0 && Number(year2)>=0 && Number(month1)>=0 && Number(month2)>=0){
                let startDate = new moment(new Date(year1 + '-' + month1 + '-' + day1 + 'T00:00:01'));
                let endDate = new moment(new Date(year2 + '-' + month2 + '-' + day2 + 'T11:59:59'));
                obj[dateField] = {startDate:startDate,endDate:endDate}; 
                
                setSelectedRelativeTime(relativeTime);
                props.setDateRange(obj); 
            }else{
                onTriggerYear(e,selectedYear);
            }
        }
        e.preventDefault();
    };

    return (
        <Row>       
            <div className="gap-1 d-flex d-sm-flex justify-content-end">
              <DropdownButton as="div" title={f({id: (selectedRelativeTime || 'timespan.today')})}>
                {
                    Array.from(relativeTimes.keys()).map(d=>
                        <Dropdown.Item key={d} onClick={($e)=>onTriggerRelativeTime($e,d,relativeTimes.get(d).ranges)}>{f({id:d})}</Dropdown.Item>
                    )
                }
              </DropdownButton>
              {
                (selectedRelativeTime===RelativeTimes.CustomDate) &&
                <div className='gap-1 d-flex d-sm-flex'>
                    <DropdownButton as="div" title={selectedYear || f({id: 'select-year'}) }>
                        {
                            years.map(year=>
                                <Dropdown.Item key={year} onClick={($e)=>onTriggerYear($e,year)}>{year}</Dropdown.Item>
                            )
                        }
                    </DropdownButton>
                    <DropdownButton as="div"  title={months[selectedMonth]?.short || f({id: 'select-month'}) }>
                        {
                            Object.keys(months).map(m=>
                                <Dropdown.Item key={m} onClick={($e)=>onTriggerMonth($e,m)}>{months[m]?.name}</Dropdown.Item>
                            )
                        }
                    </DropdownButton>
                    <DropdownButton as="div"  title={selectedDay || f({id: 'select-day'}) }>
                        {
                            days.map(d=>
                                <Dropdown.Item key={d} onClick={($e)=>onTriggerDay($e,d)}>{d}</Dropdown.Item>
                            )
                        }
                    </DropdownButton>
                </div>
              }
            </div>
        </Row>
    );
}