import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "httpService";

export const authenticate = createAsyncThunk('auth/login', async (params) => {
    const data = {
        email: params.username,
        password: params.password
    }; 
    return httpService.post('login', data);
});

export const signup = createAsyncThunk('auth/signup', async (params) => {
    const data = {
        ...params,
        username: (params.channel=='sms')? params.phoneNo : params.email,
        otpOn: 'Y',
        otpValidated: 'N'
    }	
    return httpService.post('signup', data);
});


export const resetCode = createAsyncThunk('auth/reset-code', async ({data, channel}) => {
    return httpService.post('reset-code',{...data,channel: channel});
});

export const validateOTP = createAsyncThunk('auth/validate-otp', async ({data, channel}) => {
    return httpService.post('validate-otp',{...data, channel: channel});
});

export const resetOTP = createAsyncThunk('auth/reset-otp', async ({data, channel}) => {
    return httpService.post('reset-otp',{...data, channel: channel});
});

export const changeForgottenPassword = createAsyncThunk('auth/change-forgotten-password', async ({data, channel}) => {
    return httpService.post('change-forgotten-password',{...data, channel: channel});
});

export const changeLoggedInPassword = createAsyncThunk('auth/change-logged-in-password', async (data) => {
    return httpService.post('change-logged-in-password',data);
});