import { createSlice } from '@reduxjs/toolkit';
import { getArrearsByCategory, getArrearsMetrics, getCollectionsMetrics, getPropertyMetrics } from 'actions/analytics';
import { BccStatus } from 'constants.js';

const initialState = {
  collectionsMetrics: {
    isLoading: false,
    error: '',
    message: 'OK',
    data: {
      count: 123,
      volume: 600000,
      pending: 3,
      reconciled: 120,
      fees: 4500
    }
  },
  arrearsMetrics: {
    isLoading: false,
    error: '',
    message: 'OK',
    data: {}
  },
  arrearsByCategory: {
    isLoading: false,
    error: '',
    message: 'OK',
    data: {}
  },
  propertyMetrics: {
    isLoading: false,
    error: '',
    message: 'OK',
    data: {
      tenants: 100,
      arrears: 45000,
      collected: 159000,
      occupied: 56,
      vacant: 4
    }
  },
  candidateStatusMetrics: {
    isLoading: false,
    error: '',
    message: 'OK',
    data: [
      { status: BccStatus.Clear, value: 250},
      { status: BccStatus.WithAlerts, value: 5},
      { status: BccStatus.AwaitingInfo, value: 128},
      { status: BccStatus.MissingInfo, value: 16},
      { status: BccStatus.Processing, value: 139},
      { status: BccStatus.UnableToVerify, value: 2},
    ]
  },
}

const analyticSlice = createSlice({
  name: 'analytic',
  initialState: initialState,
  reducers: {
    setPropertyMetrics: (state, action) => { 
      state.propertyMetrics.data = (action.payload || state.propertyMetrics.data);
    },
    setCollectionsMetrics: (state, action) => {
      state.collectionsMetrics.data = (action?.payload || state.collectionsMetrics.data);
    },
    setArrearsMetrics: (state, action) => {
      state.arrearsMetrics.data = (action?.payload || state.arrearsMetrics.data);
    },
    setArrearsByCategory: (state, action) => {
      state.arrearsByCategory.data = (action?.payload || state.arrearsByCategory.data);
    },
    setCandidateStatusMetrics: (state, action) => {
      state.candidateStatusMetrics.data = (action?.payload || state.candidateStatusMetrics.data);
    },
  },
  extraReducers: (builder) => {
    builder
          .addCase(getPropertyMetrics.pending,(state, action) => { state.propertyMetrics = {...state.propertyMetrics, isLoading: true, error: '', message: 'Processing..'}})
          .addCase(getPropertyMetrics.fulfilled, (state, action) => { state.propertyMetrics = {...state.propertyMetrics, isLoading: false, message: 'Done' } })
          .addCase(getPropertyMetrics.rejected, (state, action) => {state.propertyMetrics = {...state.propertyMetrics, isLoading: false, message:'', error: action.error.message}})
          
          .addCase(getCollectionsMetrics.pending,(state, action) => { state.collectionsMetrics = {...state.collectionsMetrics, isLoading: true, error: '', message: 'Processing..'}})
          .addCase(getCollectionsMetrics.fulfilled, (state, action) => { state.collectionsMetrics = {...state.collectionsMetrics, isLoading: false, message: 'Done' } })
          .addCase(getCollectionsMetrics.rejected, (state, action) => {state.collectionsMetrics = {...state.collectionsMetrics, isLoading: false, message:'', error: action.error.message}})    
          
          .addCase(getArrearsMetrics.pending,(state, action) => { state.arrearsMetrics = {...state.arrearsMetrics, isLoading: true, error: '', message: 'Processing..'}})
          .addCase(getArrearsMetrics.fulfilled, (state, action) => { state.arrearsMetrics = {...state.arrearsMetrics, isLoading: false, message: 'Done' } })
          .addCase(getArrearsMetrics.rejected, (state, action) => {state.arrearsMetrics = {...state.arrearsMetrics, isLoading: false, message:'', error: action.error.message}})
          
          .addCase(getArrearsByCategory.pending,(state, action) => { state.arrearsByCategory = {...state.arrearsByCategory, isLoading: true, error: '', message: 'Processing..'}})
          .addCase(getArrearsByCategory.fulfilled, (state, action) => { state.arrearsByCategory = {...state.arrearsByCategory, isLoading: false, message: 'Done' } })
          .addCase(getArrearsByCategory.rejected, (state, action) => {state.arrearsByCategory = {...state.arrearsByCategory, isLoading: false, message:'', error: action.error.message}});  
  }
});

const analyticReducer = analyticSlice.reducer;
export const { setPropertyMetrics, setCollectionsMetrics, setArrearsMetrics, setArrearsByCategory, setCandidateStatusMetrics } = analyticSlice.actions;
export default analyticReducer;