import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';
import langReducer from 'lang/langSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import settingsReducer from 'settings/settingsSlice';
import notificationReducer from 'layout/nav/notifications/notificationSlice';
import identityReducer from 'reducers/authSlice';
import layoutReducer from 'layout/layoutSlice';
import { REDUX_PERSIST_KEY } from 'config/config.js';
import mailboxReducer from 'reducers/mailboxSlice';
import analyticReducer from 'reducers/analyticSlice';
import propertyAiReducer from 'reducers/propertiesAiSlice';
import collectionAiReducer from 'reducers/collectionsAiSlice';
import batchProcessAiReducer from 'reducers/batchProcessSlice';
import userAiReducer from 'reducers/usersAiSlice';
import securityLogAiReducer from 'reducers/securityLogsAiSlice';
import packageAiReducer from 'reducers/packagesAiSlice';
import customerAiReducer from 'reducers/customerAiSlice';
import candidateAiReducer from 'reducers/candidatesAiSlice';
import consentAiReducer from 'reducers/consentsAiSlice';
import subscriptionAiReducer from 'reducers/subscriptionsAiSlice';
import backgroundAiReducer from 'reducers/backgroundAiSlice';
import resourceAiReducer from 'reducers/resourcesAiSlice';
import dashboardAiReducer from 'reducers/dashboardAiSlice';
import commentAiReducer from 'reducers/commentsAiSlice';
import transactionAiReducer from 'reducers/transactionsAiSlice';
import dueDiligenceAiReducer from 'reducers/dueDiligenceAiSlice';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['menu', 'settings', 'lang', 'identity'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,
    layout: layoutReducer,
    lang: langReducer,
    identity: identityReducer,
    menu: menuReducer,
    notification: notificationReducer,
    scrollspy: scrollspyReducer,
    mailbox: mailboxReducer,
    analytic: analyticReducer,
    customerAi: customerAiReducer,
    userAi: userAiReducer,
    candidateAi: candidateAiReducer,
    consentAi: consentAiReducer,
    subscriptionAi: subscriptionAiReducer,
    backgroundAi: backgroundAiReducer,
    dueDiligenceAi: dueDiligenceAiReducer,
    resourceAi: resourceAiReducer,
    dashboardAi: dashboardAiReducer,
    propertyAi: propertyAiReducer,
    collectionAi: collectionAiReducer,
    batchProcessAi: batchProcessAiReducer,
    securityLogAi: securityLogAiReducer,
    packageAi: packageAiReducer,
    commentAi: commentAiReducer,
    transactionAi: transactionAiReducer
  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>{
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })}
});
const persistedStore = persistStore(store);
export { store, persistedStore };
