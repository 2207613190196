// cra imports
import React, { useMemo } from 'react';
import ReactDOM from "react-dom/client";
import reportWebVitals from 'reportWebVitals.js';

// import redux requirements
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { store, persistedStore } from './store.js';

// import html head tags requirements
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { REACT_HELMET_PROPS } from './config/config.js';

// import multi language
import LangProvider from './lang/LangProvider';

// import routing modules
import { HashRouter as Router } from 'react-router-dom';
import RouteIdentifier from './routing/components/RouteIdentifier';
import Loading from './components/loading/Loading';

// import routes
import { getLayoutlessRoutes } from './routing/helper';
import defaultRoutes from './routing/default-routes';
import routesAndMenuItems from './routes.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

// mock server register for demo
import '@mock-api';
import { Toaster } from 'react-hot-toast';

const Main = () => {
  const layoutlessRoutes = useMemo(() => getLayoutlessRoutes({ data: routesAndMenuItems }), []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
      <HelmetProvider><Helmet {...REACT_HELMET_PROPS} /></HelmetProvider>
        <div>
          <Toaster
            position="bottom-right"
            style = {{
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            }}
          />
        </div>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <LangProvider>
            <RouteIdentifier routes={[...layoutlessRoutes, ...defaultRoutes]} fallback={<Loading />} />
          </LangProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Main />
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
