import { SERVICE_URL } from 'config/config';
import api from '../api';

const packagesList = [
    {
      "packageID": "61e3e5880b965916e0822eba",
      "packageName": "Bronze",
      "isPostPay": "N",
      "color": "yellow",
      "description": "X",
      "modules": [
        "arr",
        "col",
        "prp",
        "tnt",
        "lmg",
        "wmr",
        "mtr",
        "dir"
      ],
      "features": [
        "AC",
        "PC",
        "RF",
        "RC",
        "SM",
        "IC",
        "CC",
        "CF",
        "RS",
        "WG",
        "LC",
        "DS",
        "IB"
      ],
      "pricing": [
        {
          "id": 1,
          "info_type": "scs",
          "info_value": "50",
          "billingCycle": "monthly",
          "isVariable": "Y",
          "isEditable": true
        },
        {
          "id": 2,
          "info_type": "cpu",
          "info_value": "100",
          "billingCycle": "monthly",
          "isVariable": "Y",
          "isEditable": true
        },
        {
          "id": 3,
          "info_type": "sms",
          "info_value": "80",
          "billingCycle": "monthly",
          "isVariable": "Y",
          "isEditable": true
        }
      ],
      featurePricing: [
        {name: 'AC', unitPrice: 1500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'UED', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'PC', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RF', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RC', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'SM', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'IC', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'CC', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'CF', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RS', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'WG', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'LC', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'DS', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'IB', unitPrice: 500, billingCycle: 'once', isVariable: 'Y',isEditable: true}
      ],
      "minimumUsers": 1,
      "maximumUsers": 5,
      "minimumVolume": 1,
      "maximumVolume": 50,
      "currency": "KES"
    },
    {
      "packageID": "61e3e6920b965916e0822ebb",
      "packageName": "Silver",
      "isPostPay": "N",
      "color": "blue",
      "description": "Y",
      "modules": [
        "arr",
        "col",
        "prp",
        "tnt",
        "lmg",
        "wmr",
        "mtr",
        "dir"
      ],
      "features": [
        "AC",
        "PC",
        "RF",
        "RC",
        "SM",
      ],
      "pricing": [
        {
          "id": 1,
          "info_type": "scs",
          "info_value": "100",
          "billingCycle": "monthly",
          "isVariable": "N",
          "isEditable": true
        },
        {
          "id": 2,
          "info_type": "cpu",
          "info_value": "100",
          "billingCycle": "monthly",
          "isVariable": "Y",
          "isEditable": true
        },
        {
          "id": 3,
          "info_type": "sms",
          "info_value": "50",
          "billingCycle": "monthly",
          "isVariable": "Y",
          "isEditable": true
        }
      ],
      featurePricing: [
        {name: 'AC', unitPrice: 2000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'UED', unitPrice: 1200, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'PC', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RF', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RC', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'SM', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'IC', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'CC', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'CF', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RS', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'WG', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'LC', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'DS', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'IB', unitPrice: 800, billingCycle: 'once', isVariable: 'Y',isEditable: true}
      ],
      "minimumUsers": 6,
      "maximumUsers": 20,
      "minimumVolume": 51,
      "maximumVolume": 200,
      "currency": "KES"
    },
    {
      "packageID": "61e3e6fb0b965916e0822ebc",
      "packageName": "Bronze",
      "isPostPay": "N",
      "color": "green",
      "description": "R",
      "modules": [
        "arr",
        "col",
        "prp",
        "tnt",
        "lmg",
        "wmr",
        "mtr",
        "dir"
      ],
      "features": [
        "AC",
        "PC",
        "RF",
        "RC",
        "SM",
      ],
      "pricing": [
        {
          "id": 1,
          "info_type": "scs",
          "info_value": "60",
          "billingCycle": "monthly",
          "isVariable": "N",
          "isEditable": true
        },
        {
          "id": 2,
          "info_type": "cpu",
          "info_value": "120",
          "billingCycle": "monthly",
          "isVariable": "Y",
          "isEditable": true
        },
        {
          "id": 3,
          "info_type": "sms",
          "info_value": "90",
          "billingCycle": "monthly",
          "isVariable": "Y",
          "isEditable": true
        },
        {
          "id": 4,
          "info_type": "scs",
          "info_value": "60",
          "billingCycle": "yearly",
          "isVariable": "N",
          "isEditable": true
        },
        {
          "id": 5,
          "info_type": "cpu",
          "info_value": "1440",
          "billingCycle": "yearly",
          "isVariable": "Y",
          "isEditable": true
        },
        {
          "id": 6,
          "info_type": "sms",
          "info_value": "1080",
          "billingCycle": "yearly",
          "isVariable": "Y",
          "isEditable": true
        }
      ],
      featurePricing: [
        {name: 'AC', unitPrice: 2500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'UED', unitPrice: 1500, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'PC', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RF', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RC', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'SM', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'IC', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'CC', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'CF', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'RS', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'WG', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'LC', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'DS', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true},
        {name: 'IB', unitPrice: 1000, billingCycle: 'once', isVariable: 'Y',isEditable: true}
      ],
      "minimumUsers": 21,
      "maximumUsers": 50,
      "minimumVolume": 201,
      "maximumVolume": 10000,
      "currency": "KES"
    }
  ];

api.onGet(`${SERVICE_URL}/packages`).reply(200, packagesList);