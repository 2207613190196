import { SERVICE_URL } from 'config/config';
import api from '../api';

const paymentProvidersList = [
    {
      "providerID": "623234a0fce71a2c14c3761b",
      "providerCode": "mpesa",
      "minimumAmount": 10,
      "maximumAmount": 300000
    },
    {
      "providerID": "62323563fce71a2c14c3761c",
      "providerCode": "direct",
      "minimumAmount": 1000,
      "maximumAmount": 1000000000
    }
];

api.onGet(`${SERVICE_URL}/payment-providers`).reply(200, paymentProvidersList);