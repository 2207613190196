import { SERVICE_URL } from 'config/config';
import api from '../api';

const currencyConverterList = [
    {
      "converterID": "62324703699b8c04ac8d6a0b",
      "sourceCurrency": "KES",
      "destinationCurrency": "USD",
      "unitSourceValue": 1,
      "unitDestinationValue": 0.0087449316
    },
    {
      "converterID": "6232474d699b8c04ac8d6a0c",
      "sourceCurrency": "UGX",
      "destinationCurrency": "USD",
      "unitSourceValue": 1,
      "unitDestinationValue": 0.00027928633
    },
    {
      "converterID": "62324798699b8c04ac8d6a0d",
      "sourceCurrency": "KES",
      "destinationCurrency": "UGX",
      "unitSourceValue": 1,
      "unitDestinationValue": 33
    },
    {
      "converterID": "62324e89699b8c04ac8d6a0e",
      "sourceCurrency": "TZS",
      "destinationCurrency": "USD",
      "unitSourceValue": 1,
      "unitDestinationValue": 0.00043167046
    },
    {
      "converterID": "62324f00699b8c04ac8d6a0f",
      "sourceCurrency": "KES",
      "destinationCurrency": "TZS",
      "unitSourceValue": 1,
      "unitDestinationValue": 22
    },
    {
      "converterID": "62324f26699b8c04ac8d6a10",
      "sourceCurrency": "KES",
      "destinationCurrency": "NGN",
      "unitSourceValue": 1,
      "unitDestinationValue": 3.7
    }
];

api.onGet(`${SERVICE_URL}/currency-converters`).reply(200, currencyConverterList);