import axios from "axios";
import { toast } from "react-hot-toast";
import { store } from "store";
import { createIntl, createIntlCache } from "react-intl";
import { messages } from "lang/messages/messages";
import { languages } from "lang/langSlice";
import { SELECTED_CUSTOMER_KEY, TENANT_KEY } from "config/config";

const tenantCode = 'VT01';
const baseURL = process.env.REACT_APP_API_BASE_URL;
const api = axios.create({ baseURL: baseURL});

/* pop a message based on set success(1,0), message & title */
export const toastMessage = ({message,title='title.results',options={}}) => { 
    toast.dismiss();
    const cache = createIntlCache(); 
    const locale = store.getState()?.lang?.currentLang.locale || languages[0]?.locale; 

    const intl = createIntl({
        locale: locale,
        messages: messages[locale]
    }, cache);

    const toastBody = (
        <div role="button" onClick = { () => toast.dismiss() } >
            <div className="fw-bold mb-2 mt-2">{intl.formatMessage({ id: title})}</div>
            <div>{ intl.formatMessage({ id: message}) }</div>
        </div>
    );

    return {
        error : () => toast.error(toastBody, {...options, ...{id:'toastr'} }),
        success: () => toast.success(toastBody, {...options, ...{id:'toastr'}}),
        info: () => toast.success(toastBody, {...options, ...{id:'toastr'}}),
        warning: () => toast.success(toastBody, {...options, ...{id:'toastr'}})
    }
}

/* handle http API errors and pop a toaster message logout if token is already expired */
api.interceptors.response.use(
    resp=>{ 
        const token = store.getState()?.identity?.token;
        const title = 'title.operation';
        if(resp.config.method==='get' || !token){
            toastMessage({title, message: 'response.retrieved'}).success();
        }
        else if(resp.config.method==='post'){
            toastMessage({title, message: 'response.saved'}).success();
        }
        else if(resp.config.method==='patch'){
            toastMessage({title, message: 'response.updated'}).success();
        }
        else if(resp.config.method==='delete'){
            toastMessage({title, message: 'response.deleted'}).success();
        }
        return resp.data;
    },
    error => { 
        const token = store.getState()?.identity?.token;
        const expectedError = error.response && error.response >= 400 && error.response < 500;
        const statusCode = error?.response?.data?.error.statusCode || error?.response?.status;
        const message = error?.response?.data?.error?.message;
        
        if(statusCode===401){
            toastMessage({message}).error();
            if(token){
                toastMessage({ message: "errors.expired-session" }).error();
                window.localStorage.clear();
                window.location.reload();
            }
        }
        else if(statusCode===404 && message){
            toastMessage({ message: message }).error();
        }
        else if(statusCode===422){
            toastMessage({ message: "errors.invalid-inputs" }).error();
        }
        else if(!expectedError){
            toastMessage({ message: "errors.unexpected" }).error();
        }

        return Promise.reject(error);
    }
);

/* add jwt token to each request for authenticated users */
api.interceptors.request.use(
    (requestConfig) => {
        const { token, user } = store.getState()?.identity;

        requestConfig.headers = {
            ...requestConfig.headers,
            Authorization: `Bearer ${ token }`,
            [SELECTED_CUSTOMER_KEY]: `${ user?.extraData?.customerID || ' ' }`,
            [TENANT_KEY]: `${ tenantCode }`
        }

        if(!user?.extraData?.customerID){
            delete requestConfig.headers[SELECTED_CUSTOMER_KEY];
        }
        if(!tenantCode){
            delete requestConfig.headers[TENANT_KEY];
        }
        if(!token){
            delete requestConfig.headers['Authorization'];
            window.localStorage.clear();
        }
        
        return requestConfig
    },
    (error) => Promise.reject(error)
);

const HttpService = {
    baseURL: baseURL,
    headers: () => {
        const { token = ' ', user } = store.getState()?.identity;
        return { 
            Authorization: `Bearer ${ token }`,
            [SELECTED_CUSTOMER_KEY]:  `${ user?.extraData?.customerID || ' '}`,
            [TENANT_KEY]: `${ tenantCode }`
        }
    },
    get: api.get,
    put: api.put,
    post: api.post,
    patch: api.patch,
    delete: api.delete
};

export default HttpService;