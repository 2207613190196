import swal from '@sweetalert/with-react';

export const deleteConfirmDialog = (props) => {
    const { f, title=f({id: 'alert.delete.title'}), message = f({id: 'alert.delete.message'}) } = props;
    return swal({
        title: title,
        icon: 'warning',
        text: message,
        buttons: true,
    });
}

export const submitConfirmDialog = (props) => {
    const { f, title=f({id: 'alert.submit.title'}), message = f({id: 'alert.submit.message'}) } = props;
    return swal({
        title: title,
        icon: 'warning',
        text: message,
        buttons: true,
    });
}