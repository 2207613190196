/* eslint-disable */
import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config/config.js';
import { USER_ROLE as Role } from 'constants.js';

const blog = lazy(() => import('./views/blog/Blog'));
const community = {
  index: lazy(() => import('./views/community/Community')),
  list: lazy(() => import('./views/community/CommunityList')),
};
const upgrade = lazy(() => import('./views/upgrade/Upgrade'));

const dashboard = {
  gettingStarted: lazy(() => import('./views/dashboard/DashboardMain')),
  analysis: lazy(() => import('./views/dashboard/DashboardAnalysis')),
};

const messaging = {
  mailBox: lazy(() => import('./views/messaging/MailBox')),
  smsBox: lazy(() => import('./views/messaging/MailBox')),
};

const services = {
  customers: lazy(() => import('./views/services/ServicesCustomers')),
  orders: lazy(() => import('./views/services/ServicesBcsOrders')),
  newOrder: lazy(() => import('./views/services/ServicesBcsRaise')),
  infoBot: lazy(() => import('./views/services/ServicesInfoBot')),
  vetting: lazy(() => import('./views/services/ServicesBcs')),
  runBackgroundCheck: lazy(() => import('./views/services/ServicesRunBackgroundCheck')),
  runDueDiligence: lazy(() => import('./views/services/ServicesRunDueDiligence')),
  dueDiligence: lazy(() => import('./views/services/ServicesDueDiligence')),
  candidateReport: lazy(() => import('./views/services/ServicesCandidateReport')),
  consents: lazy(() => import('./views/services/ServicesConsents')),
  signConsent: lazy(() => import('./views/services/ServicesSignConsent')),
  conversation: lazy(() => import('./views/services/ServicesConversation')),
  archives: lazy(() => import('./views/services/ServicesArchives')),
};

const account = {
  settings: lazy(() => import('./views/account/AccountSettings')),
  billing: lazy(() => import('./views/account/AccountBilling')),
  security: lazy(() => import('./views/account/AccountSecurity')),
  users: lazy(() => import('./views/account/AccountUsers')),
  userProfile: lazy(() => import('./views/account/AccountProfile')),
  resources: lazy(() => import('./views/services/ServicesStorage')),
  resourcesFolder: lazy(() => import('./views/services/ServicesStorageFolder')),
};

const support = {
  docs: lazy(() => import('./views/support/SupportDocs')),
  docsDetail: lazy(() => import('./views/support/SupportDocsDetail')),
  knowledgeBase: lazy(() => import('./views/support/SupportKnowledgeBase')),
  tickets: lazy(() => import('./views/support/SupportTickets')),
  ticketsDetail: lazy(() => import('./views/support/SupportTicketsDetail')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboard/home`,
    },
    {
      path: `${appRoot}/sms-box`,
      component: messaging.smsBox,
      label: 'menu.sms',
      icon: 'messages',
    },
    {
      path: `${appRoot}/mail-box`,
      label: 'menu.email',
      icon: 'email',
      component: messaging.mailBox,
      subs: [
        {
          path: '/list',
          label: 'menu.community-list',
          hideInMenu: true,
          component: community.list,
        },
      ],
    },
    {
      path: `${appRoot}/upgrade`,
      label: 'menu.upgrade',
      icon: 'trend-up',
      component: upgrade,
    }
  ],
  sidebarItems: [
    {
      path: `${appRoot}/dashboard`,
      label: 'menu.dashboard',
      icon: 'grid-1',
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboard/home`,
      subs: [
        { path: '/home', label: 'menu.home', icon: 'navigate-diagonal', component: services.vetting }
      ],
    },
    {
      path: `${appRoot}/services`,
      label: 'menu.services',
      icon: 'grid-1',
      exact: true,
      redirect: true,
      to: `${appRoot}/services/vetting`,
      subs: [
        {
          roles: [Role.System],
          path: '/customers',
          label: 'menu.customers',
          icon: 'user',
          component: services.customers,
          subs: [
            { path: '/profile/:customerID', label: 'menu.customers', hideInMenu: true, component: services.customerProfile }
          ],
        },
        {
          path: '/vetting',
          label: 'menu.vetting',
          icon: 'home',
          component: services.vetting,
          subs: [
            { 
              path: '/investigate/:candidateID/:checkTypeCode?', 
              label: 'menu.investigate', 
              hideInMenu: true, 
              component: services.runBackgroundCheck,
              roles: [Role.System]  
            },
            { 
              path: '/report/:candidateID', 
              label: 'menu.report', 
              hideInMenu: true, 
              component: services.candidateReport
            }
          ],
        },
        {
          path: '/orders',
          label: 'menu.orders',
          icon: 'cart',
          component: services.orders,
          subs: [
            {
              path: '/raise/:billingID?',
              label: 'orders.add',
              icon: 'plus',
              component: services.newOrder,
              hideInMenu: true
            },
          ],
        },
        {
          path: '/consents',
          label: 'menu.consents',
          icon: 'user',
          component: services.consents,
          subs: [
            {
              path: '/conversation/:consentID',
              label: 'bcs.candidate.consent',
              component: services.conversation,
              hideInMenu: true
            }
          ]
        },
        { 
          path: '/due-diligence', 
          label: 'menu.due-diligence', 
          icon: 'gear', 
          component: services.dueDiligence,
          subs: [
            { 
              path: '/investigate/:dueDiligenceID/:checkTypeCode?', 
              label: 'menu.investigate', 
              hideInMenu: true, 
              component: services.runDueDiligence,
              roles: [Role.System]  
            },
            { 
              path: '/report/:dueDiligenceID', 
              label: 'menu.report', 
              hideInMenu: true, 
              component: services.candidateReport
            }
          ],
        },
        { path: '/info-bot', label: 'menu.info-bot', icon: 'search', component: services.infoBot, roles: [Role.System] },
        { path: '/archives', label: 'menu.archives', icon: 'list', component: services.archives, roles: [Role.System]  },
        { path: '/resources', label: 'menu.resources', icon: 'folder', component: account.resources, roles: [Role.System]  },
      ],
    },
    {
      path: `${appRoot}/account`,
      label: 'menu.account',
      icon: 'user',
      exact: true,
      redirect: true,
      to: `${appRoot}/account/settings`,
      subs: [
        { path: '/settings', label: 'menu.settings', icon: 'gear', component: account.settings },
        { 
          path: '/billing', 
          label: 'menu.billing', 
          icon: 'credit-card', 
          component: account.billing,
          roles: [Role.Customer,Role.System],
        },
        { path: '/security', label: 'menu.security', icon: 'shield', component: account.security },
        {
          path: '/users',
          label: 'menu.users',
          icon: 'user',
          component: account.users,
          roles: [Role.Customer,Role.System],
          subs: [
            { path: '/profile/:id', label: 'profile', hideInMenu: true, component: account.userProfile },
          ],
        }
      ],
    },
    {
      path: `${appRoot}/support`,
      label: 'menu.support',
      icon: 'help',
      exact: true,
      redirect: true,
      to: `${appRoot}/support/docs`,
      subs: [
        {
          path: '/docs',
          label: 'menu.docs',
          icon: 'file-empty',
          component: support.docs,
          subs: [{ path: '/detail', label: 'menu.docs', icon: 'file-empty', component: support.docsDetail, hideInMenu: true }],
        },
        {
          path: '/tickets',
          label: 'menu.tickets',
          icon: 'bookmark',
          component: support.tickets,
          subs: [{ path: '/detail', label: 'menu.tickets-detail', icon: 'file-empty', component: support.ticketsDetail, hideInMenu: true }],
        },
      ],
    },
  ],
};
export default routesAndMenuItems;
