import { SERVICE_URL } from 'config/config';
import api from '../api';

const taxCodeList = [
    {
      "taxID": "620a34859ec1bb26344b83dd",
      "code": "VK",
      "description": "VAT 16%",
      "country": "KE",
      "percent": 16
    },
    {
      "taxID": "620a349b9ec1bb26344b83de",
      "code": "UA",
      "description": "Vat 18%",
      "country": "UG",
      "percent": 18
    },
    {
      "taxID": "620a34d99ec1bb26344b83df",
      "code": "NA",
      "description": "Vat 8.5%",
      "country": "NG",
      "percent": 8.5
    }
];

api.onGet(`${SERVICE_URL}/tax-codes`).reply(200, taxCodeList);