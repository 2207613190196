const enMessages = {
  'nothing-found': 'Empty',
  'operation-new': 'You can now get started by clicking button on bottom right',
  'operation-search': 'You can now get started by searching for information above',
  'operation-add-above': 'You can now get started by adding information above',
  'title.operation': 'Data Operation',
  'title.results': 'Data Results',
  'title.processing': 'Data Processing',
  'title.error': 'Error Processing',
  'request.processing': 'Processing...',
  'request.processing-wait': 'Kindly wait for a few moments as the system processses your request...',
  'response.retrieved': 'Data has been retrieved successfully',
  'response.saved': 'Data has been saved successfully',
  'response.updated': 'Data has been updated successfully',
  'response.deleted': 'Data has been deleted successfully',
  'response.uploaded': 'Data has been uploaded successfully',

  'errors.expired-session': 'This session is expired. Please logout and login again!',
  'errors.invalid-inputs': 'Invalid input or wrong format. Please correct the form inputs then try again!',
  'errors.unexpected': 'An unexpected error occured. Please later!',
  'errors.form.controls': 'Correct errors on form before submit.',
  'errors.form.date-range': 'End date should be greater than start date',
  'errors.template.not-found': 'Sorry! The template for this check category could not be found!',
  'errors.records.not-found': 'Sorry! No records could be found. Please check again later.',

  'navigation.go-back': 'Go Back',
  'navigation.previous': 'Previous',
  'navigation.next': 'Next',

  'menu.dashboards': 'Dashboards',
  'menu.default': 'Default',
  'menu.visual': 'Visual',
  'menu.analytic': 'Analytic',
  'menu.apps': 'Apps',
  'menu.calendar': 'Calendar',
  'menu.language': 'Language',
  'menu.chat': 'Chat',
  'menu.contacts': 'Contacts',
  'menu.mailbox': 'Mailbox',
  'menu.tasks': 'Tasks',
  'menu.maintenance': 'Maintenance',
  'menu.vendors': 'Vendors',
  'menu.pages': 'Pages',
  'menu.authentication': 'Authentication',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.forgot-password': 'Forgot Password',
  'menu.reset-password': 'Reset Password',
  'menu.blog': 'Blog',
  'menu.home': 'Home',
  'menu.grid': 'Grid',
  'menu.list': 'List',
  'menu.detail': 'Detail',
  'menu.miscellaneous': 'Miscellaneous',
  'menu.faq': 'Faq',
  'menu.knowledge-base': 'Knowledge Base',
  'menu.error': 'Error',
  'menu.coming-soon': 'Coming Soon',
  'menu.pricing': 'Pricing',
  'menu.search': 'Search',
  'menu.mailing': 'Mailing',
  'menu.empty': 'Empty',
  'menu.portfolio': 'Portfolio',
  'menu.profile': 'Profile',
  'menu.standard': 'Standard',
  'menu.settings': 'Settings',
  'menu.blocks': 'Blocks',
  'menu.images': 'Images',
  'menu.tabular-data': 'Tabular Data',
  'menu.thumbnails': 'Thumbnails',
  'menu.cta': 'Cta',
  'menu.gallery': 'Gallery',
  'menu.stats': 'Stats',
  'menu.steps': 'Steps',
  'menu.details': 'Details',
  'menu.interface': 'Interface',
  'menu.components': 'Components',
  'menu.accordion': 'Accordion',
  'menu.alerts': 'Alerts',
  'menu.badge': 'Badge',
  'menu.breadcrumb': 'Breadcrumb',
  'menu.buttons': 'Buttons',
  'menu.button-group': 'Button Group',
  'menu.card': 'Card',
  'menu.close-button': 'Close Button',
  'menu.collapse': 'Collapse',
  'menu.dropdowns': 'Dropdowns',
  'menu.list-group': 'List Group',
  'menu.modal': 'Modal',
  'menu.navs': 'Navs',
  'menu.offcanvas': 'Offcanvas',
  'menu.pagination': 'Pagination',
  'menu.popovers': 'Popovers',
  'menu.progress': 'Progress',
  'menu.spinners': 'Spinners',
  'menu.toasts': 'Toasts',
  'menu.tooltips': 'Tooltips',
  'menu.forms': 'Forms',
  'menu.layouts': 'Layouts',
  'menu.validation': 'Validation',
  'menu.wizard': 'Wizard',
  'menu.input-group': 'Input Group',
  'menu.input-mask': 'Input Mask',
  'menu.generic-forms': 'Generic Forms',
  'menu.controls': 'Controls',
  'menu.autocomplete': 'Autocomplete',
  'menu.checkbox-radio': 'Checkbox-Radio',
  'menu.date-picker': 'Date Picker',
  'menu.dropzone': 'Dropzone',
  'menu.editor': 'Editor',
  'menu.input-spinner': 'Input Spinner',
  'menu.rating': 'Rating',
  'menu.select': 'Select',
  'menu.slider': 'Slider',
  'menu.tags': 'Tags',
  'menu.time-picker': 'Time Picker',
  'menu.plugins': 'Plugins',
  'menu.carousel': 'Carousel',
  'menu.charts': 'Charts',
  'menu.clamp': 'Clamp',
  'menu.context-menu': 'Context Menu',
  'menu.datatables': 'Datatables',
  'menu.editable-rows': 'Editable Rows',
  'menu.editable-boxed': 'Editable Boxed',
  'menu.server-side': 'Server Side',
  'menu.boxed-variations': 'Boxed Variations',
  'menu.lightbox': 'Lightbox',
  'menu.maps': 'Maps',
  'menu.notification': 'Notification',
  'menu.players': 'Players',
  'menu.scrollbar': 'Scrollbar',
  'menu.shortcuts': 'Shortcuts',
  'menu.sortable': 'Sortable',
  'menu.content': 'Content',
  'menu.icons': 'Icons',
  'menu.cs-line-icons': 'CS Line Icons',
  'menu.cs-interface-icons': 'CS Interface Icons',
  'menu.bootstrap-icons': 'Bootstrap Icons',
  'menu.tables': 'Tables',
  'menu.typography': 'Typography',
  'menu.menu': 'Menu',
  'menu.horizontal': 'Horizontal',
  'menu.vertical': 'Vertical',
  'menu.vertical-hidden': 'Vertical Hidden',
  'menu.vertical-no-hidden': 'Vertical No Hidden',
  'menu.mobile-only': 'Mobile Only',
  'menu.sidebar': 'Sidebar',
  'menu.Docs': 'Docs',
  'menu.connections': 'Connections',
  'menu.bookmarks': 'Bookmarks',
  'menu.requests': 'Requests',
  'menu.account': 'Account',
  'menu.password': 'Password',
  'menu.devices': 'Devices',
  'menu.notifications': 'Notifications',
  'menu.email': 'Email',
  'menu.sms': 'SMS',
  'menu.downloads': 'Downloads',
  'menu.documents': 'Documents',
  'menu.videos': 'Videos',
  'menu.dashboard': 'Dashboard',
  'menu.products': 'Products',
  'menu.orders': 'Bulk Orders',
  'menu.customers': 'Customers',
  'menu.storefront': 'Storefront',
  'menu.filters': 'Filters',
  'menu.categories': 'Categories',
  'menu.cart': 'Cart',
  'menu.checkout': 'Checkout',
  'menu.invoice': 'Invoice',
  'menu.shipping': 'Shipping',
  'menu.discount': 'Discount',
  'menu.general': 'General',
  'menu.vetting': 'Candidates',
  'menu.consents': 'Consents',
  'menu.due-diligence': 'Due Diligence',
  'menu.due-diligence-run': 'Run Due Diligence',
  'menu.archives': 'Archives',
  'menu.info-bot': 'Info Bot',
  'menu.investigate': 'Run Background',

  'menu.upgrade': 'Upgrade',
  'menu.community': 'Community',
  'menu.community-list': 'Community List',
  'menu.getting-started': 'Getting Started',
  'menu.analysis': 'Analysis',
  'menu.services': 'Services',
  'menu.property': 'Property',
  'menu.property-add': 'New Property',
  'menu.property-detail': 'Property Detail',
  'menu.tenants': 'Tenants',
  'menu.tenant-add': 'New Tenant',
  'menu.owners': 'Owners',
  'menu.owner-add': 'Add Owner',
  'menu.collections': 'Collections',
  'menu.arrears': 'Arrears',
  'menu.leases': 'Leases',
  'menu.uploads': 'Uploads',
  'menu.resources': 'Resources',
  'menu.hosting': 'Hosting',
  'menu.users': 'Users',
  'menu.billing': 'Billing',
  'menu.security': 'Security',
  'menu.support': 'Support',
  'menu.docs': 'Docs',
  'menu.docsdetail': 'Docs Detail',
  'menu.tickets': 'Tickets',
  'menu.tickets-detail': 'Ticket Detail',
  
  'username': 'Username',
  'password': 'Password',
  'old-password': 'Old Password',
  'verify-password': 'Verify Password',
  'client-code': 'Client Code',
  'id-number': 'National ID',
  'invoice-number': 'Invoice Number',
  'employee-number': 'Employee Number',
  'physical-address': 'Location',
  'postal-address': 'Postal Address',
  'residential-address': 'Residential Address',
  'country': 'Country',
  'nationality': 'Nationality',
  'website': 'Website',
  'initials': 'Initials',
  'currency': 'Currency',
  'company': 'Company Name',
  'unassigned': 'Unassigned',
  'add': 'Add',
  'new': 'New',
  'amend': 'Amend',
  'view': 'View',
  'delete': 'Delete',
  'resume': 'Resume',
  'copy': 'Copy',
  'submit': 'Submit',
  'ok': 'OK',
  'cancel': 'Cancel',
  'back': 'Back',
  'next': 'Next',
  'Y': 'Yes',
  'N': 'No',
  'suspend': 'Suspend',
  'activate': 'Activate',
  'close': 'Close',
  'no-change': 'No Change',
  'verify': 'Verify',
  'quick-add': 'Quick Add',
  'quick-actions': 'Quick Actions',
  'bulk-upload': 'Bulk Upload',
  'bulk-upload.bills': 'Upload Bills',
  'view-more': 'View More',
  'random-search': 'Enter text to search...',
  'recent-activity': 'Recent Activity',
  'stats': 'Stats',
  'recent-activity.teller': 'Recent Teller Transactions and Activities',
  'actions': 'Actions',
  'select-year': 'Year',
  'select-month': 'Month',
  'select-day': 'Day', 
  'ago': 'Ago',
  'start-date': 'Start Date',
  'end-date': 'End Date',
  'from': 'From',
  'to': 'To',
  'dob': 'Date of Birth',
  'total-count': 'Total Count',
  'payment-details': 'Payment Details',
  'amount-distribution': 'Amount Distribution',
  'gender': 'Gender',
  'gender.male': 'Male',
  'gender.female': 'Female',
  'gender.other': 'Other',
  'none': 'None',

  'language.en': 'English',
  'language.sw': 'Swahili',
  'language.fr': 'Francais',
  'language.de': 'German',
  'language.es': 'Spanish',
  
  'crud.actions': 'Action',
  'crud.actions.add': 'Add New',
  'crud.actions.edit': 'Edit',
  'crud.actions.save': 'Save',
  'crud.actions.update': 'Update',
  'crud.actions.archive': 'Archive',
  'crud.actions.delete': 'Delete',
  'crud.actions.detail': 'View',
  'crud.actions.list': 'List',
  'crud.actions.bulk-download': 'Bulk Download',
  'crud.actions.search-record': 'Search Record',
  
  'alert.delete.title': 'Confirm Delete',
  'alert.delete.message': 'Do you really want to delete selected record(s)?',
  'alert.archive.title': 'Confirm Archive',
  'alert.archive.message': 'Do you really want to archive selected record(s)?',
  'alert.submit.title': 'Confirm Submit',
  'alert.mpesa.message': 'You are about to initiate an MPESA payment. Are you sure?',
  'alert.submit.message': 'Do you really want to save change(s) on record(s)?',
  'alert.language.title': 'Language Change',
  'alert.language.message': 'Do you really want to switch to selected language(s)?',
  'alert.signup.message': 'You are about to create an account. Are you sure?',
  'alert.change-password.message': 'You are about to change your password. Are you sure?',
  'alert.reset-password.message': 'You are about to reset your password. Are you sure?',
  'alert.change-password.success': 'Your password has been changed successfully.',
  'alert.reset-password.success': 'Your password reset code has been sent to email/sms',
  'alert.reset-password.failure': 'Attempt to reset password failed. Please retry after 1 minute!',
  'alert.otp-sent.success': 'Your OTP code has been sent to email/sms',
  'alert.otp-validated.success': 'Your OTP code has been verified successfully',
  'alert.registration.success': 'You are now a new user on the platform. Please check your email/sms for login credentials',
  'alert.registration.failure': 'User account registration has failed. Please retry after 1 minute!',
  'alert.account-lock': 'User account has been locked.',
  'alert.account-suspended': 'Your account has been suspended after many unsuccessful logins. Reach out to the admnistrator',

  'timespan.custom': 'Custom Date',
  'timespan.today': 'Today',
  'timespan.yesterday': 'Yesterday', 
  'timespan.last-7-days': 'Last 7 Days',
  'timespan.this-week': 'This Week',
  'timespan.last-week': 'Last Week',
  'timespan.this-month': 'This Month',
  'timespan.last-month': 'Last Month',
  'timespan.this-year': 'This Year', 
  'timespan.last-year': 'Last Year', 

  'period-cycle': 'Billing Cycle',
  'period-cycle.none': 'None',
  'period-cycle.daily': 'Daily',
  'period-cycle.weekly': 'Weekly',
  'period-cycle.every-2-weeks': 'Every 2 Weeks',
  'period-cycle.monthly': 'Monthly',
  'period-cycle.every-2-months': 'Every 2 Months',
  'period-cycle.quarterly': 'Quarterly',
  'period-cycle.twice-a-year': 'Twice A Year', 
  'period-cycle.yearly': 'Yearly', 

  'days.mon.short': 'Mon',
  'days.tue.short': 'Tue',
  'days.wed.short': 'Wed',
  'days.thu.short': 'Thu',
  'days.fri.short': 'Fri',
  'days.sat.short': 'Sat', 
  'days.sun.short': 'Sun',
  
  'months.jan.short': 'Jan',
  'months.feb.short': 'Feb',
  'months.mar.short': 'Mar',
  'months.apr.short': 'Apr',
  'months.may.short': 'May',
  'months.jun.short': 'Jun', 
  'months.jul.short': 'Jul',
  'months.aug.short': 'Aug',
  'months.sep.short': 'Sep',
  'months.oct.short': 'Oct',
  'months.nov.short': 'Nov',
  'months.dec.short': 'Dec',

  'notification': 'Notification',
  'notification.channel': 'Notification Channel',

  'login.via': 'Login Via',
  'login.forgot': 'Forgot?',
  'login.sign-in': 'Sign In',
  'login.sign-out': 'Logout',
  'login.sign-out-msg': 'You have successfully logged out',
  'login.use-credentials': 'Or use your credentials to login.',
  'login.go-dashboard': 'Go Dashboard',
  'login.2factor': 'OTP Validation',
  'login.2factor.description': 'The application needs to securely authenticate you onto the system. Please enter OTP Code sent to your email or SMS',
  'login.2factor.otp': 'OTP Code',
  'login.2factor.send':'Get OTP',
  'login.2factor.resend':'Resend',
  'login.2factor.channel': 'Change where to receive the OTP Code',
  'login.motto': 'The leading, AI based solution to candidate screening, background checks, risk assesment and due diligence.',
  
  'dashboard.metrics': 'Property Metrics',
  'dashboard.performance-stats': 'Performance Stats',
  'dashboard.quick-reports': 'Quick Reports',
  'dashboard.description': 'Intelligent property & tenant management, transaction reconciliation, and realtime reports.',
  'dashboard.search.title': 'Tenant Search',
  'dashboard.search.placeholder': 'Name or ID Number',
  'dashboard.search.description': 'Please enter Tenant\'s Name or ID number to search in your database',
  'customer.search.title': 'Customer Search',
  'customer.search.placeholder': 'Customer Name',
  'customer.search.description': 'Please enter Customer\'s Name to search in your database',

  'property.info': 'Property Info',
  'property.name': 'Property Name',
  'property.description': 'Description',
  'property.location': 'Location',
  'property.manager': 'Manager',
  'property.status': 'Status',
  'property.status.active': 'Active',
  'property.status.inactive': 'Inactive',
  'property.status.suspended': 'Suspended',
  'property.tenants': 'Tenants',
  'property.vacant': 'Vacant Rooms',
  'property.occupied': 'Occupied Rooms',
  'property.advances': 'Advance Payments',
  'property.arrears': 'Rent Arrears',
  'property.rent': 'Rent P.m',
  'property.security-deposit': 'Security Deposit',
  'property.other-charges': 'Other Charges',
  'property.room-number': 'Room No',
  'property.rooms': 'Rooms',
  'property.room-history': 'Room History',
  'property.occupancy': 'Occupancy',
  'property.collections-today': 'Collections Today',
  'property.collections': 'Rent Collections',
  'property.billing-day': 'Billing Cycle',
  'property.actual-rent': 'Actual Rent',
  'property.projected-rent': 'Projected Rent',
  'property.choose-room-types': 'Choose Room Types',
  'property.choose-amenities': 'Choose Amenities',
  'property.describe': 'Describe The Property',
  'property.manage-rooms': 'Manage Rooms',

  'amenities.water': 'Water',
  'amenities.internet': 'Internet',
  'amenities.garbage-collection': 'Garbage Collection',
  'amenities.security': 'Security',
  'amenities.cleaning': 'Cleaning',
  'amenities.electricity': 'Electricity',  
  'amenities.electronic-repairs': 'Electronic Repairs',
  'amenities.flooring-maintenance': 'Flooring Maintenance',
  'amenities.landscaping': 'Landscaping',
  'amenities.masonry-services': 'Masonry Services',
  'amenities.interior-design': 'Interior Design',
  'amenities.painting-services': 'Painting Services',
  'amenities.plumbing-services': 'Plumbing Services',
  'amenities.roofing-repairs': 'Roofing Repairs',
  'amenities.windows-maintenance': 'Windows Maintenance',
  'amenities.others': 'Others',
  
  'room.type': 'Room Type',
  'room.type.1BR': '1 Bedroom',
  'room.type.2BR': '2 Bedroom',
  'room.type.3BR': '3 Bedroom',
  'room.type.SNR': 'Single Room',
  'room.type.DBR': 'Double Room',
  'room.type.BST': 'Bedsitter',
  'room.status': 'Room Status',
  'room.status.occupied': 'Occupied',
  'room.status.vacant': 'Vacant',
  'room.add': 'Add Room',
  'room.edit': 'Edit Room',
  'room.search.placeholder': 'Search Property',

  'tenant.name': 'Tenant Name',  
  'tenant.current': 'Current Tenant',  
  'tenant.past': 'Past Tenants',  
  'tenant.profile': 'Profile',
  'tenant.account-statement': 'Account Statement',
  'tenant.add': 'Add Tenant',
  'tenant.edit': 'Edit Tenant',
  'tenant.suspend': 'Suspend Tenant',
  'tenant.activate': 'Activate Tenant',
  'tenant.status': 'Tenant Status',
  'tenant.status.active': 'Active',
  'tenant.status.inactive': 'Inctive',
  'tenant.leases': 'Tenant Leases',

  'lease': 'Lease',
  'lease.details': 'Lease Details',
  'lease.days-left': 'Days Left',
  'lease.type': 'Lease Type',
  'lease.rent-cycle': 'Rent Cycle',
  'lease.type.fixed': 'Fixed',
  'lease.type.rollover': 'Fixed With RollOver',
  'lease.type.monthly': 'Month To Month',
  'lease.status': 'Lease Status',
  'lease.status.active': 'Active',
  'lease.status.expired': 'Expired',
  'lease.agreement': 'Agreement',
  'lease.add': 'Add Lease',
  'lease.edit': 'Edit Lease',
  'lease.renew': 'Renew',
  'lease.receive-payment': 'Receive Payment',
  'lease.search-lease': 'Search Lease',
  
  'profile': 'Profile',
  'profile.type': 'Profile Type',
  'profile.type.admin': 'Administrator',
  'profile.type.data-admin': 'Data Admin',
  'profile.type.investigator': 'Investigator',
  'profile.type.accountant': 'Accountant',
  'profile.type.customer-admin': 'Customer Admin',
  'profile.type.customer-user': 'Customer User',
  'profile.name': 'Full Name', 
  'profile.email': 'Email',
  'profile.phone': 'Phone',
  'profile.address': 'Address',
  'profile.active': 'Is Active',
  'profile.contact-info': 'Contact Info',  
  'profile.personal-info': 'Personal Info',   
  'profile.public-info': 'Public Info',
  'profile.email-settings': 'Email Settings',
  'profile.language-settings': 'Language Settings',
  'profile.add': 'Add Profile',
  'profile.edit': 'Edit Profile',

  'transactions': 'Transactions', 
  'transactions.count': 'No. of Transactions',
  'transactions.volume': 'Volume of Transactions',
  'transactions.fees': 'Transaction Fees',
  'transactions.reconciled': 'Reconciled Transactions',
  'transactions.pending': 'Pending Transactions',

  'transaction.reference': 'A/C REF',
  'transaction.message': 'Enter Transaction Message',
  'transaction.new-payment': 'New Payment',
  'transaction.new-message': 'You can add a missing payment message below. Paste a raw transaction message from the Bank or Mpesa',
  'transaction.transact': 'Transact',
  'transaction.date': 'Date',
  'transaction.amount': 'Amount',
  'transaction.balance': 'Balance',
  'transaction.type': 'Item Type',
  'transaction.code': 'Transaction Code',
  'transaction.receipt-number': 'Receipt No.',
  'transaction.particulars': 'Particulars',
  'transaction.type.payment': 'Payment',
  'transaction.type.rent': 'Rent Bill',
  'transaction.type.water': 'Water Bill',
  'transaction.type.internet': 'Internet Bill',
  'transaction.type.garbage-collection': 'Garbage Collection',
  'transaction.type.security': 'Security Bill',
  'transaction.type.cleaning': 'Cleaning Bill',
  'transaction.type.electricity': 'Electricity Bill',
  'transaction.type.other': 'Other',
  
  'arrears.due-this-month': 'Due This Month',
  'arrears.past-arrears': 'Past Arrears',
  'arrears.total-arrears': 'Total Arrears',
  'arrears.breakdown-per-category': 'Breakdown Per Category',

  'storage.fileName': 'File Name',
  'storage.rowCount': 'Rows',
  'storage.validCount': 'Valid',
  'storage.uploadDate': 'Upload Date',
  'storage.batchType': 'Batch Type',
  'storage.batchType.tenants': 'Tenants',
  'storage.batchType.collections': 'Collections',
  'storage.batchType.rooms': 'Rooms',

  'uploader.uploadStep.title': 'Upload File',
  'uploader.uploadStep.manifestTitle': 'Data that we expect:',
  'uploader.uploadStep.manifestDescription': '(You will have a chance to rename or remove columns in next steps)',
  'uploader.selectHeaderStep.title': 'Select Header Row',
  'uploader.matchColumnsStep.title': 'Match Columns',
  'uploader.validationStep.title': 'Validate Data',
  'uploader.alerts.confirmClose.headerTitle': 'Exit Import',

  'vendor': 'Vendor Name',
  'vendor.iscompany': 'Is Company?',
  'vendor.category': ' Service Type',
  'vendor.search.placeholder': 'Search Vendor',
  'vendor.add': 'Add Vendor',
  'vendor.edit': 'Edit Vendor',

  'work-task': 'Maintenance Task',
  'work-task.add': 'Add Task',
  'work-task.edit': ' Edit Task',
  'work-task.subject': 'Subject',
  'work-task.description': 'Work To Be Performed',
  'work-task.assignee': 'Assigned To',
  'work-task.collaborators': 'Collaborators',
  'work-task.due-date': 'Due Date',
  'work-task.date-created': 'Date Created',
  'work-task.recurring': 'Is Recurring',
  'work-task.status': 'Status',
  'work-task.status.new': 'New',
  'work-task.status.in-progress': 'In Progress',
  'work-task.status.completed': 'Completed',
  'work-task.status.deffered': 'Deffered',
  'work-task.status.closed': 'Closed',
  'work-task.type': 'Request Type',
  'work-task.type.un-categorized': 'Uncategorized',
  'work-task.type.complaint': 'Complaint',
  'work-task.type.feedback-suggestion': 'Feedback/Suggestion',
  'work-task.type.general-inquiry': 'General Inquiry',
  'work-task.type.maintenance-request': 'Maintenance Request',
  'work-task.type.others': 'Others',
  'work-task.type.closed': 'Closed',
  'work-task.priority': 'Priority',
  'work-task.priority.low': 'Low',
  'work-task.priority.medium': 'Medium',
  'work-task.priority.high': 'High',
  'work-task.last-update': 'Last Update',

  'work-order': 'Work Order',
  'work-order.add': 'Add Work Order',
  'work-order.edit': ' Edit Work Order',
  'work-order.entry-allowed': 'Entry Allowed?',
  'work-order.entry-contact': 'Entry Contact',
  'work-order.labor-boqs': 'Labor & Materials',
  'work-order.vendor-notes': 'Vendor Notes',
  
  'security': 'Security',
  'security.logs': 'Security Logs',
  'security.logs.resource': 'Resource',
  'security.logs.accessed-by': 'Accessed By',
  'security.logs.ip-address': 'IP Address',
  'security.logs.time': 'Time',

  'register.company-info':'Company Info',
  'register.title': 'Complete Registration',
  'register.sign-up': 'Sign Up',
  'register.sign-up-as': 'Welcome to AI Vetting Software. You can get get started by providing details below and a few steps',
  'register.company-info.instruction': 'Please provide the details below to proceed to next step',
  'register.choose-package': 'Choose Package',
  'register.choose-package.instruction': 'Please choose your preferred package and proceed to next step',
  'register.add-users':'Add Users',
  'register.add-users.instruction': 'Please add your application users below to proceed to next step',
  'register.checkout': 'Checkout',
  'register.checkout.instruction': 'Please choose payment method to pay for the selected package',
  'register.finish': 'Finish',
  'register.thank-you': 'Thank You!',
  'register.completed': 'Your registration completed successfully!',
  'register.error': 'An error occured registering account. Please re-check before submitting!',
  'register.creating': 'Creating account...',
  'register.change-password': 'Password Change',
  'register.change-password.description': 'Please use the form below to change your password. A password change is a MUST for new accounts',
  'register.reset-now': 'Reset Now',
  'register.forgot-password': 'Lost Password?',
  'register.forgot-password.description': 'Please enter your email or phone to receive a temporary password. You must change this temporary password once you login',

  'billing.info':'Billing Information',
  'billing.next-date': 'Next Billing',
  'billing.estimated-volume': 'Estimated Volume of Checks Per Cycle',
  'billing.package': 'Package',
  'billing.description': 'Description',
  'billing.sub-total': 'SubTotal',
  'billing.total': 'Total',
  'billing.total-cost': 'Total Cost',
  'billing.no-of-users': 'No of users',
  'billing.volume': 'Volume of Checks',
  'billing.method': 'Payment Method',
  'billing.method.choose': 'Choose Payment Method',
  'billing.method.mpesa-express': 'Mpesa Express',
  'billing.method.mpesa': 'Mpesa Legacy',
  'billing.method.bank': 'Bank Cheque',
  'billing.method.paypal': 'Paypal',
  'billing.methods': 'Payment Methods',
  'billing.mpesa-already-paid': 'MPESA receipt number (If aready Paid)',
  'billing.statement.account': 'Account Statements',
  'billing.advance-payments': 'Advance Payments',
  'billing.current-invoice': 'Current Invoice',
  'billing.past-arrears': 'Past Arrears',
  'billing.promo.apply': 'Apply',
  'billing.promo.code': 'Promo Code',
  'billing.promo.description': 'If you have a promo code, you may apply here!',
  'billing.status': 'Status',
  'billing.transaction-type.receipt': 'Receipt',
  'billing.transaction-type.promo': 'Promo',
  'billing.transaction-type.invoice': 'Invoice',

  'due-diligence.search': 'Enter text to search cases...', 
  'due-diligence.case-number': 'Case Number', 
  'due-diligence.purpose': 'Purpose', 
  'due-diligence.category': 'Category', 
  'due-diligence.category.kyc': 'KYC Due Diligence', 
  'due-diligence.category.individual': 'Individual Due Diligence', 
  'due-diligence.category.business': 'Business Due Diligence', 
  'due-diligence.subject': 'Subject', 
  'due-diligence.status': 'Status', 
  'due-diligence.new-request': 'New Request', 
  'due-diligence.add': 'Add Due Diligence',
  'due-diligence.edit': 'Edit Due Diligence',
  'due-diligence.documents': 'Subject Documents',
  'due-diligence.tax-compliance': 'Tax Compliance',
  'due-diligence.tax-pin': 'Tax PIN',
  'due-diligence.incorporation-certificate': 'Incorporation Certificate',
  'due-diligence.national-id-director': 'National ID (Director)',
  'due-diligence.national-id-shareholder': 'National ID (Shareholder)',
  'due-diligence.business-permit': 'Business Permit',
  'due-diligence.others': 'Others',
  'due-diligence.submitted-on': 'Submitted On',
  
  'bcs': 'Background Checks',
  'bcs.upload.resume': 'Attach Resume', 
  'bcs.upload.others': 'Attach Document', 
  'bcs.all-checks': 'All Checks',
  'bcs.search': 'Enter text to search candidates...',
  'bcs.raise.completed': 'Background check request has been submitted successfully.',
  'bcs.raise.description': 'Customize background check, bulk upload candidate information and submit it in one workflow',
  'bcs.raise.customer':'Customer',
  'bcs.raise.customer.instruction': 'Please search the beneficiary customer details to get started.',
  'bcs.raise.customize':'Customize',
  'bcs.raise.customize.instruction': 'Please choose your package or select particular checks from the available below',
  'bcs.raise.candidates':'Candidates',
  'bcs.raise.candidates.instruction': 'Please add your candidates users or submit through the excel bulk upload below',
  'bcs.date': 'Date',
  'bcs.name': 'Name',
  'bcs.level': 'Level of Check',
  'bcs.levels': 'Levels of Check',
  'bcs.reference-number': 'Reference Number',
  'bcs.consent.via': 'Request Consent Via',
  'bcs.status': 'Status',
  'bcs.status.clear': 'Clear Results', 
  'bcs.status.with-alerts': 'Results With Alerts',
  'bcs.status.new': 'New',
  'bcs.status.awaiting-info': 'Awaiting Info', 
  'bcs.status.awaiting-info.candidate': 'Awaiting Info (From Candidate)', 
  'bcs.status.missing-info': 'Missing Info', 
  'bcs.status.processing': 'Results Processing',
  'bcs.status.unable-to-verify': 'Unable To Verify',
  'bcs.status.minified.clear': 'OK', 
  'bcs.status.minified.with-alerts': 'Alerts',
  'bcs.status.minified.awaiting-info': 'Awaiting Info', 
  'bcs.status.minified.awaiting-info.candidate': 'Awaiting Info', 
  'bcs.status.minified.missing-info': 'Missing Info', 
  'bcs.status.minified.processing': 'Processing',
  'bcs.status.minified.unable-to-verify': 'Unverifiable',
  'bcs.document.type': 'Document Type',
  'bcs.document.choose': 'Choose Document Type',
  'bcs.candidate.count': 'Candidate Count',
  'bcs.candidate.documents': 'Candidate Documents',
  'bcs.candidate.resume': 'Resume', 
  'bcs.candidate.good-conduct': 'Good Conduct',
  'bcs.candidate.national-id': 'National ID', 
  'bcs.candidate.birth-certificate': 'Birth Certificate', 
  'bcs.candidate.academic-certificate': 'Academic Certificate',
  'bcs.candidate.others': 'Others',
  'bcs.candidate.consent': 'Candidate Consent',
  'bcs.candidate.add': 'Add Candidate',
  'bcs.candidate.edit': 'Edit Candidate',
  'bcs.load-resume': 'Load Resume',
  'bcs.print-pdf': 'View PDF',
  'bcs.reset-all': 'Reset ALL',
  'bcs.education.institution': 'Institution',
  'bcs.education.contacts': 'Contacts',
  'bcs.education.email': 'Email',
  'bcs.education.address': 'Address',
  'bcs.education.contact-person': 'Contact Person',
  'bcs.education.qualification': 'Area Of Study',
  'bcs.education.isgraduated': 'Graduated?',
  'bcs.education.period': 'Period',
  'bcs.work-history.period': 'Period',
  'bcs.work-history.job-position': 'Job Position',
  'bcs.work-history.employer': 'Employer',
  'bcs.checks.final-comments': 'Final Comments',
  'bcs.checks.comments': 'Previous Comments',
  'bcs.checks.comments.title': 'Comment Title',
  'bcs.checks.comments.add': 'Add Comments',
  'bcs.checks.documents': 'Previous Documents',
  'bcs.checks.emails': 'Previous Emails',
  'bcs.checks.emails.send': 'Send Email',

  'consents.sent-on': 'Sent On',
  'consents.subject': 'Subject',
  'consents.actioned-on': 'Actioned On',
  'consents.isconsented': 'Is Consented?',
  'consents.actions.approve': 'Approve',
  'consents.actions.reject': 'Reject',
  'consents.candidate.description': 'Verify candidate supplied information and candidate consent',
  'consents.candidate.intro': 'Candidate Intro',
  'consents.candidate.intro.instruction': 'Welcome {subject}. {customer} is using AI Vetting to run your background checks. Please fill in the details below.',
  'consents.candidate.work-history': 'Work History',
  'consents.candidate.work-history.instruction': 'Please fill in and correct missing details in the pre-filled form generated from from your resume',
  'consents.candidate.education': 'Education',
  'consents.candidate.education.instruction': 'Please fill in and correct missing details in the pre-filled form generated from from your resume',
  'consents.candidate.sign': 'Sign Consent',
  'consents.candidate.sign.instruction': 'Welcome {subject}. {customer} is using AI Vetting to run your background checks. Please read this agreement below',
  'consents.candidate.finish': 'Finish',
  'consents.candidate.agree': 'I hereby agree, to the best of my knowledge, to provide true and accurate information',
  'consents.candidate.completed': 'Your background check consent and info has been completed successfully.',
  
  'info-bot.request': 'Search people or organizations...',
  'info-bot.response.title': 'Search Results',
  'info-bot.exact-matches': 'Exact Matches',
  'info-bot.other-matches': 'Other Relevant Info',
  'info-bot.no-exact-matches': 'We couldn\'t find exact matches for based on your search criteria. Please view other relevant info below',

  'customers.date': 'Date Created',
  'customers.name': 'Customer Name',
  'customers.package': 'Active Package',
  'customers.status': 'Status',
  'customers.ispostpay': 'Is Postpay Account',
  'customers.status.pending': 'Pending',
  'customers.status.onboarding': 'Onboarding',
  'customers.status.suspended': 'Suspended',
  'customers.status.active': 'Active',
  'customers.status.dormant': 'Dormant',
  'customers.add': 'Add Customer',
  'customers.edit': 'Edit Customer',

  'orders.add': 'New Order',
  'orders.edit': 'Edit Order',
  'orders.date': 'Order Date',
  'orders.order-number': 'Order Number',
  'orders.status': 'Status',
  'orders.status.pending': 'Pending',
  'orders.status.submitted': 'Submitted',
  'orders.status.partial': 'Partial',
  'orders.status.paid': 'Paid',
  'orders.status.active': 'Active',
  'orders.status.past': 'Past',
};
export default enMessages;
