import { SERVICE_URL } from 'config/config';
import api from '../api';

const pricingInfoList = [
    {
      "pricingID": "61eb0f212c647934b0b09fc5",
      "name": "scs",
      "color": "yellow",
      "singular": "Onboarding Fees",
      "plural": "Setup  & Training Fees",
      "isPerUser": true,
      "isRecurrent": false,
      "featurePricingAllowed": false
    },
    {
      "pricingID": "61eb0f402c647934b0b09fc6",
      "name": "cpu",
      "color": "mediumseagreen",
      "singular": "Subscription Fee",
      "plural": "Subscription Fees",
      "isPerUser": true,
      "isRecurrent": true,
      "featurePricingAllowed": false
    },
    {
      "pricingID": "61eb0f5c2c647934b0b09fc7",
      "name": "sms",
      "color": "slateblue",
      "singular": "Bulk Sms Cost",
      "plural": "Bulk Sms Cost",
      "isPerUser": false,
      "isRecurrent": false,
      "featurePricingAllowed": false
    }
];

api.onGet(`${SERVICE_URL}/pricing-info`).reply(200, pricingInfoList);