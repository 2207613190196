import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'httpService';

export const getPropertyMetrics = createAsyncThunk('analytics/property-metrics', async ({advanced}) => {
    const response = await httpService.get(`dashboard/property-metrics?advanced=${JSON.stringify(advanced)}`);
    return response;
});

export const getCollectionsMetrics = createAsyncThunk('analytics/collections', async ({advanced}) => {
  const response = await httpService.get(`dashboard/collection-metrics?advanced=${JSON.stringify(advanced)}`); 
  return response;
});

export const getArrearsMetrics = createAsyncThunk('analytics/arrears', async ({advanced}) => {
  const response = await httpService.get(`dashboard/arrears-metrics?advanced=${JSON.stringify(advanced)}`); 
  return response;
});

export const getArrearsByCategory = createAsyncThunk('analytics/arrears-by-category', async ({advanced}) => {
  const response = await httpService.get(`dashboard/arrears-by-category?advanced=${JSON.stringify(advanced)}`); 
  return response;
});

export const getCandidateStatusMetrics = createAsyncThunk('analytics/candidate-status-metrics', async ({advanced}) => {
  const response = await httpService.get(`dashboard/candidate-status-metrics?advanced=${JSON.stringify(advanced)}`); 
  return response;
});