import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const batchProcessAiSlice = createSlice({
  name: 'batchProcessAi',
  initialState: initialState,
  reducers: {
    setBatchProcess: (state,action)=>{ 
      state.profile.data = action?.payload || state.data;
    },
    setBatchProcesses: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {
    
  }
});

const batchProcessAiReducer = batchProcessAiSlice.reducer;
export const { setBatchProcess, setBatchProcesses } = batchProcessAiSlice.actions;
export default batchProcessAiReducer;