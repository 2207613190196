import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const packageAiSlice = createSlice({
  name: 'packageAi',
  initialState: initialState,
  reducers: {
    setPackage: (state,action)=>{ 
      state.profile.data = action?.payload || state.data;
    },
    setPackages: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {

  }
});

const packageAiReducer = packageAiSlice.reducer;
export const { setPackage, setPackages } = packageAiSlice.actions;
export default packageAiReducer;