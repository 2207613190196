import api from '@mock-api/api';
import { checkTree } from '@mock-api/data/candidates';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import httpService from 'httpService';

export const countCandidates = createAsyncThunk('candidates/count', async (advanced) => {
  const where = JSON.stringify({...(advanced || {})});
  const response = await httpService.get(`candidates/count?where=${where}`);
  return response?.count;
});

export const getCandidates = createAsyncThunk('candidates/list', async (advanced) => {
  const filter = JSON.stringify((advanced || {}));
  const response = await httpService.get(`candidates?filter=${filter}`);
  return response;
});

export const getCandidate = createAsyncThunk('candidates/get', async (id) => {
  const response = await httpService.get(`candidates/${id}`);
  return response;
});

export const getCheckTree = createAsyncThunk('candidates/get-background-tree/id', async (id) => {
  const response = Promise.resolve([...checkTree]);
  return response;
});

export const saveCandidate = createAsyncThunk('candidates/save', async (data) => { 
  const response = await httpService.post(`candidates`,data);
  return response;
});

export const updateCandidate = createAsyncThunk('candidates/update', async ({candidateID,data}) => { 
  const response = await httpService.patch(`candidates/${candidateID}`,data);
  return response;
});

export const submitConsent = createAsyncThunk('candidates/submit-consent/{id}', async ({candidateID,data}) => { 
  const response = await httpService.patch(`candidates/submit-consent/${candidateID}`,data);
  return response;
});

export const updateCandidateChecks = createAsyncThunk('candidates/update-checks', async ({candidateID,data}) => { 
  const response = await httpService.patch(`candidates/update-checks/${candidateID}`,data);
  return response;
});

export const deleteCandidate = createAsyncThunk('candidates/delete', async (id) => { 
  const response = await httpService.delete(`candidates/${id}`);
  return response;
});

export const autocompleteCandidates = createAsyncThunk('candidates/autocomplete', async ({term,advanced}) => {
    const response = await httpService.get(`candidates/autocomplete?term=${term}&advanced=${advanced || ''}`);
    return response;
});
