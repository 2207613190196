import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  myProfile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const userAiSlice = createSlice({
  name: 'userAi',
  initialState: initialState,
  reducers: {
    setUser: (state,action)=>{ 
      state.profile.data = action?.payload || state.data;
    },
    setMyProfile: (state,action)=>{ 
      state.myProfile.data = action?.payload || state.data;
    },
    setUsers: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {

  }
});

const userAiReducer = userAiSlice.reducer;
export const { setUser, setUsers, setMyProfile } = userAiSlice.actions;
export default userAiReducer;