import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const backgroundAiSlice = createSlice({
  name: 'backgroundAi',
  initialState: initialState,
  reducers: {
    setBackgroundOrder: (state,action)=>{ 
      state.profile.data = action?.payload || state.data;
    },
    setBackgroundOrders: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {

  }
});

const backgroundAiReducer = backgroundAiSlice.reducer;
export const { setBackgroundOrder, setBackgroundOrders } = backgroundAiSlice.actions;
export default backgroundAiReducer;