import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
}

const dashboardAiSlice = createSlice({
  name: 'dashboardAi',
  initialState: initialState,
  reducers: {
    setLoading: (state,action)=>{
      state.loading = action?.payload;
    }
  },
  extraReducers: (builder) => {
    
  }
});

const dashboardAiReducer = dashboardAiSlice.reducer;
export const { setLoading } = dashboardAiSlice.actions;
export default dashboardAiReducer;