const enMessages = {
  'nothing-found': 'de|Empty',
  'operation-new': 'de|You can now get started by clicking button on bottom right',
  'operation-search': 'de|You can now get started by searching for information above',
  'operation-add-above': 'de|You can now get started by adding information above',
  'title.operation': 'de|Data Operation',
  'title.results': 'de|Data Results',
  'title.processing': 'de|Data Processing',
  'title.error': 'de|Error Processing',
  'request.processing': 'de|Processing...',
  'request.processing-wait': 'de|Kindly wait for a few moments as the system processses your request...',
  'response.retrieved': 'de|Data has been retrieved successfully',
  'response.saved': 'de|Data has been saved successfully',
  'response.updated': 'de|Data has been updated successfully',
  'response.deleted': 'de|Data has been deleted successfully',
  'response.uploaded': 'de|Data has been uploaded successfully',

  'errors.expired-session': 'de|This session is expired. Please logout and login again!',
  'errors.invalid-inputs': 'de|Invalid input or wrong format. Please correct the form inputs then try again!',
  'errors.unexpected': 'de|An unexpected error occured. Please later!',
  'errors.form.controls': 'de|Correct errors on form before submit.',
  'errors.form.date-range': 'de|End date should be greater than start date',
  'errors.template.not-found': 'de|Sorry! The template for this check category could not be found!',
  'errors.records.not-found': 'de|Sorry! No records could be found. Please check again later.',

  'navigation.go-back': 'de|Go Back',
  'navigation.previous': 'de|Previous',
  'navigation.next': 'de|Next',

  'menu.dashboards': 'de|Dashboards',
  'menu.default': 'de|Default',
  'menu.visual': 'de|Visual',
  'menu.analytic': 'de|Analytic',
  'menu.apps': 'de|Apps',
  'menu.calendar': 'de|Calendar',
  'menu.language': 'de|Language',
  'menu.chat': 'de|Chat',
  'menu.contacts': 'de|Contacts',
  'menu.mailbox': 'de|Mailbox',
  'menu.tasks': 'de|Tasks',
  'menu.maintenance': 'de|Maintenance',
  'menu.vendors': 'de|Vendors',
  'menu.pages': 'de|Pages',
  'menu.authentication': 'de|Authentication',
  'menu.login': 'de|Login',
  'menu.register': 'de|Register',
  'menu.forgot-password': 'de|Forgot Password',
  'menu.reset-password': 'de|Reset Password',
  'menu.blog': 'de|Blog',
  'menu.home': 'de|Home',
  'menu.grid': 'de|Grid',
  'menu.list': 'de|List',
  'menu.detail': 'de|Detail',
  'menu.miscellaneous': 'de|Miscellaneous',
  'menu.faq': 'de|Faq',
  'menu.knowledge-base': 'de|Knowledge Base',
  'menu.error': 'de|Error',
  'menu.coming-soon': 'de|Coming Soon',
  'menu.pricing': 'de|Pricing',
  'menu.search': 'de|Search',
  'menu.mailing': 'de|Mailing',
  'menu.empty': 'de|Empty',
  'menu.portfolio': 'de|Portfolio',
  'menu.profile': 'de|Profile',
  'menu.standard': 'de|Standard',
  'menu.settings': 'de|Settings',
  'menu.blocks': 'de|Blocks',
  'menu.images': 'de|Images',
  'menu.tabular-data': 'de|Tabular Data',
  'menu.thumbnails': 'de|Thumbnails',
  'menu.cta': 'de|Cta',
  'menu.gallery': 'de|Gallery',
  'menu.stats': 'de|Stats',
  'menu.steps': 'de|Steps',
  'menu.details': 'de|Details',
  'menu.interface': 'de|Interface',
  'menu.components': 'de|Components',
  'menu.accordion': 'de|Accordion',
  'menu.alerts': 'de|Alerts',
  'menu.badge': 'de|Badge',
  'menu.breadcrumb': 'de|Breadcrumb',
  'menu.buttons': 'de|Buttons',
  'menu.button-group': 'de|Button Group',
  'menu.card': 'de|Card',
  'menu.close-button': 'de|Close Button',
  'menu.collapse': 'de|Collapse',
  'menu.dropdowns': 'de|Dropdowns',
  'menu.list-group': 'de|List Group',
  'menu.modal': 'de|Modal',
  'menu.navs': 'de|Navs',
  'menu.offcanvas': 'de|Offcanvas',
  'menu.pagination': 'de|Pagination',
  'menu.popovers': 'de|Popovers',
  'menu.progress': 'de|Progress',
  'menu.spinners': 'de|Spinners',
  'menu.toasts': 'de|Toasts',
  'menu.tooltips': 'de|Tooltips',
  'menu.forms': 'de|Forms',
  'menu.layouts': 'de|Layouts',
  'menu.validation': 'de|Validation',
  'menu.wizard': 'de|Wizard',
  'menu.input-group': 'de|Input Group',
  'menu.input-mask': 'de|Input Mask',
  'menu.generic-forms': 'de|Generic Forms',
  'menu.controls': 'de|Controls',
  'menu.autocomplete': 'de|Autocomplete',
  'menu.checkbox-radio': 'de|Checkbox-Radio',
  'menu.date-picker': 'de|Date Picker',
  'menu.dropzone': 'de|Dropzone',
  'menu.editor': 'de|Editor',
  'menu.input-spinner': 'de|Input Spinner',
  'menu.rating': 'de|Rating',
  'menu.select': 'de|Select',
  'menu.slider': 'de|Slider',
  'menu.tags': 'de|Tags',
  'menu.time-picker': 'de|Time Picker',
  'menu.plugins': 'de|Plugins',
  'menu.carousel': 'de|Carousel',
  'menu.charts': 'de|Charts',
  'menu.clamp': 'de|Clamp',
  'menu.context-menu': 'de|Context Menu',
  'menu.datatables': 'de|Datatables',
  'menu.editable-rows': 'de|Editable Rows',
  'menu.editable-boxed': 'de|Editable Boxed',
  'menu.server-side': 'de|Server Side',
  'menu.boxed-variations': 'de|Boxed Variations',
  'menu.lightbox': 'de|Lightbox',
  'menu.maps': 'de|Maps',
  'menu.notification': 'de|Notification',
  'menu.players': 'de|Players',
  'menu.scrollbar': 'de|Scrollbar',
  'menu.shortcuts': 'de|Shortcuts',
  'menu.sortable': 'de|Sortable',
  'menu.content': 'de|Content',
  'menu.icons': 'de|Icons',
  'menu.cs-line-icons': 'de|CS Line Icons',
  'menu.cs-interface-icons': 'de|CS Interface Icons',
  'menu.bootstrap-icons': 'de|Bootstrap Icons',
  'menu.tables': 'de|Tables',
  'menu.typography': 'de|Typography',
  'menu.menu': 'de|Menu',
  'menu.horizontal': 'de|Horizontal',
  'menu.vertical': 'de|Vertical',
  'menu.vertical-hidden': 'de|Vertical Hidden',
  'menu.vertical-no-hidden': 'de|Vertical No Hidden',
  'menu.mobile-only': 'de|Mobile Only',
  'menu.sidebar': 'de|Sidebar',
  'menu.Docs': 'de|Docs',
  'menu.connections': 'de|Connections',
  'menu.bookmarks': 'de|Bookmarks',
  'menu.requests': 'de|Requests',
  'menu.account': 'de|Account',
  'menu.password': 'de|Password',
  'menu.devices': 'de|Devices',
  'menu.notifications': 'de|Notifications',
  'menu.email': 'de|Email',
  'menu.sms': 'de|SMS',
  'menu.downloads': 'de|Downloads',
  'menu.documents': 'de|Documents',
  'menu.videos': 'de|Videos',
  'menu.dashboard': 'de|Dashboard',
  'menu.products': 'de|Products',
  'menu.orders': 'de|Bulk Orders',
  'menu.customers': 'de|Customers',
  'menu.storefront': 'de|Storefront',
  'menu.filters': 'de|Filters',
  'menu.categories': 'de|Categories',
  'menu.cart': 'de|Cart',
  'menu.checkout': 'de|Checkout',
  'menu.invoice': 'de|Invoice',
  'menu.shipping': 'de|Shipping',
  'menu.discount': 'de|Discount',
  'menu.general': 'de|General',
  'menu.vetting': 'de|Candidates',
  'menu.consents': 'de|Consents',
  'menu.due-diligence': 'de|Due Diligence',
  'menu.due-diligence-run': 'de|Run Due Diligence',
  'menu.archives': 'de|Archives',
  'menu.info-bot': 'de|Info Bot',
  'menu.investigate': 'de|Run Background',

  'menu.upgrade': 'de|Upgrade',
  'menu.community': 'de|Community',
  'menu.community-list': 'de|Community List',
  'menu.getting-started': 'de|Getting Started',
  'menu.analysis': 'de|Analysis',
  'menu.services': 'de|Services',
  'menu.property': 'de|Property',
  'menu.property-add': 'de|New Property',
  'menu.property-detail': 'de|Property Detail',
  'menu.tenants': 'de|Tenants',
  'menu.tenant-add': 'de|New Tenant',
  'menu.owners': 'de|Owners',
  'menu.owner-add': 'de|Add Owner',
  'menu.collections': 'de|Collections',
  'menu.arrears': 'de|Arrears',
  'menu.leases': 'de|Leases',
  'menu.uploads': 'de|Uploads',
  'menu.resources': 'de|Resources',
  'menu.hosting': 'de|Hosting',
  'menu.users': 'de|Users',
  'menu.billing': 'de|Billing',
  'menu.security': 'de|Security',
  'menu.support': 'de|Support',
  'menu.docs': 'de|Docs',
  'menu.docsdetail': 'de|Docs Detail',
  'menu.tickets': 'de|Tickets',
  'menu.tickets-detail': 'de|Teicket Detail',

  'username': 'de|Username',
  'password': 'de|Password',
  'old-password': 'de|Old Password',
  'verify-password': 'de|Verify Password',
  'client-code': 'de|Client Code',
  'id-number': 'de|National ID',
  'invoice-number': 'de|Invoice Number',
  'employee-number': 'de|Employee Number',
  'physical-address': 'de|Location',
  'postal-address': 'de|Postal Address',
  'residential-address': 'de|Residential Address',
  'country': 'de|Country',
  'nationality': 'de|Nationality',
  'website': 'de|Website',
  'initials': 'de|Initials',
  'currency': 'de|Currency',
  'company': 'de|Company Name',
  'unassigned': 'de|Unassigned',
  'add': 'de|Add',
  'new': 'de|New',
  'amend': 'de|Amend',
  'view': 'de|View',
  'delete': 'de|Delete',
  'resume': 'de|Resume',
  'copy': 'de|Copy',
  'submit': 'de|Submit',
  'ok': 'de|OK',
  'cancel': 'de|Cancel',
  'back': 'de|Back',
  'next': 'de|Next',
  'Y': 'de|Yes',
  'N': 'de|No',
  'suspend': 'de|Suspend',
  'activate': 'de|Activate',
  'close': 'de|Close',
  'no-change': 'de|No Change',
  'verify': 'de|Verify',
  'quick-add': 'de|Quick Add',
  'quick-actions': 'de|Quick Actions',
  'bulk-upload': 'de|Bulk Upload',
  'bulk-upload.bills': 'de|Upload Bills',
  'view-more': 'de|View More',
  'random-search': 'de|Enter text to search...',
  'recent-activity': 'de|Recent Activity',
  'stats': 'de|Stats',
  'recent-activity.teller': 'de|Recent Teller Transactions and Activities',
  'actions': 'de|Actions',
  'select-year': 'de|Year',
  'select-month': 'de|Month',
  'select-day': 'de|Day', 
  'ago': 'de|Ago',
  'start-date': 'de|Start Date',
  'end-date': 'de|End Date',
  'from': 'de|From',
  'to': 'de|To',
  'dob': 'de|Date of Birth',
  'total-count': 'de|Total Count',
  'payment-details': 'de|Payment Details',
  'amount-distribution': 'de|Amount Distribution',
  'gender': 'Gender',
  'gender.male': 'Male',
  'gender.female': 'Female',
  'gender.other': 'Other',
  'none': 'None',

  'language.en': 'de|English',
  'language.sw': 'de|Swahili',
  'language.fr': 'de|Francais',
  'language.de': 'de|German',
  'language.es': 'de|Spanish',

  'crud.actions': 'de|Action',
  'crud.actions.add': 'de|Add New',
  'crud.actions.edit': 'de|Edit',
  'crud.actions.save': 'de|Save',
  'crud.actions.update': 'de|Update',
  'crud.actions.archive': 'de|Archive',
  'crud.actions.delete': 'de|Delete',
  'crud.actions.detail': 'de|View',
  'crud.actions.list': 'de|List',
  'crud.actions.bulk-download': 'de|Bulk Download',
  'crud.actions.search-record': 'de|Search Record',

  'alert.delete.title': 'de|Confirm Delete',
  'alert.delete.message': 'de|Do you really want to delete selected record(s)?',
  'alert.archive.title': 'de|Confirm Archive',
  'alert.archive.message': 'de|Do you really want to archive selected record(s)?',
  'alert.submit.title': 'de|Confirm Submit',
  'alert.mpesa.message': 'de|You are about to initiate an MPESA payment. Are you sure?',
  'alert.submit.message': 'de|Do you really want to save change(s) on record(s)?',
  'alert.language.title': 'de|Language Change',
  'alert.language.message': 'de|Do you really want to switch to selected language(s)?',
  'alert.signup.message': 'de|You are about to create an account. Are you sure?',
  'alert.change-password.message': 'de|You are about to change your password. Are you sure?',
  'alert.reset-password.message': 'de|You are about to reset your password. Are you sure?',
  'alert.change-password.success': 'de|Your password has been changed successfully.',
  'alert.reset-password.success': 'de|Your password reset code has been sent to email/sms',
  'alert.reset-password.failure': 'de|Attempt to reset password failed. Please retry after 1 minute!',
  'alert.otp-sent.success': 'de|Your OTP code has been sent to email/sms',
  'alert.otp-validated.success': 'de|Your OTP code has been verified successfully',
  'alert.registration.success': 'de|You are now a new user on the platform. Please check your email/sms for login credentials',
  'alert.registration.failure': 'de|User account registration has failed. Please retry after 1 minute!',
  'alert.account-lock': 'de|User account has been locked.',
  'alert.account-suspended': 'de|Your account has been suspended after many unsuccessful logins. Reach out to the admnistrator',
  
  'timespan.custom': 'de|Custom Date',
  'timespan.today': 'de|Today',
  'timespan.yesterday': 'de|Yesterday', 
  'timespan.last-7-days': 'de|Last 7 Days',
  'timespan.this-week': 'de|This Week',
  'timespan.last-week': 'de|Last Week',
  'timespan.this-month': 'de|This Month',
  'timespan.last-month': 'de|Last Month',
  'timespan.this-year': 'de|This Year', 
  'timespan.last-year': 'de|Last Year', 

  'period-cycle': 'de|Billing Cycle',
  'period-cycle.none': 'de|None',
  'period-cycle.daily': 'de|Daily',
  'period-cycle.weekly': 'de|Weekly',
  'period-cycle.every-2-weeks': 'de|Every 2 Weeks',
  'period-cycle.monthly': 'de|Monthly',
  'period-cycle.every-2-months': 'de|Every 2 Months',
  'period-cycle.quarterly': 'de|Quarterly',
  'period-cycle.twice-a-year': 'de|Twice A Year', 
  'period-cycle.yearly': 'de|Yearly', 

  'days.mon.short': 'de|Mon',
  'days.tue.short': 'de|Tue',
  'days.wed.short': 'de|Wed',
  'days.thu.short': 'de|Thu',
  'days.fri.short': 'de|Fri',
  'days.sat.short': 'de|Sat', 
  'days.sun.short': 'de|Sun',
  
  'months.jan.short': 'de|Jan',
  'months.feb.short': 'de|Feb',
  'months.mar.short': 'de|Mar',
  'months.apr.short': 'de|Apr',
  'months.may.short': 'de|May',
  'months.jun.short': 'de|Jun', 
  'months.jul.short': 'de|Jul',
  'months.aug.short': 'de|Aug',
  'months.sep.short': 'de|Sep',
  'months.oct.short': 'de|Oct',
  'months.nov.short': 'de|Nov',
  'months.dec.short': 'de|Dec',

  'notification': 'de|Notification',
  'notification.channel': 'de|Notification Channel',

  'login.via': 'de|Login Via',
  'login.forgot': 'de|Forgot?',
  'login.sign-in': 'de|Sign In',
  'login.sign-out': 'de|Logout',
  'login.sign-out-msg': 'de|You have successfully logged out',
  'login.use-credentials': 'de|Or use your credentials to login.',
  'login.go-dashboard': 'de|Go Dashboard',
  'login.2factor': 'de|OTP Validation',
  'login.2factor.description': 'de|The application needs to securely authenticate you onto the system. Please enter OTP Code sent to your email or SMS',
  'login.2factor.otp': 'de|OTP Code',
  'login.2factor.send':'de|Get OTP',
  'login.2factor.resend':'de|Resend',
  'login.2factor.channel': 'de|Change where to receive the OTP Code',
  'login.motto': 'de|The leading, AI based solution to candidate screening, background checks, risk assesment and due diligence.',
  
  'dashboard.metrics': 'de|Property Metrics',
  'dashboard.performance-stats': 'de|Performance Stats',
  'dashboard.quick-reports': 'de|Quick Reports',
  'dashboard.description': 'de|Intelligent property & tenant management, transaction reconciliation, and realtime reports.',
  'dashboard.search.title': 'de|Tenant Search',
  'dashboard.search.placeholder': 'de|Name or ID Number',
  'dashboard.search.description': 'de|Please enter Tenant\'s Name or ID number to search in your database',
  'customer.search.title': 'de|Customer Search',
  'customer.search.placeholder': 'de|Customer Name',
  'customer.search.description': 'de|Please enter Customer\'s Name to search in your database',

  'property.info': 'de|Property Info',
  'property.name': 'de|Property Name',
  'property.description': 'de|Description',
  'property.location': 'de|Location',
  'property.manager': 'de|Manager',
  'property.status': 'de|Status',
  'property.status.active': 'de|Active',
  'property.status.inactive': 'de|Inactive',
  'property.status.suspended': 'de|Suspended',
  'property.tenants': 'de|Tenants',
  'property.vacant': 'de|Vacant Rooms',
  'property.occupied': 'de|Occupied Rooms',
  'property.advances': 'de|Advance Payments',
  'property.arrears': 'de|Rent Arrears',
  'property.rent': 'de|Rent P.m',
  'property.security-deposit': 'de|Security Deposit',
  'property.other-charges': 'de|Other Charges',
  'property.room-number': 'de|Room No',
  'property.rooms': 'de|Rooms',
  'property.room-history': 'de|Room History',
  'property.occupancy': 'de|Occupancy',
  'property.collections-today': 'de|Collections Today',
  'property.collections': 'de|Rent Collections',
  'property.billing-day': 'de|Billing Cycle',
  'property.actual-rent': 'de|Actual Rent',
  'property.projected-rent': 'de|Projected Rent',
  'property.choose-room-types': 'de|Choose Room Types',
  'property.choose-amenities': 'de|Choose Amenities',
  'property.describe': 'de|Describe The Property',
  'property.manage-rooms': 'de|Manage Rooms',

  'amenities.water': 'de|Water',
  'amenities.internet': 'de|Internet',
  'amenities.garbage-collection': 'de|Garbage Collection',
  'amenities.security': 'de|Security',
  'amenities.cleaning': 'de|Cleaning',
  'amenities.electricity': 'de|Electricity',  
  'amenities.electronic-repairs': 'de|Electronic Repairs',
  'amenities.flooring-maintenance': 'de|Flooring Maintenance',
  'amenities.landscaping': 'de|Landscaping',
  'amenities.masonry-services': 'de|Masonry Services',
  'amenities.interior-design': 'de|Interior Design',
  'amenities.painting-services': 'de|Painting Services',
  'amenities.plumbing-services': 'de|Plumbing Services',
  'amenities.roofing-repairs': 'de|Roofing Repairs',
  'amenities.windows-maintenance': 'de|Windows Maintenance',
  'amenities.others': 'de|Others',

  'room.type': 'de|Room Type',
  'room.type.1BR': 'de|1 Bedroom',
  'room.type.2BR': 'de|2 Bedroom',
  'room.type.3BR': 'de|3 Bedroom',
  'room.type.SNR': 'de|Single Room',
  'room.type.DBR': 'de|Double Room',
  'room.type.BST': 'de|Bedsitter',
  'room.status': 'de|Room Status',
  'room.status.occupied': 'de|Occupied',
  'room.status.vacant': 'de|Vacant',
  'room.add': 'de|Add Room',
  'room.edit': 'de|Edit Room',
  'room.search.placeholder': 'de|Search Property',

  'tenant.name': 'de|Tenant Name',  
  'tenant.current': 'de|Current Tenant',  
  'tenant.past': 'de|Past Tenants',  
  'tenant.profile': 'de|Profile',
  'tenant.account-statement': 'de|Account Statement',
  'tenant.add': 'de|Add Tenant',
  'tenant.edit': 'de|Edit Tenant',
  'tenant.suspend': 'de|Suspend Tenant',
  'tenant.activate': 'de|Activate Tenant',
  'tenant.status': 'de|Tenant Status',
  'tenant.status.active': 'de|Active',
  'tenant.status.inactive': 'de|Inctive',
  'tenant.leases': 'de|Tenant Leases',
  
  'lease': 'de|Lease',
  'lease.details': 'de|Lease Details',
  'lease.days-left': 'de|Days Left',
  'lease.type': 'de|Lease Type',
  'lease.rent-cycle': 'de|Rent Cycle',
  'lease.type.fixed': 'de|Fixed',
  'lease.type.rollover': 'de|Fixed With RollOver',
  'lease.type.monthly': 'de|Month To Month',
  'lease.status': 'de|Lease Status',
  'lease.status.active': 'de|Active',
  'lease.status.expired': 'de|Expired',
  'lease.agreement': 'de|Agreement',
  'lease.add': 'de|Add Lease',
  'lease.edit': 'de|Edit Lease',
  'lease.renew': 'de|Renew',
  'lease.receive-payment': 'de|Receive Payment',
  'lease.search-lease': 'de|Search Lease',

  'profile': 'de|Profile', 
  'profile.type': 'de|Profile Type',
  'profile.type.admin': 'de|Administrator',
  'profile.type.data-admin': 'de|Data Admin',
  'profile.type.investigator': 'de|Investigator',
  'profile.type.accountant': 'de|Accountant',
  'profile.type.customer-admin': 'de|Customer Admin',
  'profile.type.customer-user': 'de|Customer User',
  'profile.name': 'de|Full Name', 
  'profile.email': 'de|Email',
  'profile.phone': 'de|Phone',
  'profile.address': 'de|Address',
  'profile.active': 'de|Is Active',
  'profile.contact-info': 'de|Contact Info',  
  'profile.personal-info': 'de|Personal Info',  
  'profile.public-info': 'de|Public Info',
  'profile.email-settings': 'de|Email Settings',
  'profile.language-settings': 'de|Language Settings',
  'profile.add': 'de|Add Profile',
  'profile.edit': 'de|Edit Profile', 

  'transactions': 'de|Transactions', 
  'transactions.count': 'de|No. of Transactions',
  'transactions.volume': 'de|Volume of Transactions',
  'transactions.fees': 'de|Transaction Fees',
  'transactions.reconciled': 'de|Reconciled Transactions',
  'transactions.pending': 'de|Pending Transactions',
  
  'transaction.reference': 'de|A/C REF',
  'transaction.message': 'de|Enter Transaction Message',
  'transaction.new-payment': 'de|New Payment',
  'transaction.new-message': 'de|You can add a missing payment below. Paste a raw transaction message from the Bank or Mpesa',
  'transaction.transact': 'de|Transact',
  'transaction.date': 'de|Date',
  'transaction.amount': 'de|Amount',
  'transaction.balance': 'de|Balance',
  'transaction.type': 'de|Item Type',
  'transaction.code': 'de|Transaction Code',
  'transaction.receipt-number': 'de|Receipt No.',
  'transaction.particulars': 'de|Particulars',
  'transaction.type.payment': 'de|Payment',
  'transaction.type.rent': 'de|Rent Bill',
  'transaction.type.water': 'de|Water Bill',
  'transaction.type.internet': 'de|Internet Bill',
  'transaction.type.garbage-collection': 'de|Garbage Collection',
  'transaction.type.security': 'de|Security Bill',
  'transaction.type.cleaning': 'de|Cleaning Bill',
  'transaction.type.electricity': 'de|Electricity Bill',
  'transaction.type.other': 'de|Other',

  'arrears.due-this-month': 'de|Due This Month',
  'arrears.past-arrears': 'de|Past Arrears',
  'arrears.total-arrears': 'de|Total Arrears',
  'arrears.breakdown-per-category': 'de|Breakdown Per Category',

  'storage.fileName': 'de| File Name',
  'storage.rowCount': 'de| Rows',
  'storage.validCount': 'de| Valid',
  'storage.uploadDate': 'de| Upload Date',
  'storage.batchType': 'de| Batch Type',
  'storage.batchType.tenants': 'de| Tenants',
  'storage.batchType.collections': 'de| Collections',
  'storage.batchType.rooms': 'de| Rooms',

  'uploader.uploadStep.title': 'de|Upload File',
  'uploader.uploadStep.manifestTitle': 'de|Data that we expect:',
  'uploader.uploadStep.manifestDescription': 'de|(You will have a chance to rename or remove columns in next steps)',
  'uploader.selectHeaderStep.title': 'de|Select Header Row',
  'uploader.matchColumnsStep.title': 'de|Match Columns',
  'uploader.validationStep.title': 'de|Validate Data',
  'uploader.alerts.confirmClose.headerTitle': 'de|Exit Import',

  'vendor': 'de| Vendor Name',
  'vendor.iscompany': 'de| Is Company?',
  'vendor.category': 'de| Service Type',
  'vendor.search.placeholder': 'de| Search Vendor',
  'vendor.add': 'de|Add Vendor',
  'vendor.edit': 'de|Edit Vendor',

  'work-task': 'de| Maintenance Task',
  'work-task.add': 'de| Add Task',
  'work-task.edit': 'de| Edit Task',
  'work-task.subject': 'de| Subject',
  'work-task.description': 'de| Work To Be Performed',
  'work-task.assignee': 'de| Assigned To',
  'work-task.collaborators': 'de| Collaborators',
  'work-task.due-date': 'de| Due Date',
  'work-task.date-created': 'de| Date Created',
  'work-task.recurring': 'de| Is Recurring',
  'work-task.status': 'de| Status',
  'work-task.status.new': 'de| New',
  'work-task.status.in-progress': 'de| In Progress',
  'work-task.status.completed': 'de| Completed',
  'work-task.status.deffered': 'de| Deffered',
  'work-task.status.closed': 'de| Closed',
  'work-task.type': 'de| Request Type',
  'work-task.type.un-categorized': 'de| Uncategorized',
  'work-task.type.complaint': 'de| Complaint',
  'work-task.type.feedback-suggestion': 'de| Feedback/Suggestion',
  'work-task.type.general-inquiry': 'de| General Inquiry',
  'work-task.type.maintenance-request': 'de| Maintenance Request',
  'work-task.type.others': 'de| Others',
  'work-task.type.closed': 'de| Closed',
  'work-task.priority': 'de| Priority',
  'work-task.priority.low': 'de| Low',
  'work-task.priority.medium': 'de| Medium',
  'work-task.priority.high': 'de| High',
  'work-task.last-update': 'de| Last Update',

  'work-order': 'de| Work Order',
  'work-order.add': 'de| Add Work Order',
  'work-order.edit': 'de| Edit Work Order',
  'work-order.entry-allowed': 'de| Entry Allowed?',
  'work-order.entry-contact': 'de| Entry Contact',
  'work-order.labor-boqs': 'de| Labor & Materials',
  'work-order.vendor-notes': 'de| Vendor Notes',

  'security': 'de| Security',
  'security.logs': 'de| Security Logs',
  'security.logs.resource': 'de| Resource',
  'security.logs.accessed-by': 'de| Accessed By',
  'security.logs.ip-address': 'de| IP Address',
  'security.logs.time': 'de| Time',

  'register.company-info':'de|Company Info',
  'register.title': 'de|Registration',
  'register.sign-up': 'de|Sign Up',
  'register.sign-up-as': 'de|Welcome to AI Vetting Software. You can get get started by providing details below and a few steps',
  'register.company-info.instruction': 'de|Please provide the details below to proceed to next step',
  'register.choose-package': 'de|Choose Package',
  'register.choose-package.instruction': 'de|Please choose your preferred package and proceed to next step',
  'register.add-users':'de|Add Users',
  'register.add-users.instruction': 'de|Please add your application users below to proceed to next step',
  'register.checkout': 'de|Checkout',
  'register.checkout.instruction': 'de|Please choose payment method to pay for the selected package',
  'register.finish': 'de|Finish',
  'register.thank-you': 'de|Thank You!',
  'register.completed': 'de|Your registration completed successfully!',
  'register.error': 'de|An error occured registering account. Please re-check before submitting!',
  'register.creating': 'de|Creating account...',
  'register.change-password': 'de|Password Change',
  'register.change-password.description': 'de|Please use the form below to change your password. A password change is a MUST for new accounts',
  'register.reset-now': 'de|Reset Now',
  'register.forgot-password': 'de|Lost Password?',
  'register.forgot-password.description': 'de|Please enter your email or phone to receive a temporary password. You must change this temporary password once you login',

  'billing.info':'de|Billing Information',
  'billing.next-date': 'de|Next Billing',
  'billing.estimated-volume': 'de|Estimated Volume of Checks Per Cycle',
  'billing.package': 'de|Package',
  'billing.description': 'de|Description',
  'billing.sub-total': 'de|SubTotal',
  'billing.total': 'de|Total',
  'billing.total-cost': 'de|Total Cost',
  'billing.no-of-users': 'de|No of users',
  'billing.volume': 'de|Volume of Checks',
  'billing.method': 'de|Payment Method',
  'billing.method.choose': 'de|Choose Payment Method',
  'billing.method.mpesa-express': 'de|Mpesa Express',
  'billing.method.mpesa': 'de|Mpesa Legacy',
  'billing.method.bank': 'de|Bank Cheque',
  'billing.method.paypal': 'de|Paypal',
  'billing.methods': 'de|Payment Methods',
  'billing.mpesa-already-paid': 'de|MPESA receipt number (If aready Paid)',
  'billing.statement.account': 'de|Account Statements',
  'billing.advance-payments': 'de|Advance Payments',
  'billing.current-invoice': 'de|Current Invoice',
  'billing.past-arrears': 'de|Past Arrears',
  'billing.promo.apply': 'de|Apply',
  'billing.promo.code': 'de|Promo Code',
  'billing.promo.description': 'de|If you have a promo code, you may apply here!',
  'billing.status': 'de|Status',
  'billing.transaction-type.receipt': 'de|Receipt',
  'billing.transaction-type.promo': 'de|Promo',
  'billing.transaction-type.invoice': 'de|Invoice',

  'due-diligence.search': 'de|Enter text to search cases...', 
  'due-diligence.case-number': 'de|Case Number', 
  'due-diligence.purpose': 'de|Purpose', 
  'due-diligence.category': 'de|Category', 
  'due-diligence.category.kyc': 'de|KYC Due Diligence', 
  'due-diligence.category.individual': 'de|Individual Due Diligence', 
  'due-diligence.category.business': 'de|Business Due Diligence', 
  'due-diligence.subject': 'de|Subject', 
  'due-diligence.status': 'de|Status', 
  'due-diligence.new-request': 'de|New Request', 
  'due-diligence.add': 'de|Add Due Diligence',
  'due-diligence.edit': 'de|Edit Due Diligence',
  'due-diligence.documents': 'de|Subject Documents',
  'due-diligence.tax-compliance': 'de|Tax Compliance',
  'due-diligence.tax-pin': 'de|Tax PIN',
  'due-diligence.incorporation-certificate': 'de|Incorporation Certificate',
  'due-diligence.national-id-director': 'de|National ID (Director)',
  'due-diligence.national-id-shareholder': 'de|National ID (Shareholder)',
  'due-diligence.business-permit': 'de|Business Permit',
  'due-diligence.others': 'de|Others',
  'due-diligence.submitted-on': 'de|Submitted On',

  'bcs': 'de|Background Checks',
  'bcs.upload.resume': 'de|Attach Resume', 
  'bcs.upload.others': 'de|Attach Document', 
  'bcs.all-checks': 'de|All Checks',
  'bcs.search': 'de|Enter text to search candidates...',
  'bcs.raise.completed': 'de|Background check request has been submitted successfully.',
  'bcs.raise.description': 'de|Customize background check, bulk upload candidate information and submit it in one workflow',
  'bcs.raise.customer':'de|Customer',
  'bcs.raise.customer.instruction': 'de|Please search the beneficiary customer details to get started.',
  'bcs.raise.customize':'de|Customize',
  'bcs.raise.customize.instruction': 'de|Please choose your package or select particular checks from the available below',
  'bcs.raise.candidates':'de|Candidates',
  'bcs.raise.candidates.instruction': 'de|Please add your candidates users or submit through the excel bulk upload below',
  'bcs.date': 'de|Date',
  'bcs.name': 'de|Name',
  'bcs.level': 'de|Level of Check',
  'bcs.levels': 'de|Levels of Check',
  'bcs.reference-number': 'de|Reference Number',
  'bcs.consent.via': 'de|Request Consent Via',
  'bcs.status': 'de|Status',
  'bcs.status.clear': 'de|Clear Results', 
  'bcs.status.with-alerts': 'de|Results With Alerts',
  'bcs.status.new': 'de|New',
  'bcs.status.awaiting-info': 'de|Awaiting Info', 
  'bcs.status.awaiting-info.candidate': 'de|Awaiting Info (From Candidate)', 
  'bcs.status.missing-info': 'de|Missing Info', 
  'bcs.status.processing': 'de|Results Processing',
  'bcs.status.unable-to-verify': 'de|Unable To Verify',
  'bcs.status.minified.clear': 'de|OK', 
  'bcs.status.minified.with-alerts': 'de|Alerts',
  'bcs.status.minified.awaiting-info': 'de|Awaiting Info', 
  'bcs.status.minified.awaiting-info.candidate': 'de|Awaiting Info', 
  'bcs.status.minified.missing-info': 'de|Missing Info', 
  'bcs.status.minified.processing': 'de|Processing',
  'bcs.status.minified.unable-to-verify': 'de|Unverifiable',
  'bcs.document.type': 'de|Document Type',
  'bcs.document.choose': 'de|Choose Document Type',
  'bcs.candidate.count': 'de|Candidate Count',
  'bcs.candidate.documents': 'de|Candidate Documents',
  'bcs.candidate.resume': 'de|Resume', 
  'bcs.candidate.good-conduct': 'de|Good Conduct',
  'bcs.candidate.national-id': 'de|National ID', 
  'bcs.candidate.birth-certificate': 'de|Birth Certificate', 
  'bcs.candidate.academic-certificate': 'de|Academic Certificate', 
  'bcs.candidate.others': 'de|Others',
  'bcs.candidate.consent': 'de|Candidate Consent',
  'bcs.candidate.add': 'de|Add Candidate',
  'bcs.candidate.edit': 'de|Edit Candidate',
  'bcs.load-resume': 'de|Load Resume',
  'bcs.print-pdf': 'de|View PDF',
  'bcs.reset-all': 'de|Reset ALL',
  'bcs.education.institution': 'de|Institution',
  'bcs.education.contacts': 'de|Contacts',
  'bcs.education.email': 'de|Email',
  'bcs.education.address': 'de|Address',
  'bcs.education.contact-person': 'de|Contact Person',
  'bcs.education.qualification': 'de|Area Of Study',
  'bcs.education.isgraduated': 'de|Graduated?',
  'bcs.education.period': 'de|Period',
  'bcs.work-history.period': 'de|Period',
  'bcs.work-history.job-position': 'de|Job Position',
  'bcs.work-history.employer': 'de|Employer',
  'bcs.checks.final-comments': 'de|Final Comments',
  'bcs.checks.comments': 'de|Previous Comments',
  'bcs.checks.comments.title': 'de|Comment Title',
  'bcs.checks.comments.add': 'de|Add Comments',
  'bcs.checks.documents': 'de|Previous Documents',
  'bcs.checks.emails': 'de|Previous Emails',
  'bcs.checks.emails.send': 'de|Send Email',

  'consents.sent-on': 'de|Sent On',
  'consents.subject': 'de|Subject',
  'consents.actioned-on': 'de|Actioned On',
  'consents.isconsented': 'de|Is Consented?',
  'consents.actions.approve': 'de|Approve',
  'consents.actions.reject': 'de|Reject',
  'consents.candidate.description': 'de|Verify candidate supplied information and candidate consent',
  'consents.candidate.intro': 'de|Candidate Intro',
  'consents.candidate.intro.instruction': 'de|Welcome {subject}. {customer} is using AI Vetting to run your background checks. Please fill in the details below.',
  'consents.candidate.work-history': 'de|Work History',
  'consents.candidate.work-history.instruction': 'de|Please fill in and correct missing details in the pre-filled form generated from from your resume',
  'consents.candidate.education': 'de|Education',
  'consents.candidate.education.instruction': 'de|Please fill in and correct missing details in the pre-filled form generated from from your resume',
  'consents.candidate.sign': 'de|Sign Consent',
  'consents.candidate.sign.instruction': 'de|Welcome {subject}. {customer} is using AI Vetting to run your background checks. Please read this agreement below',
  'consents.candidate.finish': 'de|Finish',
  'consents.candidate.agree': 'de|I hereby agree, to the best of my knowledge, to provide true and accurate information',
  'consents.candidate.completed': 'de|Your background check consent and info has been completed successfully.',
  
  'info-bot.request': 'de|Search people or organizations...',
  'info-bot.response.title': 'de|Search Results',
  'info-bot.exact-matches': 'de|Exact Matches',
  'info-bot.other-matches': 'de|Other Relevant Info',
  'info-bot.no-exact-matches': 'de|We couldn\'t find exact matches for based on your search criteria. Please view other relevant info below',

  'customers.date': 'de|Date Created',
  'customers.name': 'de|Customer Name',
  'customers.package': 'de|Active Package',
  'customers.status': 'de|Status',
  'customers.ispostpay': 'de|Is Postpay Account',
  'customers.status.pending': 'de|Pending',
  'customers.status.onboarding': 'de|Onboarding',
  'customers.status.suspended': 'de|Suspended',
  'customers.status.active': 'de|Active',
  'customers.status.dormant': 'de|Dormant',
  'customers.add': 'de|Add Customer',
  'customers.edit': 'de|Edit Customer',

  'orders.add': 'de|New Order',
  'orders.edit': 'de|Edit Order',
  'orders.date': 'de|Order Date',
  'orders.order-number': 'de|Order Number',
  'orders.status': 'de|Status',
  'orders.status.pending': 'de|Pending',
  'orders.status.submitted': 'de|Submitted',
  'orders.status.partial': 'de|Partial',
  'orders.status.paid': 'de|Paid',
  'orders.status.active': 'de|Active',
  'orders.status.past': 'de|Past',
};
export default enMessages;
