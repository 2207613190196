import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const propertyAiSlice = createSlice({
  name: 'propertyAi',
  initialState: initialState,
  reducers: {
    setProperty: (state,action)=>{ 
      state.profile.data = action?.payload || state.data;
    },
    setProperties: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {
    
  }
});

const propertyAiReducer = propertyAiSlice.reducer;
export const { setProperty, setProperties } = propertyAiSlice.actions;
export default propertyAiReducer;