import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const subscriptionAiSlice = createSlice({
  name: 'subscriptionAi',
  initialState: initialState,
  reducers: {
    setSubscription: (state,action)=>{ 
      state.profile.data = action?.payload || state.data;
    },
    setSubscriptions: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {
    
  }
});

const subscriptionAiReducer = subscriptionAiSlice.reducer;
export const { setSubscription, setSubscriptions } = subscriptionAiSlice.actions;
export default subscriptionAiReducer;