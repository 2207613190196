import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const securityLogAiSlice = createSlice({
  name: 'securityLogAi',
  initialState: initialState,
  reducers: {
    setSecurityLog: (state,action)=>{ 
      state.profile.data = action?.payload || state.data;
    },
    setSecurityLogs: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {

  }
});

const securityLogAiReducer = securityLogAiSlice.reducer;
export const { setSecurityLog, setSecurityLogs } = securityLogAiSlice.actions;
export default securityLogAiReducer;