import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const transactionAiSlice = createSlice({
  name: 'transactionAi',
  initialState: initialState,
  reducers: {
    setTransactions: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {

  }
});

const transactionAiReducer = transactionAiSlice.reducer;
export const { setTransaction, setTransactions } = transactionAiSlice.actions;
export default transactionAiReducer;