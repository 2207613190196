import { SERVICE_URL } from 'config/config';
import api from '../api';

const featuresList = [
    {
      "featureID": "61eb06426f96eb15e43e2cd0",
      "name": "AC",
      "color": "primary",
      "singular": "Academic Check",
      "plural": "Academic Checks (Native)",
      "subFeatures": [
        {
          name: "PRM",
          description: "Primary Education"
        },
        {
          name: "SED",
          description: "Secondary Education"
        },
        {
          name: "TED",
          description: "Tertiary i.e. College etc"
        },
        {
          name: "UED",
          description: "University Education"
        }
      ]
    },
    {
      "featureID": "61eb06426f96eb15e43e2cd1",
      "name": "PC",
      "color": "primary",
      "singular": "Professional Check",
      "plural": "Professional Checks",
      "subFeatures": [
        {
          name: "PLC",
          description: "Professional License Checks"
        },
        {
          name: "PMC",
          description: "Professional Body Membership Checks"
        }
      ]
    },
    {
      "featureID": "61eb06646f96eb15e43e2cd2",
      "name": "PE",
      "color": "secondary",
      "singular": "Previous Employment",
      "plural": "Previous Employment (Native)"
    },
    {
      "featureID": "61eb066e6f96eb15e43e2cd3",
      "name": "RC",
      "color": "warning",
      "singular": "Reference Check",
      "plural": "Reference Checks"
    },
    {
      "featureID": "61eb068c6f96eb15e43e2cd4",
      "name": "SM",
      "color": "info",
      "singular": "Social Media Check",
      "plural": "Social Media Checks"
    },
    {
      "featureID": "61eb06826f96eb15e43e2cd5",
      "name": "IC",
      "color": "warning",
      "singular": "Identity Check",
      "plural": "Identity Checks"
    },
    {
      "featureID": "61eb06826f96eb15e43e2cd6",
      "name": "CC",
      "color": "danger",
      "singular": "Criminal Check",
      "plural": "Criminal Checks"
    },
    {
      "featureID": "61eb06826f96eb15e43e2cd7",
      "name": "CF",
      "color": "violet",
      "singular": "Credit/Financial Check",
      "plural": "Credit/Financial Checks"
    },
    {
      "featureID": "61eb06826f96eb15e43e2cd8",
      "name": "RS",
      "color": "violet",
      "singular": "Residential Check",
      "plural": "Residential Checks"
    },
    {
      "featureID": "61eb06826f96eb15e43e2cd9",
      "name": "WG",
      "color": "violet",
      "singular": "World (Global) Sanction Check",
      "plural": "World (Global) Sanction Checks"
    },
    {
      "featureID": "61eb06826f96eb15e43e2ce1",
      "name": "LC",
      "color": "violet",
      "singular": "Litigation Check",
      "plural": "Litigation Checks"
    },
    {
      "featureID": "61eb06826f96eb15e43e2ce2",
      "name": "DS",
      "color": "violet",
      "singular": "Drug Screening Check",
      "plural": "Drug Screening Checks"
    },
    {
      "featureID": "61eb06826f96eb15e43e2ce3",
      "name": "DR",
      "color": "quarternary",
      "singular": "Driving Record Check",
      "plural": "Driving Record Checks",
      "subFeatures": [
        {
          name: "LCS",
          description: "License Status"
        },
        {
          name: "LCC",
          description: "License Class"
        },
        {
          name: "TVI",
          description: "Traffic Violations"
        },
        {
          name: "ARP",
          description: "Accident Reports"
        },
        {
          name: "OTR",
          description: "Other Offences"
        },
      ]
    },
    {
      "featureID": "61eb06826f96eb15e43e2ce4",
      "name": "IB",
      "color": "tertiary",
      "singular": "International Background Check",
      "plural": "International Background Check",
      "subFeatures": [
        {
          name: "ICR",
          description: "Criminal Records"
        },
        {
          name: "IEH",
          description: "Employment History"
        },
        {
          name: "IDC",
          description: "Education Credentials"
        }
      ]
    }
  ];

api.onGet(`${SERVICE_URL}/features`).reply(200, featuresList);