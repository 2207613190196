import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_USER, IS_DEMO } from 'config/config.js';

const initialState = {
  isLogin: IS_DEMO,
  identity: IS_DEMO ? DEFAULT_USER : {},
};

const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    setIdentity(state, action) {
      const {token,user, otpOn,otpValidated,completeSignup,passwordVerified} = action.payload;
      state.user = user;
      state.token = token;
      state.isLogin = true; 
      state.otpOn = otpOn;
      state.otpValidated = otpValidated;
      state.completeSignup = completeSignup;
      state.passwordVerified = passwordVerified;
    },
    setOTPOn(state, action) {
      const { payload: otpOn='N' } = action;
      state.otpOn = otpOn;
    },
    setOTPValid(state, action) {
      const { payload: otpValidated='N' } = action;
      state.otpValidated = otpValidated; 
    },
    setCompleteSignup(state, action){
      const { payload: completeSignup=false } = action;
      state.completeSignup = completeSignup;
    },
    setPasswordVerified(state, action){
      const { payload: passwordVerified='N' } = action;
      state.passwordVerified = passwordVerified;
    },
    unsetIdentity(state,action) { 
      state.user = action.payload?.identity || DEFAULT_USER;
      state.otpOn = 'N';
      state.otpValidated = 'N';
      state.completeSignup = false;
      state.passwordVerified = 'N';
      state.token = null;
      state.isLogin = false;
      window.localStorage.clear();
    }
  },
});

const identityReducer = identitySlice.reducer;
export const { setIdentity,unsetIdentity,setOTPOn,setOTPValid,setCompleteSignup,setPasswordVerified } = identitySlice.actions;
export default identityReducer;
