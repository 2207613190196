import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const collectionAiSlice = createSlice({
  name: 'collectionAi',
  initialState: initialState,
  reducers: {
    setCollections: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {

  }
});

const collectionAiReducer = collectionAiSlice.reducer;
export const { setCollection, setCollections } = collectionAiSlice.actions;
export default collectionAiReducer;