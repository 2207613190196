import React, { useState, createRef, useEffect } from 'react';
import { Wizard, Steps, Step, WithWizard } from 'react-albus';
import { Button, Card, CardBody, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setLoading } from 'reducers/dashboardAiSlice';
import { REACT_HELMET_PROPS } from 'config/config';
import ReactViewAdobe from 'react-adobe-embed';
import { AuthLeftSide } from 'views/default/Login';
import LayoutFullpage from 'layout/LayoutFullpage';
import { getCandidate, getCheckTree, saveCandidate, submitConsent, updateCandidate } from 'actions/candidates';
import { setCandidate } from 'reducers/candidatesAiSlice';
import httpService, { toastMessage } from "httpService";
import Select from 'react-select';
import { getNationalities,getCountries } from 'views/dashboard/widgets/DateSearchWidget';
import DatePicker from 'react-datepicker';
import { getCustomer } from 'actions/customers';
import { setCustomer } from 'reducers/customerAiSlice';
import { BccStatus, BinaryAnswer, PDF_KEY, PDF_LOCAL_KEY, ResumeKeys } from 'constants.js';
import Editable from 'react-bootstrap-editable';
import moment from 'moment';
import { parseResumeById } from 'actions/data-learning';
import { deleteConfirmDialog } from 'config/dialog';
import { checkTree } from '@mock-api/data/candidates';

const handleDeleteResumeItem = ({setFieldValue,resumeKey,period,values, f}) => {
  deleteConfirmDialog({f}).then((ok)=> {
    if(ok){
      const items = values[resumeKey]?.filter(a=>!(a.period===period));
      setFieldValue(resumeKey,items);
      toastMessage({title: 'title.operation', message: 'response.deleted'}).success();
    }
  });
}


export const CandidateConsentStep = ({innerRef,isSubmitted=false,fields: initialValues={}}) => {
  const { formatMessage: f } = useIntl();
  const {data:customer={}} = useSelector(state=>state.customerAi?.profile);
  const [file,setFile] = useState({});

  const validationSchema = Yup.object().shape({
    isConsented: Yup.string().required('Please accept that you have read above candidate consent!')
    .min(1,'Please accept that you have read above candidate consent!')
  });

  useEffect(()=>{
    if(initialValues?.candidateID){
      setFile({
        name: initialValues?.subject,
        id: `${initialValues?.subject}-consent-${initialValues?.candidateID}?`,
        URL: `${httpService.baseURL}/candidates/consent/${initialValues?.candidateID}?`
      });
    }
  },[initialValues?.candidateID])

  return (
    <Formik
      innerRef = {innerRef}
      initialValues = {initialValues}
      validateOnMount = {false}
      enableReinitialize = {true}
      validateOnBlur = {false}
      validationSchema = {validationSchema}
      onSubmit={()=>{}}>

      {({ values, touched, errors, setFieldValue }) => (
        <div>
          <h5 className="card-title fw-bolder">{f({id: 'consents.candidate.sign'})}</h5>
          <p className="card-text text-alternate mb-4 text-wrap">
            { f({ id: 'consents.candidate.sign.instruction'},{customer: customer?.customerName, subject: values?.subject}) }
          </p>

          <Row>
            <Col xs="12">
              <div>
                {
                  file?.name &&
                  <ReactViewAdobe
                    clientId = {process.env.REACT_APP_ADOBE_EMBED_KEY}
                    title = {`${file.name}.pdf`}
                    url = {file.URL}
                    id = {`${file.id}`} 
                    fileMeta={{
                      fileName: `${file.name}.pdf`
                    }}
                    previewConfig={{
                      defaultViewMode: "FULL_WIDTH",
                      showAnnotationTools: false,
                      showPageControls: false,
                      showDownloadPDF: false
                    }}
                    style={{
                      height: "50vh"
                    }}
                  />

                }
              </div>
              <div className="position-relative mb-3 mt-3">
                <div className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    label={ f({ id: 'consents.candidate.agree' }) } 
                    id="isConsented" 
                    name="isConsented"
                    onClick={(e)=>{ setFieldValue('isConsented',(e.target.checked? 'Y' : 'N')) }}
                    defaultChecked={values?.isConsented==='Y'}/>
                </div>
                {errors.isConsented && (touched.isConsented || isSubmitted) ? (
                  <Form.Control.Feedback type="invalid" tooltip className="d-block">
                    {errors.isConsented}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            </Col>
          </Row>

        </div>
      )}
    </Formik>
  );
}

export const CandidateIntroStep = ({innerRef,isSubmitted=false,fields: initialValues={}}) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch(); 
  const [nationalities,setNationalities] = useState([]);
  const [countries,setCountries] = useState({});
  const {data:customer={}} = useSelector(state=>state.customerAi?.profile);

  const validationSchema = Yup.object().shape({
    phoneNo: Yup.string(),
    country: Yup.string().required('Please select nationality!'),
    email: Yup.string().required('Email address missing!').email('Invalid email format!'),
    dateOfBirth: Yup.string().required('Date of birth required!'),
    residentialAddress: Yup.string().required('Residential address required!'),
    postalAddress: Yup.string().required('Postal address is required!'),
  });

  useEffect(() => {
    const fetch = async() => {
      getNationalities().then(nationalities=>setNationalities(nationalities));
      getCountries().then(countries=>setCountries(countries));
    }
    fetch();
  },[dispatch]);

  return (
    <Formik
      innerRef = {innerRef}
      initialValues = {initialValues}
      validateOnMount = {true}
      enableReinitialize = {true}
      validateOnBlur = {true}
      validationSchema = {validationSchema}
      onSubmit={()=>{}}>

      {({ handleChange, values, touched, errors, setFieldValue }) => (
        <div>
          <h5 className="card-title fw-bolder">{f({id: 'consents.candidate.intro'})}</h5>
          <p className="card-text text-alternate mb-4">
            { f({ id: 'consents.candidate.intro.instruction'},{customer: customer?.customerName, subject: values?.subject?.split(' ')[0]}) }
          </p>
          <div className="mb-3 top-label tooltip-end-top">
            <Form.Label>{ f({id: 'nationality'}) }</Form.Label>
            <Select 
              classNamePrefix="react-select" 
              options={ nationalities?.map(({alpha_2_code: value,nationality: label})=>({ value, label})) } 
              value={ 
                values?.country
                ?{ 
                  value: values?.country, 
                  label: nationalities?.find(a=>a.alpha_2_code===values?.country)?.nationality 
                }: null 
              } 
              onChange = {(v)=> {
                setFieldValue('country', v.value);
                setFieldValue('phoneCode',countries[v?.value]?.phone);
                setFieldValue('currency',countries[v?.value]?.currency);
              }}
              placeholder={f({id: 'country'})} />
            {errors.country && (touched.country || isSubmitted) ? (
              <Form.Control.Feedback type="invalid" tooltip className="d-block">
                {errors.country}
              </Form.Control.Feedback>
            ) : null}
          </div>
          <div className="mb-3 top-label tooltip-end-top">
            <Form.Label>{ f({id: 'profile.email'}) }</Form.Label>
            <Form.Control 
              type="text" 
              name = "email" 
              defaultValue = {values?.email}
              onChange = {(e) => { handleChange(e) }} />
            {errors.email && (touched.email || isSubmitted) ? (
              <Form.Control.Feedback type="invalid" tooltip className="d-block">
                {errors.email}
              </Form.Control.Feedback>
            ) : null}
          </div>
          <div className="mb-3 top-label tooltip-end-top">
            <Form.Label>{ f({id: 'dob'}) }</Form.Label>
            <DatePicker
              className="form-control"
              placeholderText = {f({id: 'dob'})}
              dateFormat={REACT_HELMET_PROPS.dateFormat}
              selected = { values?.dateOfBirth? new Date(values?.dateOfBirth) : null }
              onChange= {(date)=> {
                setFieldValue('dateOfBirth', moment(date).format(REACT_HELMET_PROPS.dateMomentFormat));
              }}
            />
            {errors.dateOfBirth && (touched.dateOfBirth || isSubmitted) ? (
              <Form.Control.Feedback type="invalid" tooltip className="d-block">
                {errors.dateOfBirth}
              </Form.Control.Feedback>
            ) : null}
          </div>
          <div className="mb-3 top-label tooltip-end-top">
            <Form.Label>{ f({id: 'residential-address'}) }</Form.Label>
            <Form.Control 
              type="text" 
              name = "residentialAddress" 
              defaultValue = {values?.residentialAddress}
              onChange = {(e) => { handleChange(e) }} />
            {errors.residentialAddress && (touched.residentialAddress || isSubmitted) ? (
              <Form.Control.Feedback type="invalid" tooltip className="d-block">
                {errors.residentialAddress}
              </Form.Control.Feedback>
            ) : null}
          </div>
          <div className="mb-3 top-label tooltip-end-top">
            <Form.Label>{ f({id: 'postal-address'}) }</Form.Label>
            <Form.Control 
              type="text" 
              name = "postalAddress" 
              defaultValue = {values?.postalAddress}
              onChange = {(e) => { handleChange(e) }} />
            {errors.postalAddress && (touched.postalAddress || isSubmitted) ? (
              <Form.Control.Feedback type="invalid" tooltip className="d-block">
                {errors.postalAddress}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
      )}
    </Formik>
  )
}

export const EducationStep = ({innerRef,isSubmitted=false,fields: initialValues={}, setFields }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch(); 

  const validationSchema = Yup.object().shape({
    [ResumeKeys.Education]: Yup.array().of(Yup.object().shape({
      [ResumeKeys.Institution]: Yup.string().required('Education insitution required!'),
      contacts: Yup.string(),
      email: Yup.string(),
      address: Yup.string(),
      contactPerson: Yup.string(),
      [ResumeKeys.Qualification]: Yup.string().required('Area of study/Degree required!'),
      [ResumeKeys.Period]: Yup.string().required('Specify joining date!')
    }))
    .unique("Duplicate institution!", (a) => a?.[ResumeKeys.Period])
    .test({
      message: 'You have not added education verification',
      test: arr => arr?.length>0,
    })
  });

  const fields = [
    { name: ResumeKeys.Period, translationKey: 'period', md: '2', inputType: 'textarea'},
    { name: ResumeKeys.Institution, translationKey: 'institution', md: '4', inputType: 'select'},
    { name: ResumeKeys.Qualification, translationKey: 'qualification', md: '4', inputType: 'select'}
  ]

  useEffect(()=>{
    if((!initialValues?.[ResumeKeys.Education] || initialValues?.[ResumeKeys.Education]?.length===0) && initialValues?.candidateID){
      dispatch(parseResumeById(initialValues?.candidateID)).then(({payload})=>{
        setFields(payload?.[ResumeKeys.Education] || []);
      })
    }
  },[initialValues?.[ResumeKeys.Education],initialValues?.candidateID])

  return (
    <Formik
      innerRef = {innerRef}
      initialValues = {initialValues}
      validateOnMount = {true}
      enableReinitialize = {true}
      validateOnBlur = {true}
      validationSchema = {validationSchema}
      onSubmit={()=>{}}>

      {({ values, touched, errors, setFieldValue }) => (
        <div>
          <h5 className="card-title fw-bolder">{f({id: 'consents.candidate.education'})}</h5>
          <p className="card-text text-alternate mb-4">
            { f({ id: 'consents.candidate.education.instruction'}) }
          </p>
          {/* List Header Start */}
          <Row className="g-0 h-100 align-content-center d-none d-md-flex mb-4 custom-sort">
            {
                fields.map(({translationKey, md}) =>
                  <Col xs="11" md={md} className="d-flex flex-column pe-0 justify-content-start align-items-start">
                    <div className="text-muted text-small cursor-pointer">{f({ id : `bcs.education.${translationKey}`}).toUpperCase()}</div>
                  </Col>
                )
            }
            <Col xs={1} md={1} className="d-flex flex-column justify-content-end align-items-end">
              <OverlayTrigger delay={{ show: 100, hide: 0 }} placement="top" 
                overlay={<Tooltip id="tooltip-top">{ f({id: 'quick-add'}) }</Tooltip>}>
                <Button variant="link" className="p-0" 
                  onClick={(e)=>{setFieldValue(ResumeKeys.Education,[...values?.[ResumeKeys.Education],[]])}}>
                  <CsLineIcons icon="plus" className="cs-icon icon" />
                </Button> 
              </OverlayTrigger>
            </Col>
          </Row>
          {/* List Header End */}
          {
            values?.[ResumeKeys.Education]?.map((item,i) =>
              <Row className="g-0 h-100 align-items-center cursor-default" key={i}>
                {
                  fields.map(({name, translationKey, md, inputType,options}) =>
                  <Col xs="11" md={md} key={name} className="d-flex align-items-baseline mb-2 mb-md-0 order-1 order-md-1 tooltip-end-top">
                    <div className="text-wrap text-alternate mb-3 d-flex gap-1">
                        <Editable 
                          editText={ item[name] || f({ id : `bcs.education.${translationKey}`}) }
                          mode="inline"
                          onSubmit={(value)=> {
                              const text =  inputType==='date'? moment(value).format(REACT_HELMET_PROPS.yearFormat) : value;
                              const items = [...values?.education]?.map((a,j)=> (i!==j)? a : ({...a, [name]: text }));
                              setFieldValue(ResumeKeys.Education,items) 
                            } 
                          }
                          showText={false}
                          type='textarea'
                          options={ options || item[ResumeKeys.ValueOptions]}
                          initialValue={item[name]}
                        />
                        { 
                            touched?.[ResumeKeys.Education] && errors?.[ResumeKeys.Education] && (touched?.[ResumeKeys.Education] || isSubmitted) &&
                            errors?.[ResumeKeys.Education]?.[i]?.[name] &&
                            <OverlayTrigger delay={{ show: 100, hide: 0 }} placement="top" 
                              overlay={<Tooltip id="tooltip-top">{ errors?.[ResumeKeys.Education]?.[i]?.[name] }</Tooltip>}>
                                <i className="d-inline-block text-danger icon-14 bi-exclamation-circle-fill" />
                            </OverlayTrigger>
                        }
                    </div>
                  </Col>
                )}
                <Col xs={1} md={1} className="d-flex justify-content-end align-items-baseline mb-2 mb-md-0 order-last order-md-last">
                  <OverlayTrigger delay={{ show: 100, hide: 0 }} placement="top" 
                    overlay={<Tooltip id="tooltip-top">{ f({id: 'delete'}) }</Tooltip>}>
                    <Button className="ps-2 pe-2" variant="link" 
                      onClick={() => { handleDeleteResumeItem({f, setFieldValue, values, resumeKey: ResumeKeys.Education, period: item?.[ResumeKeys.Period] }) }}>
                      <CsLineIcons icon="close" className="cs-icon icon text-danger" />
                    </Button>     
                  </OverlayTrigger>
                </Col>
              </Row>
            )
          }
          <Row>
            <Col xs="12" className="position-relative mb-3">
              {touched?.[ResumeKeys.Education] && errors?.[ResumeKeys.Education] && (touched?.[ResumeKeys.Education] || isSubmitted) ? (
                <Form.Control.Feedback type="invalid" tooltip className="d-block">
                  {f({ id: 'errors.form.controls'})}
                </Form.Control.Feedback>
                ) : null}
              {errors?.[ResumeKeys.Education] && isSubmitted && !Array.isArray(errors?.[ResumeKeys.Education]) ? (
                <Form.Control.Feedback type="invalid" tooltip className="d-block">
                  { errors?.[ResumeKeys.Education] }
                </Form.Control.Feedback>
                ) : null}
            </Col>
          </Row>
        </div>
      )}
    </Formik>
  )
}

export const WorkHistoryStep = ({innerRef,isSubmitted=false,fields: initialValues={}, setFields}) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch(); 

  const validationSchema = Yup.object().shape({
    [ResumeKeys.WorkHistory]: Yup.array().of(Yup.object().shape({
      [ResumeKeys.Employer]: Yup.string().required('Employer is required!'),
      contacts: Yup.string(),
      email: Yup.string(),
      address: Yup.string(),
      contactPerson: Yup.string(),
      [ResumeKeys.JobPosition]: Yup.string().required('Job position required!'),
      [ResumeKeys.Period]: Yup.string().required('Specify employment period!'),
    }))
    .unique("Duplicate employment/position!", (a) => a?.[ResumeKeys.Period])
    .test({
      message: 'You have not added any work history',
      test: arr => arr?.length>0,
    })
  });

  const fields = [
    { name: ResumeKeys.Period, translationKey: 'period', md: '2', inputType: 'textarea'},
    { name: ResumeKeys.Employer, translationKey: 'employer', md: '4', inputType: 'select'},
    { name: ResumeKeys.JobPosition, translationKey: 'job-position', md: '5', inputType: 'select'}
  ]

  useEffect(()=>{
    if((!initialValues?.[ResumeKeys.WorkHistory] || initialValues?.[ResumeKeys.WorkHistory]?.length===0) && initialValues?.candidateID){
      dispatch(parseResumeById(initialValues?.candidateID)).then(({payload})=>{
        setFields(payload?.[ResumeKeys.WorkHistory] || []);
      })
    }
  },[initialValues?.[ResumeKeys.WorkHistory],initialValues?.candidateID]);
  
  return (
    <Formik
      innerRef = {innerRef}
      initialValues = {initialValues}
      validateOnMount = {true}
      enableReinitialize = {true}
      validateOnBlur = {true}
      validationSchema = {validationSchema}
      onSubmit={()=>{}}>

      {({ values, touched, errors, setFieldValue }) => (
        <div>
          <h5 className="card-title fw-bolder">{f({id: 'consents.candidate.work-history'})}</h5>
          <p className="card-text text-alternate mb-4">
            { f({ id: 'consents.candidate.work-history.instruction'}) }
          </p>
          {/* List Header Start */}
          <Row className="g-0 h-100 align-content-center d-none d-md-flex mb-4 custom-sort">
            {
                fields.map(({ name, translationKey, md}) =>
                  <Col key={name} xs="11" md={md} className="d-flex flex-column pe-0 justify-content-start align-items-start">
                    <div className="text-muted text-small cursor-pointer sort">{f({ id : `bcs.work-history.${translationKey}`}).toUpperCase()}</div>
                  </Col>
                )
            }
            <Col xs={1} md={1} className="d-flex flex-column justify-content-end align-items-end">
              <OverlayTrigger delay={{ show: 100, hide: 0 }} placement="top" 
                overlay={<Tooltip id="tooltip-top">{ f({id: 'quick-add'}) }</Tooltip>}>
                <Button variant="link" className="p-0" 
                  onClick={(e)=>{setFieldValue(ResumeKeys.WorkHistory,[...values?.[ResumeKeys.WorkHistory],[]])}}>
                  <CsLineIcons icon="plus" className="cs-icon icon" />
                </Button> 
              </OverlayTrigger>
            </Col>
          </Row>
          {/* List Header End */}
          {
            values?.[ResumeKeys.WorkHistory]?.map((item,i) =>
              <Row className="g-0 h-100 align-items-center cursor-default" key={i}>
                {
                  fields.map(({name, translationKey, md, inputType,options}) =>
                  <Col xs="11" md={md} key={name} className="d-flex align-items-baseline mb-2 mb-md-0 order-1 order-md-1 tooltip-end-top">
                    <div className="text-wrap text-alternate mb-3 d-flex gap-1">
                        <Editable 
                          editText={ item[name] || f({ id : `bcs.work-history.${translationKey}`}) }
                          mode="inline"
                          onSubmit={(value)=> {
                              const text =  inputType==='date'? moment(value).format(REACT_HELMET_PROPS.yearFormat) : value;
                              const items = [...values?.[ResumeKeys.WorkHistory]]?.map((a,j)=> (i!==j)? a : ({...a, [name]: text }));
                              setFieldValue(ResumeKeys.WorkHistory,items); 
                            } 
                          }
                          showText={false}
                          type='textarea'
                          options={ options || item[ResumeKeys.ValueOptions]}
                          initialValue={item[name]}
                        />
                        { 
                            touched?.[ResumeKeys.WorkHistory] && errors?.[ResumeKeys.WorkHistory] && (touched?.[ResumeKeys.WorkHistory] || isSubmitted) &&
                            errors?.[ResumeKeys.WorkHistory]?.[i]?.[name] &&
                            <OverlayTrigger delay={{ show: 100, hide: 0 }} placement="top" 
                              overlay={<Tooltip id="tooltip-top">{ errors?.[ResumeKeys.WorkHistory]?.[i]?.[name] }</Tooltip>}>
                                <i className="d-inline-block text-danger icon-14 bi-exclamation-circle-fill" />
                            </OverlayTrigger>
                        }
                    </div>
                  </Col>
                )}
                <Col xs={1} md={1} className="d-flex justify-content-end align-items-baseline mb-2 mb-md-0 order-last order-md-last">
                  <OverlayTrigger delay={{ show: 100, hide: 0 }} placement="top" 
                    overlay={<Tooltip id="tooltip-top">{ f({id: 'delete'}) }</Tooltip>}>
                    <Button className="ps-2 pe-2" variant="link" 
                      onClick={() => { handleDeleteResumeItem({f, setFieldValue, values, resumeKey: ResumeKeys.WorkHistory, period: item?.[ResumeKeys.Period] }) }}>
                      <CsLineIcons icon="close" className="cs-icon icon text-danger" />
                    </Button>     
                  </OverlayTrigger>
                </Col>
              </Row>
            )
          }
          <Row>
            <Col xs="12" className="position-relative mb-3">
              {touched?.[ResumeKeys.WorkHistory] && errors?.[ResumeKeys.WorkHistory] && (touched?.[ResumeKeys.WorkHistory] || isSubmitted) ? (
                <Form.Control.Feedback type="invalid" tooltip className="d-block">
                  {f({ id: 'errors.form.controls'})}
                </Form.Control.Feedback>
                ) : null}
              {errors?.[ResumeKeys.WorkHistory] && isSubmitted && !Array.isArray(errors?.[ResumeKeys.WorkHistory]) ? (
                <Form.Control.Feedback type="invalid" tooltip className="d-block">
                  { errors?.[ResumeKeys.WorkHistory] }
                </Form.Control.Feedback>
                ) : null}
            </Col>
          </Row>
        </div>
      )}
    </Formik>
  );
}

export const FinishStep = ({ handleCancel }) => {
  const { formatMessage: f } = useIntl();
  const loading = useSelector((state) => state.dashboardAi.loading);
  const {isLogin} = useSelector((state) => state.identity);

  return (
    <div className="p-5">
      <Card>
        <CardBody>
        {
          loading 
          ?(
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <p>{ f({ id: 'request.processing'}) }</p>
            </div>
          ) 
          :(
            <div className="d-flex flex-column align-items-center">
              <h3 className="mb-2">{ f({ id: 'register.thank-you'}) }</h3>
              <p>{ f({ id: 'consents.candidate.completed'}) }</p>
              <Button variant="primary" type="button" className="mt-3 btn-icon btn-icon-end" onClick={()=>handleCancel()}>
                {f({id: (isLogin? 'login.go-dashboard' : 'menu.close')})}
                <span className="arrow-right"></span>
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export const ServicesSignConsent = () => {

  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const {isLogin} = useSelector((state) => state.identity);
  const {candidateID} = useParams();

  const {data:fields={}} = useSelector(state=>state.candidateAi?.profile);
  const loading = useSelector((state) => state.dashboardAi.loading);
  
  const [bottomNavHidden, setBottomNavHidden] = useState(false);
  const forms = [createRef(null), createRef(null), createRef(null), createRef(null) ];
  const [isSubmitted, setSubmitted] = useState(false);
  

  const onClickNext = (goToNext, steps, step) => {
    setSubmitted(true);
    if (steps.length - 1 <= steps.indexOf(step)) {
      return;
    }
    const formIndex = steps.indexOf(step);
    const form = forms[formIndex].current;

    form?.submitForm().then(()=> {
      if (!form.isDirty && form.isValid) {
        dispatch(setLoading(true));
        let data = { ...fields, ...form.values };
        let isConsented = (steps?.length - 2 === steps?.indexOf(step))? 'Y' : 'N';

        if(data.dateOfBirth){
          data.dateOfBirth = new Date(moment(new Date()).format(REACT_HELMET_PROPS.dateMomentFormat)); 
        }

        if(isConsented==='Y'){
          data.checks = [...checkTree];

          /* add academic checks */
          data?.checks?.map(a=>{
            if(a.code==='AC'){
              let i = 0;
              let b = {...a};
              data?.education?.map(({institution, qualification, period})=>{
                b.items[i]['remarks'] = qualification;
                b.items[i]['children']?.map(c=>{
                  if(c?.text?.toLowerCase() === "Institution"?.toLowerCase()){
                    return {...c, remarks: institution}
                  }
                  if(c?.text?.toLowerCase() === "Details"?.toLowerCase()){
                    c?.children?.map(d=>{
                      if(d?.text?.toLowerCase()==="Applicant Supplied Information"?.toLowerCase()){
                        d.children?.map(e=>{
                          if(e?.text?.toLowerCase() === "Dates Attended"?.toLowerCase()){
                            return {...e, remarks: period};
                          }
                          if(e?.text?.toLowerCase() === "Certificate"?.toLowerCase()){
                            return {...e, remarks: qualification};
                          }
                          return e;
                        })
                      }else{
                        d.children?.map(e=>({...e, remarks: ''}));
                      }
                    });
                  }
                  return {...c, remarks: ''}
                });
                i++;
              });
              a = b;
            }

            /* add previous employment */
            if(a.code==='PE'){
              let i = 0;
              let b = {...a};
              data?.workHistory?.map(({employer, position, period})=>{
                b.items[i]['remarks'] = employer;
                b.items[i]['children']?.map(c=>{
                  if(c?.text?.toLowerCase() === "Details"?.toLowerCase()){
                    c?.children?.map(d=>{
                      if(d?.text?.toLowerCase()==="Applicant Supplied Information"?.toLowerCase()){
                        d.children?.map(e=>{
                          if(e?.text?.toLowerCase() === "Dates of Work"?.toLowerCase()){
                            return {...e, remarks: period};
                          }
                          if(e?.text?.toLowerCase() === "Position"?.toLowerCase()){
                            return {...e, remarks: position};
                          }
                          return e;
                        })
                      }else{
                        d.children?.map(e=>({...e, remarks: ''}));
                      }
                    });
                  }
                  return {...c, remarks: ''}
                });
                i++;
              });
              a = b;
            }
            return a;
          });
        }

        dispatch(data?.candidateID
            ? (isConsented==='Y'
              ? submitConsent({data, candidateID: data?.candidateID})
              : updateCandidate({data, candidateID: data?.candidateID}))
            : saveCandidate(data)).then(({payload}) => {

            const values = data?.candidateID? data : payload;
            dispatch(setCandidate(values));

            goToNext();
            step.isDone = true;
            dispatch(setLoading(false));
            
        }).finally(()=>{
          setSubmitted(false);
          if (steps?.length - 1 <= steps?.indexOf(step)) {
            setBottomNavHidden(true);
          }
        });
      }

    });
  };

  const onClickPrev = (goToPrev, steps, step) => {
    setSubmitted(false);
    if (steps.indexOf(step) <= 0) {
      return;
    }
    goToPrev();
  };

  const getClassName = (steps, step, index, stepItem) => {
    if (steps.indexOf(step) === index) {
      return 'step-doing';
    }
    if (steps.indexOf(step) > index || stepItem.isDone) {
      stepItem.isDone = true;
      return 'step-done';
    }
    return 'step';
  };

  const handleCancel = async() => {
    if(isLogin){
      dispatch(setCandidate({}));
      dispatch(setLoading(false));
      history.push('/services/vetting');
    }else{
      window.opener = null;
      window.open("", "_self");
      window.close();
    }
  }


  /* fetch candidate info based on candidate id */
  useEffect(() => {
    const fetch = async() => { 
      if(candidateID){
        dispatch(getCandidate(candidateID)).then(({payload})=>{
          dispatch(setCandidate(payload));
        });
      }else{
        dispatch(setCandidate({}));
      }
    }
    fetch();
  },[dispatch,candidateID]);

  /* fetch customer info based selected candidate info */
  useEffect(() => {
    const fetch = async() => { 
      if(fields?.customerID){
        dispatch(getCustomer(fields?.customerID)).then(({payload})=>{
          dispatch(setCustomer(payload));
        });
      }else{
        dispatch(setCustomer({}));
      }
    }
    fetch();
  },[dispatch,fields?.customerID]);

  const title = f({ id: 'bcs.candidate.consent'});
  const description = f({ id: 'consents.candidate.description'});

  const rightSide = (
    <div className="min-h-100 d-flex align-items-center bg-white p-5">
      <div className="w-100">
        <div className="mb-0">
          <div className="pb-6 mt-3 text-center"><h2 className="mb-6">{ f({ id: 'bcs.candidate.consent'}) }</h2></div>
          <div className="wizard wizard-default">
            <Wizard>
              <WithWizard
                render={({ step, steps }) => (
                  <ul className="nav nav-tabs justify-content-center mb-5">
                    {steps.map((stepItem, index) => {
                      if (!stepItem.hideTopNav) {
                        return (
                          <li key={`topNavStep_${index}`} className={`nav-item ${getClassName(steps, step, index, stepItem)} p-0 p-md-3`}>
                            <Button variant="link" className="nav-link pe-none">
                              <span>{stepItem.name}</span>
                              <small>{stepItem.desc}</small>
                            </Button>
                          </li>
                        );
                      }
                      return <span key={`topNavStep_${index}`} />;
                    })}
                  </ul>
                )}
              />
              <Steps>
                <Step id="step1" name={f({id: 'consents.candidate.sign'})} desc="">
                  <CandidateConsentStep 
                      innerRef = {forms[0]} 
                      fields={fields} 
                      isSubmitted={isSubmitted} />
                </Step>
                <Step id="step2" name={f({id: 'consents.candidate.intro'})} desc="">
                  <CandidateIntroStep 
                    innerRef = {forms[1]} 
                    fields={fields} 
                    isSubmitted={isSubmitted} />
                </Step>
                <Step id="step3" name={f({id: 'consents.candidate.education'})} desc="">
                  <EducationStep 
                    innerRef = {forms[2]}
                    fields={fields} 
                    isSubmitted={isSubmitted}
                    setFields = {(education)=>{
                      dispatch(setCandidate({ ...fields, education }));
                    }} />
                </Step>
                <Step id="step4" name={f({id: 'consents.candidate.work-history'})} desc="">
                  <WorkHistoryStep  
                    innerRef = {forms[3]}
                    fields={fields} 
                    isSubmitted={isSubmitted}
                    setFields = {(workHistory)=>{
                      dispatch(setCandidate({ ...fields, workHistory }));
                    }} />
                </Step>
                <Step id="step5" name={f({id: 'consents.candidate.finish'})} desc="">
                  <FinishStep  
                    innerRef = {forms[4]}
                    fields={fields} 
                    isSubmitted={isSubmitted} 
                    handleCancel={handleCancel} />
                </Step>
              </Steps>
              <WithWizard
                render={({ next, previous, step, steps }) => (
                  (steps.indexOf(step) < steps.length -1) && 
                  <div className={`wizard-buttons mt-4 d-flex justify-content-center ${bottomNavHidden && 'invisible'}`}>
                    <Button 
                      disabled = {loading}
                      onClick = {()=>{handleCancel()}}
                      as={Link} to={`/services/vetting`} 
                      className="btn-icon btn-icon-start me-5 d-flex align-items-center" 
                      variant="outline-danger">
                      <CsLineIcons icon="close" /> <span>{ f({ id: 'cancel'}) }</span>
                    </Button>
                    <Button
                      disabled = {loading}
                      variant="outline-primary"
                      className={`btn-icon btn-icon-start me-1 ${steps.indexOf(step) <= 0 ? 'disabled' : ''} d-flex`}
                      onClick={() => {
                        onClickPrev(previous, steps, step);
                      }}>
                      <CsLineIcons icon="chevron-left" /> <span>{ f({ id: 'back'}) }</span>
                    </Button>
                    <Button
                      disabled = {loading}
                      variant="outline-primary"
                      className={`btn-icon btn-icon-end ${steps.indexOf(step) >= steps.length - 1 ? 'disabled' : ''} d-flex`}
                      onClick={() => {
                        onClickNext(next, steps, step);
                      }}
                    >
                      <span>{ f({ id: 'next'}) }</span> <CsLineIcons icon="chevron-right" />
                    </Button>
                  </div>
                )}
              />
            </Wizard>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HtmlHead title={title} description={description} />
      <LayoutFullpage
            left = {<AuthLeftSide />}
            right = {rightSide}
        />
    </>
  );
}

export default ServicesSignConsent;
