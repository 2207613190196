import { SERVICE_URL } from 'config/config';
import api from '../api';

const modulesList = [
    {
      "moduleID": "61eb072c6f96eb15e43e2cd7",
      "name": "prp",
      "color": "yellow",
      "singular": "PROPERTY MANAGER",
      "plural": "PROPERTY MANAGER"
    },
    {
      "moduleID": "61eb07426f96eb15e43e2cd8",
      "name": "tnt",
      "color": "orange",
      "singular": "TENANT ONBOARDING",
      "plural": "TENANT ONBOARDING"
    },
    {
      "moduleID": "61eb074f6f96eb15e43e2cd9",
      "name": "lmg",
      "color": "slateblue",
      "singular": "LEASE MANAGEMENT",
      "plural": "LEASE MANAGEMENT"
    },
    {
      "moduleID": "61eb07876f96eb15e43e2cde",
      "name": "arr",
      "color": "gray",
      "singular": "ARREARS DASHBOARD",
      "plural": "ARREARS DASHBOARD"
    },
    {
      "moduleID": "61eb07946f96eb15e43e2cdf",
      "name": "col",
      "color": "gray",
      "singular": "COLLECTIONS DASHBOARD",
      "plural": "COLLECTIONS DASHBOARD"
    },
    {
      "moduleID": "61eb07596f96eb15e43e2cda",
      "name": "wmr",
      "color": "dodgerblue",
      "singular": "WORK ORDER & INVOICING",
      "plural": "WORK ORDERS & INVOICING"
    },
    {
      "moduleID": "61eb07646f96eb15e43e2cdb",
      "name": "mtr",
      "color": "mtn",
      "singular": "MAINTENANCE REQUEST",
      "plural": "MAINTENANCE REQUESTS"
    },
];

api.onGet(`${SERVICE_URL}/modules`).reply(200, modulesList);