import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'httpService';

export const BasicCustomerFields = [
  "customerID",
  "customerName",
  "activePackageID",
  "billingCycle",
  "currency",
  "paymentMethod",
  "creditBalance",
  "isPostPay",
  "country",
  "phoneCode"
];

export const countCustomers = createAsyncThunk('customers/count', async (advanced) => {
  const where = JSON.stringify({...(advanced || {})});
  const response = await httpService.get(`customers/count?where=${where}`);
  return response?.count;
});

export const getCustomers = createAsyncThunk('customers/get-list', async (advanced) => {
  const filter = JSON.stringify({...(advanced || {}),limit: 10 });
  const response = await httpService.get(`customers?filter=${filter}`);
  return response;
});

export const getCustomer = createAsyncThunk('customers/get-one', async (id) => {
  const response = await httpService.get(`customers/${id}`);
  return response;
});

export const saveCustomer = createAsyncThunk('customers/save', async (data) => {
  const response = await httpService.post(`customers`,data);
  return response;
});

export const saveCustomers = createAsyncThunk('customers/save-many', async (data) => {
  const response = await httpService.post(`customers/save-many`,data);
  return response;
});


export const signupCustomer = createAsyncThunk('customers/signup', async (data) => {
  const response = await httpService.post(`customers/signup`,data);
  return response;
});

export const updateCustomer = createAsyncThunk('customers/update', async ({customerID,data}) => { 
  const response = await httpService.patch(`customers/${customerID}`,data);
  return response;
});

export const deleteCustomer = createAsyncThunk('customers/delete', async (id) => { 
  const response = await httpService.delete(`customers/${id}`);
  return response;
});

export const autocompleteCustomers = createAsyncThunk('customers/autocomplete', async ({term}) => {
    const filter = JSON.stringify({fields: BasicCustomerFields, where: { customerName: {like: `${term}`, options:"i"} } });
    const response = await httpService.get(`customers?filter=${filter}`);
    return response;
});