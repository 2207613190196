
import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list?.length === new Set(list?.map(mapper))?.size;
  });
});

export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
};

export const NAV_COLOR = {
  Default: 'default',
  Light: 'light',
  Dark: 'dark',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const USER_ROLE = {
  System: 'admin',
  Customer: 'customer',
};

export const UserTypes = {
  Admin: 'admin',
  DataAdmin: 'data-admin',
  Investigator: 'investigator',
  Accountant: 'accountant',
  CustomerAdmin: 'customer-admin',
  CustomerUser: 'customer-user',
};

export const TenantStatus = {
  Active: 'active',
  InActive: 'inactive'
};

export const TenantStatusColors = new Map([
  [TenantStatus.Active, { bgColor: 'outline-success', btnBg: 'success' } ],
  [TenantStatus.InActive, { bgColor: 'outline-danger', btnBg: 'danger' } ]
]);

export const SocialLoginTypes = {
  Facebook: 'facebook',
  Google: 'google',
  LinkedIn: 'linkedin'
};

export const RelativeTimes = {
  CustomDate: 'timespan.custom',
  Today: 'timespan.today',
  Yesterday: 'timespan.yesterday',
  Last7Days: 'timespan.last-7-days',
  ThisWeek: 'timespan.this-week',
  LastWeek: 'timespan.last-week',
  ThisMonth: 'timespan.this-month',
  LastMonth: 'timespan.last-month',
  ThisYear: 'timespan.this-year',
  LastYear: 'timespan.last-year',
};

export const SocialLoginTypesColors = new Map([
  [SocialLoginTypes.Facebook, { icon: 'bi-facebook', className: 'text-info' } ],
  [SocialLoginTypes.Google, { icon: 'bi-google', className: 'text-danger' } ],
  [SocialLoginTypes.LinkedIn, { icon: 'bi-linkedin', className: 'text-primary'  } ]
]);


export const RoomType = {
  OneBedroom: '1BR',
  TwoBedroom: '2BR',
  ThreeBedroom: '3BR',
  SingleRoom: 'SNR',
  DoubleRoom: 'DBR',
  Bedsitter: 'BST',
};

export const DueDiligenceLevel = {
  Kyc: 'kyc',
  Individual: 'individual',
  Business: 'business'
};

export const DueDiligenceLevelColors = new Map([
  [DueDiligenceLevel.Kyc, { bgColor: 'outline-primary', btnBg: 'primary' } ],
  [DueDiligenceLevel.Individual, { bgColor: 'outline-warning', btnBg: 'warning' } ],
  [DueDiligenceLevel.Business, { bgColor: 'outline-danger', btnBg: 'danger' } ]
]);

export const RoomStatus = {
  Vacant: 'vacant',
  Occupied: 'occupied'
};

export const RoomStatusColors = new Map([
  [RoomStatus.Occupied, { bgColor: 'outline-success', btnBg: 'success' } ],
  [RoomStatus.Vacant, { bgColor: 'outline-warning', btnBg: 'warning' } ]
]);

export const LeaseType = {
  Fixed: 'fixed',
  MonthToMonth: 'monthly',
  FixedRollOver: 'rollover',
};

export const RentCycle = {
  None: 'none',
  Daily: 'daily',
  Weekly: 'weekly',
  Every2Weeks: 'every-2-weeks',
  Monthly: 'monthly',
  Every2Months: 'every-2-months',
  Quarterly: 'quarterly',
  TwiceAYear: 'twice-a-year',
  Yearly: 'yearly'
};

export const RentCycleLength = new Map([
  [ RentCycle.None, { cycleType: null, cycleCount: null} ],
  [ RentCycle.Daily, { cycleType: 'day', cycleCount: 1 } ],
  [ RentCycle.Weekly, { cycleType: 'week', cycleCount: 1 } ],
  [ RentCycle.Every2Weeks, { cycleType: 'weeks', cycleCount: 2 } ],
  [ RentCycle.Monthly, { cycleType: 'month', cycleCount: 1 } ],
  [ RentCycle.Every2Months, { cycleType: 'months', cycleCount: 2 } ],
  [ RentCycle.Quarterly, { cycleType: 'quarter', cycleCount:  1 } ],
  [ RentCycle.TwiceAYear, { cycleType: 'months', cycleCount: 6 } ],
  [ RentCycle.Yearly, { cycleType: 'year', cycleCount: 1 } ]
]);

export const LeaseStatus = {
  Active: 'active',
  Expired: 'expired'
};

export const LeaseStatusColors = new Map([
  [LeaseStatus.Active, { bgColor: 'outline-primary', btnBg: 'success' } ],
  [LeaseStatus.Expired, { bgColor: 'outline-secondary', btnBg: 'warning' } ]
]);

export const RoomAmenity = {
  Water: 'water',
  Internet: 'internet',
  GarbageCollection: 'garbage-collection',
  Security: 'security',
  Cleaning: 'cleaning',
  Electricity: 'electricity',
  ElectronicRepairs: 'electronic-repairs',
  FlooringMaintenance: 'flooring-maintenance',
  Landscaping: 'landscaping',
  MasonryServices: 'masonry-services',
  InteriorDesign: 'interior-design',
  PaintingServices: 'painting-services',
  PlumbingServices: 'plumbing-services',
  RoofingRepairs: 'roofing-repairs',
  WindowsMaintenance: 'windows-maintenance',
  Others: 'others',
}

export const TaskType = {
  UnCategorized: 'un-categorized',
  Complaint: 'complaint',
  FeedbackSuggestion: 'feedback-suggestion',
  GeneralInquiry: 'general-inquiry',
  MaintenanceRequest: 'maintenance-request',
  Others: 'others'
}

export const TaskStatus = {
  New: 'new',
  InProgress: 'in-progress',
  Completed: 'completed',
  Deffered: 'deffered',
  Closed: 'closed'
}

export const TaskStatusColors = new Map([
  [TaskStatus.New, { bgColor: 'outline-success' } ],
  [TaskStatus.InProgress, { bgColor: 'outline-warning' } ],
  [TaskStatus.Completed, { bgColor: 'outline-primary' } ],
  [TaskStatus.Deffered, { bgColor: 'outline-default' } ],
  [TaskStatus.Closed, { bgColor: 'outline-danger' } ]
]);

export const Priority = {
  Low: 'low',
  Medium: 'medium',
  High: 'high'
}

export const BinaryAnswer = {
  Yes: 'Y',
  No: 'N',
}

export const BinaryAnswerColors = new Map([
  [BinaryAnswer.Yes, { bgColor: 'outline-success' } ],
  [BinaryAnswer.No, { bgColor: 'outline-danger' } ]
]);

export const TransactionType = {
  Payment: 'payment',
  RentBill: 'rent',
  WaterBill: 'water',
  InternetBill: 'internet',
  GarbageBill: 'garbage-collection',
  SecurityBill: 'security',
  CleaningBill: 'cleaning',
  ElectricityBill: 'electricity',
  Other: 'other'
}

export const TransactionTypeColors = new Map([
  [TransactionType.Payment, { bgColor: 'outline-success' } ],
  [TransactionType.RentBill, { bgColor: 'outline-primary' } ],
  [TransactionType.WaterBill, { bgColor: 'outline-secondary' } ],
  [TransactionType.InternetBill, { bgColor: 'outline-info' } ],
  [TransactionType.GarbageBill, { bgColor: 'outline-quaternary' } ],
  [TransactionType.SecurityBill, { bgColor: 'outline-tertiary' } ],
  [TransactionType.CleaningBill, { bgColor: 'outline-warning' } ],
  [TransactionType.ElectricityBill, { bgColor: 'outline-danger' } ],
  [TransactionType.Other, { bgColor: 'outline-dark' } ]
]);

export const PaymentModes = {
  MpesaExpress: 'mpesa-express',
  Mpesa: 'mpesa',
  Bank: 'bank',
  Paypal: 'paypal'
};

export const BatchUploadType = {
  Users: 'users',
  Tenants: 'tenants',
  Collections: 'collections',
  Customers: 'customers',
  Arrears: 'arrears',
  RoomUnits: 'rooms',
  Leases: 'leases',
  Vendors: 'vendors',
  WorkOrders: 'work-orders',
  Tasks: 'tasks'
};

export const Gender = {
  Male: 'male',
  Female: 'female',
  Other: 'other'
};

export const NotificationChannel = {
  Sms: 'sms',
  Email: 'email',
}

export const NoticeTypes = {
  CandidateConsent: 'consent',
  CompletionMessage: 'completion',
  GeneralMessage: 'general',
}

export const ResourceType = {
  File: 'file',
  Folder: 'folder',
  Link: 'symlink',
}

export const PaymentGateways = new Map([
  [PaymentModes.MpesaExpress, { imageURL: 'gateways/mpesa-express.png' } ],
  [PaymentModes.Mpesa, { imageURL: 'gateways/mpesa.png' } ],
  [PaymentModes.Bank, { imageURL: 'gateways/direct-transfer.png' } ],
  [PaymentModes.Paypal, { imageURL: 'gateways/paypal.png' } ]
]);

export const BccStatus = {
  Clear: 'clear',
  WithAlerts: 'with-alerts',
  AwaitingInfo: 'awaiting-info',
  MissingInfo: 'missing-info',
  Processing: 'processing',
  UnableToVerify: 'unable-to-verify'
}

export const DueDiligenceStatus = {
  Clear: 'clear',
  WithAlerts: 'with-alerts',
  New: 'new',
  MissingInfo: 'missing-info',
  Processing: 'processing',
  UnableToVerify: 'unable-to-verify'
}

export const BccStatusColors = new Map([
  [BccStatus.Clear, { color: 'primary', icon: 'check' } ],
  [BccStatus.WithAlerts, { color: 'warning', icon: 'question-hexagon' } ],
  [BccStatus.AwaitingInfo, { color: 'secondary', icon: 'more-horizontal' } ],
  [DueDiligenceStatus.New, { color: 'secondary', icon: 'more-horizontal' } ],
  [BccStatus.MissingInfo, { color: 'danger', icon: 'info-hexagon' } ],
  [BccStatus.Processing, { color: 'tertiary', icon: 'refresh-horizontal' } ],
  [BccStatus.UnableToVerify, { color: 'default', icon: 'puzzle' } ]
]);

export const BccUploadType = {
  Resume: 'resume',
  GoodConduct: 'good-conduct',
  NationalID: 'national-id',
  BirthCertificate: 'birth-certificate',
  AcademicCertificate: 'academic-certificate',
  Others: 'others'
}

export const InvestigationUploadType = {
  TaxCompliance: 'tax-compliance',
  TaxPin: 'tax-pin',
  IncorporationCertificate: 'incorporation-certificate',
  NationalIDDirector: 'national-id-director',
  NationalIDShareholder: 'national-id-shareholder',
  BusinessPermit: 'business-permit',
  Others: 'others'
}

export const ResumeKeys = {
  Period: 'period',
  Institution: 'institution',
  Qualification: 'qualification',
  Graduated: 'isGraduated',
  Education: 'education',
  WorkHistory: 'workHistory',
  Referees: 'referees',
  Profiles: 'profiles',
  Employer: 'employer',
  JobPosition: 'position',
  ValueOptions: 'options'
}

export const SocialMedia = {
  Facebook: 'facebook',
  Twitter: 'twitter',
  LinkedIn: 'linkedin',
  Youtube: 'youtube',
  Github: 'github'
}

export const SubscriptionStatus = {
  Pending: 'pending',
  Submitted: 'submitted',
  Partial: 'partial',
  Paid: 'paid',
  Active: 'active',
  Past: 'past'
}

export const SubscriptionStatusColors = new Map([
  [SubscriptionStatus.Active, { color: 'primary', icon: 'check' } ],
  [SubscriptionStatus.Pending, { color: 'warning', icon: 'question-hexagon' } ],
  [SubscriptionStatus.Submitted, { color: 'success', icon: 'information-hexagon' } ],
  [SubscriptionStatus.Partial, { color: 'secondary', icon: 'more-horizontal' } ],
  [SubscriptionStatus.Paid, { color: 'tertiary', icon: 'refresh-horizontal' } ],
  [SubscriptionStatus.Past, { color: 'default', icon: 'puzzle' } ]
]);

export const AccountTransactionType = {
  Receipt: 'receipt',
  Promo: 'promo',
  Invoice: 'invoice'
}

export const AccountTransactionTypeColors = new Map([
  [AccountTransactionType.Receipt, { color: 'primary', icon: 'check' } ],
  [AccountTransactionType.Promo, { color: 'warning', icon: 'more-horizontal' } ],
  [AccountTransactionType.Invoice, { color: 'danger', icon: 'refresh-horizontal' } ]
]);

const moment = require('moment-timezone');
moment.tz.setDefault("Etc/UTC");