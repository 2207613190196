import { SERVICE_URL } from 'config/config';
import api from '../api';

const countriesList = [
    {
      "countryID": "62331bc3cb8bd83d1487cbe6",
      "countryCode": "KE",
      "baseCurrency": "KES",
      "currencies": [
        "KES",
        "USD"
      ],
      "paymentProviders": [
        "direct",
        "mpesa"
      ]
    },
    {
      "countryID": "62334bd72e2ae63f30d9dddf",
      "countryCode": "UG",
      "baseCurrency": "UGX",
      "currencies": [
        "UGX",
        "USD",
        "KES"
      ],
      "paymentProviders": [
        "mpesa",
        "direct"
      ]
    },
    {
      "countryID": "62340beeb6baeb3b68955f67",
      "countryCode": "NG",
      "baseCurrency": "NGN",
      "currencies": [
        "NGN",
        "USD",
        "EUR",
        "KES"
      ],
      "paymentProviders": [
        "direct"
      ]
    },
    {
      "countryID": "623a113bf13e971f44745da2",
      "countryCode": "TZ",
      "baseCurrency": "TZS",
      "currencies": [
        "TZS",
        "USD"
      ],
      "paymentProviders": [
        "direct"
      ]
    }
  ];


api.onGet(`${SERVICE_URL}/countries`).reply(200, countriesList);