import { createAsyncThunk } from '@reduxjs/toolkit';
import httpService from 'httpService';

export const searchEngine = createAsyncThunk('/data-learning/search-engine', async (searchTerm) => {
  const response = await httpService.get(`/data-learning/search-engine?searchTerm=${searchTerm || ''}`);
  return response;
});

export const parseResumeUpload = createAsyncThunk('/data-learning/parse-upload', async ({file,fields}) => {
  const response = await httpService.post(`/data-learning/parse-upload`,fields);
  return response;
});

export const parseResumeById = createAsyncThunk('/data-learning/parse-resume/{candidateID}}', async (candidateID) => { 
  const response = await httpService.get(`/data-learning/parse-resume/${candidateID}`);
  return response;
});