const enMessages = {
  'nothing-found': 'es|Empty',
  'operation-new': 'es|You can now get started by clicking button on bottom right',
  'operation-search': 'es|You can now get started by searching for information above',
  'operation-add-above': 'es|You can now get started by adding information above',
  'title.operation': 'es|Data Operation',
  'title.results': 'es|Data Results',
  'title.processing': 'es|Data Processing',
  'title.error': 'es|Error Processing',
  'request.processing': 'es|Processing...',
  'request.processing-wait': 'es|Kindly wait for a few moments as the system processses your request...',
  'response.retrieved': 'es|Data has been retrieved successfully',
  'response.saved': 'es|Data has been saved successfully',
  'response.updated': 'es|Data has been updated successfully',
  'response.deleted': 'es|Data has been deleted successfully',
  'response.uploaded': 'es|Data has been uploaded successfully',

  'errors.expired-session': 'es|This session is expired. Please logout and login again!',
  'errors.invalid-inputs': 'es|Invalid input or wrong format. Please correct the form inputs then try again!',
  'errors.unexpected': 'es|An unexpected error occured. Please later!',
  'errors.form.controls': 'es|Correct errors on form before submit.',
  'errors.form.date-range': 'es|End date should be greater than start date',
  'errors.template.not-found': 'es|Sorry! The template for this check category could not be found!',
  'errors.records.not-found': 'es|Sorry! No records could be found. Please check again later.',

  'navigation.go-back': 'es|Go Back',
  'navigation.previous': 'es|Previous',
  'navigation.next': 'es|Next',

  'menu.dashboards': 'es|Dashboards',
  'menu.default': 'es|Default',
  'menu.visual': 'es|Visual',
  'menu.analytic': 'es|Analytic',
  'menu.apps': 'es|Apps',
  'menu.calendar': 'es|Calendar',
  'menu.language': 'es|Language',
  'menu.chat': 'es|Chat',
  'menu.contacts': 'es|Contacts',
  'menu.mailbox': 'es|Mailbox',
  'menu.tasks': 'es|Tasks',
  'menu.maintenance': 'es|Maintenance',
  'menu.vendors': 'es|Vendors',
  'menu.pages': 'es|Pages',
  'menu.authentication': 'es|Authentication',
  'menu.login': 'es|Login',
  'menu.register': 'es|Register',
  'menu.forgot-password': 'es|Forgot Password',
  'menu.reset-password': 'es|Reset Password',
  'menu.blog': 'es|Blog',
  'menu.home': 'es|Home',
  'menu.grid': 'es|Grid',
  'menu.list': 'es|List',
  'menu.detail': 'es|Detail',
  'menu.miscellaneous': 'es|Miscellaneous',
  'menu.faq': 'es|Faq',
  'menu.knowledge-base': 'es|Knowledge Base',
  'menu.error': 'es|Error',
  'menu.coming-soon': 'es|Coming Soon',
  'menu.pricing': 'es|Pricing',
  'menu.search': 'es|Search',
  'menu.mailing': 'es|Mailing',
  'menu.empty': 'es|Empty',
  'menu.portfolio': 'es|Portfolio',
  'menu.profile': 'es|Profile',
  'menu.standard': 'es|Standard',
  'menu.settings': 'es|Settings',
  'menu.blocks': 'es|Blocks',
  'menu.images': 'es|Images',
  'menu.tabular-data': 'es|Tabular Data',
  'menu.thumbnails': 'es|Thumbnails',
  'menu.cta': 'es|Cta',
  'menu.gallery': 'es|Gallery',
  'menu.stats': 'es|Stats',
  'menu.steps': 'es|Steps',
  'menu.details': 'es|Details',
  'menu.interface': 'es|Interface',
  'menu.components': 'es|Components',
  'menu.accordion': 'es|Accordion',
  'menu.alerts': 'es|Alerts',
  'menu.badge': 'es|Badge',
  'menu.breadcrumb': 'es|Breadcrumb',
  'menu.buttons': 'es|Buttons',
  'menu.button-group': 'es|Button Group',
  'menu.card': 'es|Card',
  'menu.close-button': 'es|Close Button',
  'menu.collapse': 'es|Collapse',
  'menu.dropdowns': 'es|Dropdowns',
  'menu.list-group': 'es|List Group',
  'menu.modal': 'es|Modal',
  'menu.navs': 'es|Navs',
  'menu.offcanvas': 'es|Offcanvas',
  'menu.pagination': 'es|Pagination',
  'menu.popovers': 'es|Popovers',
  'menu.progress': 'es|Progress',
  'menu.spinners': 'es|Spinners',
  'menu.toasts': 'es|Toasts',
  'menu.tooltips': 'es|Tooltips',
  'menu.forms': 'es|Forms',
  'menu.layouts': 'es|Layouts',
  'menu.validation': 'es|Validation',
  'menu.wizard': 'es|Wizard',
  'menu.input-group': 'es|Input Group',
  'menu.input-mask': 'es|Input Mask',
  'menu.generic-forms': 'es|Generic Forms',
  'menu.controls': 'es|Controls',
  'menu.autocomplete': 'es|Autocomplete',
  'menu.checkbox-radio': 'es|Checkbox-Radio',
  'menu.date-picker': 'es|Date Picker',
  'menu.dropzone': 'es|Dropzone',
  'menu.editor': 'es|Editor',
  'menu.input-spinner': 'es|Input Spinner',
  'menu.rating': 'es|Rating',
  'menu.select': 'es|Select',
  'menu.slider': 'es|Slider',
  'menu.tags': 'es|Tags',
  'menu.time-picker': 'es|Time Picker',
  'menu.plugins': 'es|Plugins',
  'menu.carousel': 'es|Carousel',
  'menu.charts': 'es|Charts',
  'menu.clamp': 'es|Clamp',
  'menu.context-menu': 'es|Context Menu',
  'menu.datatables': 'es|Datatables',
  'menu.editable-rows': 'es|Editable Rows',
  'menu.editable-boxed': 'es|Editable Boxed',
  'menu.server-side': 'es|Server Side',
  'menu.boxed-variations': 'es|Boxed Variations',
  'menu.lightbox': 'es|Lightbox',
  'menu.maps': 'es|Maps',
  'menu.notification': 'es|Notification',
  'menu.players': 'es|Players',
  'menu.scrollbar': 'es|Scrollbar',
  'menu.shortcuts': 'es|Shortcuts',
  'menu.sortable': 'es|Sortable',
  'menu.content': 'es|Content',
  'menu.icons': 'es|Icons',
  'menu.cs-line-icons': 'es|CS Line Icons',
  'menu.cs-interface-icons': 'es|CS Interface Icons',
  'menu.bootstrap-icons': 'es|Bootstrap Icons',
  'menu.tables': 'es|Tables',
  'menu.typography': 'es|Typography',
  'menu.menu': 'es|Menu',
  'menu.horizontal': 'es|Horizontal',
  'menu.vertical': 'es|Vertical',
  'menu.vertical-hidden': 'es|Vertical Hidden',
  'menu.vertical-no-hidden': 'es|Vertical No Hidden',
  'menu.mobile-only': 'es|Mobile Only',
  'menu.sidebar': 'es|Sidebar',
  'menu.Docs': 'es|Docs',
  'menu.connections': 'es|Connections',
  'menu.bookmarks': 'es|Bookmarks',
  'menu.requests': 'es|Requests',
  'menu.account': 'es|Account',
  'menu.password': 'es|Password',
  'menu.devices': 'es|Devices',
  'menu.notifications': 'es|Notifications',
  'menu.email': 'es|Email',
  'menu.sms': 'es|SMS',
  'menu.downloads': 'es|Downloads',
  'menu.documents': 'es|Documents',
  'menu.videos': 'es|Videos',
  'menu.dashboard': 'es|Dashboard',
  'menu.products': 'es|Products',
  'menu.orders': 'es|Bulk Orders',
  'menu.customers': 'es|Customers',
  'menu.storefront': 'es|Storefront',
  'menu.filters': 'es|Filters',
  'menu.categories': 'es|Categories',
  'menu.cart': 'es|Cart',
  'menu.checkout': 'es|Checkout',
  'menu.invoice': 'es|Invoice',
  'menu.shipping': 'es|Shipping',
  'menu.discount': 'es|Discount',
  'menu.general': 'es|General',
  'menu.vetting': 'es|Candidates',
  'menu.consents': 'es|Consents',
  'menu.due-diligence': 'es|Due Diligence',
  'menu.due-diligence-run': 'es|Run Due Diligence',
  'menu.archives': 'es|Archives',
  'menu.info-bot': 'es|Info Bot',
  'menu.investigate': 'es|Run Background',

  'menu.upgrade': 'es|Upgrade',
  'menu.community': 'es|Community',
  'menu.community-list': 'es|Community List',
  'menu.getting-started': 'es|Getting Started',
  'menu.analysis': 'es|Analysis',
  'menu.services': 'es|Services',
  'menu.property': 'es|Property',
  'menu.property-add': 'es|New Property',
  'menu.property-detail': 'es|Property Detail',
  'menu.tenants': 'es|Tenants',
  'menu.tenant-add': 'es|New Tenant',
  'menu.owners': 'es|Owners',
  'menu.owner-add': 'es|Add Owner',
  'menu.collections': 'es|Collections',
  'menu.arrears': 'es|Arrears',
  'menu.leases': 'es|Leases',
  'menu.uploads': 'es|Uploads',
  'menu.resources': 'es|Resources',
  'menu.hosting': 'es|Hosting',
  'menu.users': 'es|Users',
  'menu.billing': 'es|Billing',
  'menu.security': 'es|Security',
  'menu.support': 'es|Support',
  'menu.docs': 'es|Docs',
  'menu.docsdetail': 'es|Docs Detail',
  'menu.tickets': 'es|Tickets',
  'menu.tickets-detail': 'es|Teicket Detail',

  'username': 'es|Username',
  'password': 'es|Password',
  'old-password': 'es|Old Password',
  'verify-password': 'es|Verify Password',
  'client-code': 'es|Client Code',
  'id-number': 'es|National ID',
  'invoice-number': 'es| Invoice Number',
  'employee-number': 'es|Employee Number',
  'physical-address': 'es|Location',
  'postal-address': 'es|Postal Address',
  'residential-address': 'es|Residential Address',
  'country': 'es|Country',
  'nationality': 'es|Nationality',
  'website': 'es|Website',
  'initials': 'es|Initials',
  'currency': 'es|Currency',
  'company': 'es|Company Name',
  'unassigned': 'es|Unassigned',
  'add': 'es|Add',
  'new': 'es|New',
  'amend': 'es|Amend',
  'view': 'es|View',
  'delete': 'es|Delete',
  'resume': 'es|Resume',
  'copy': 'es|Copy',
  'submit': 'es|Submit',
  'ok': 'es|OK',
  'cancel': 'es|Cancel',
  'back': 'es|Back',
  'next': 'es|Next',
  'Y': 'es|Yes',
  'N': 'es|No',
  'suspend': 'es|Suspend',
  'activate': 'es|Activate',
  'close': 'es|Close',
  'no-change': 'es|No Change',
  'verify': 'Hakikisha',
  'quick-add': 'es|Quick Add',
  'quick-actions': 'es|Quick Actions',
  'bulk-upload': 'es|Bulk Upload',
  'bulk-upload.bills': 'es|Upload Bills',
  'view-more': 'es|View More',
  'random-search': 'es|Enter text to search...',
  'recent-activity': 'es|Recent Activity',
  'stats': 'es|Stats',
  'recent-activity.teller': 'es|Recent Teller Transactions and Activities',
  'actions': 'es|Actions',
  'select-year': 'es|Year',
  'select-month': 'es|Month',
  'select-day': 'es|Day', 
  'ago': 'es|Ago',
  'start-date': 'es|Start Date',
  'end-date': 'es|End Date',
  'from': 'es|From',
  'to': 'es|To',
  'dob': 'es|Date of Birth',
  'total-count': 'es|Total Count',
  'payment-details': 'es|Payment Details',
  'amount-distribution': 'es|Amount Distribution',
  'gender': 'Gender',
  'gender.male': 'Male',
  'gender.female': 'Female',
  'gender.other': 'Other',
  'none': 'None',
  
  'language.en': 'es|English',
  'language.sw': 'es|Swahili',
  'language.fr': 'es|Francais',
  'language.de': 'es|German',
  'language.es': 'es|Spanish',

  'crud.actions': 'es|Action',
  'crud.actions.add': 'es|Add New',
  'crud.actions.edit': 'es|Edit',
  'crud.actions.save': 'es|Save',
  'crud.actions.update': 'es|Update',
  'crud.actions.archive': 'es|Archive',
  'crud.actions.delete': 'es|Delete',
  'crud.actions.detail': 'es|View',
  'crud.actions.list': 'es|List',
  'crud.actions.bulk-download': 'es|Bulk Download',
  'crud.actions.search-record': 'es|Search Record',

  'alert.delete.title': 'es|Confirm Delete',
  'alert.delete.message': 'es|Do you really want to delete selected record(s)?',
  'alert.archive.title': 'es|Confirm Archive',
  'alert.archive.message': 'es|Do you really want to archive selected record(s)?',
  'alert.submit.title': 'es|Confirm Submit',
  'alert.mpesa.message': 'es|You are about to initiate an MPESA payment. Are you sure?',
  'alert.submit.message': 'es|Do you really want to save change(s) on record(s)?',
  'alert.language.title': 'es|Language Change',
  'alert.language.message': 'es|Do you really want to switch to selected language(s)?',
  'alert.signup.message': 'es|You are about to create an account. Are you sure?',
  'alert.change-password.message': 'es|You are about to change your password. Are you sure?',
  'alert.reset-password.message': 'es|You are about to reset your password. Are you sure?',
  'alert.change-password.success': 'es|Your password has been changed successfully.',
  'alert.reset-password.success': 'es|Your password reset code has been sent to email/sms',
  'alert.reset-password.failure': 'es|Attempt to reset password failed. Please retry after 1 minute!',
  'alert.otp-sent.success': 'es|Your OTP code has been sent to email/sms',
  'alert.otp-validated.success': 'es|Your OTP code has been verified successfully',
  'alert.registration.success': 'es|You are now a new user on the platform. Please check your email/sms for login credentials',
  'alert.registration.failure': 'es|User account registration has failed. Please retry after 1 minute!',
  'alert.account-lock': 'es|User account has been locked.',
  'alert.account-suspended': 'es|Your account has been suspended after many unsuccessful logins. Reach out to the admnistrator',
  
  'timespan.custom': 'es|Custom Date',
  'timespan.today': 'es|Today',
  'timespan.yesterday': 'es|Yesterday', 
  'timespan.last-7-days': 'es|Last 7 Days',
  'timespan.this-week': 'es|This Week',
  'timespan.last-week': 'es|Last Week',
  'timespan.this-month': 'es|This Month',
  'timespan.last-month': 'es|Last Month',
  'timespan.this-year': 'es|This Year', 
  'timespan.last-year': 'es|Last Year', 

  'period-cycle': 'es|Billing Cycle',
  'period-cycle.none': 'es|None',
  'period-cycle.daily': 'es|Daily',
  'period-cycle.weekly': 'es|Weekly',
  'period-cycle.every-2-weeks': 'es|Every 2 Weeks',
  'period-cycle.monthly': 'es|Monthly',
  'period-cycle.every-2-months': 'es|Every 2 Months',
  'period-cycle.quarterly': 'es|Quarterly',
  'period-cycle.twice-a-year': 'es|Twice A Year', 
  'period-cycle.yearly': 'es|Yearly', 

  'days.mon.short': 'es|Mon',
  'days.tue.short': 'es|Tue',
  'days.wed.short': 'es|Wed',
  'days.thu.short': 'es|Thu',
  'days.fri.short': 'es|Fri',
  'days.sat.short': 'es|Sat', 
  'days.sun.short': 'es|Sun',
  
  'months.jan.short': 'es|Jan',
  'months.feb.short': 'es|Feb',
  'months.mar.short': 'es|Mar',
  'months.apr.short': 'es|Apr',
  'months.may.short': 'es|May',
  'months.jun.short': 'es|Jun', 
  'months.jul.short': 'es|Jul',
  'months.aug.short': 'es|Aug',
  'months.sep.short': 'es|Sep',
  'months.oct.short': 'es|Oct',
  'months.nov.short': 'es|Nov',
  'months.dec.short': 'es|Dec',

  'notification': 'es|Notification',
  'notification.channel': 'es|Notification Channel',

  'login.via': 'es|Login Via',
  'login.forgot': 'es|Forgot?',
  'login.sign-in': 'es|Sign In',
  'login.sign-out': 'es|Logout',
  'login.sign-out-msg': 'es|You have successfully logged out',
  'login.use-credentials': 'es|Or use your credentials to login.',
  'login.go-dashboard': 'es|Go Dashboard',
  'login.2factor': 'es|OTP Validation',
  'login.2factor.description': 'es|The application needs to securely authenticate you onto the system. Please enter OTP Code sent to your email or SMS',
  'login.2factor.otp': 'es|OTP Code',
  'login.2factor.send':'es|Get OTP',
  'login.2factor.resend':'es|Resend',
  'login.2factor.channel': 'es|Change where to receive the OTP Code',
  'login.motto': 'es|The leading, AI based solution to candidate screening, background checks, risk assesment and due diligence.',
  
  'dashboard.metrics': 'es|Property Metrics',
  'dashboard.performance-stats': 'es|Performance Stats',
  'dashboard.quick-reports': 'es|Quick Reports',
  'dashboard.description': 'es|Intelligent property & tenant management, transaction reconciliation, and realtime reports.',
  'dashboard.search.title': 'es|Tenant Search',
  'dashboard.search.placeholder': 'es|Name or ID Number',
  'dashboard.search.description': 'es|Please enter Tenant\'s Name or ID number to search in your database',
  'customer.search.title': 'es|Customer Search',
  'customer.search.placeholder': 'es|Customer Name',
  'customer.search.description': 'es|Please enter Customer\'s Name to search in your database',

  'property.info': 'es|Property Info',
  'property.name': 'es|Property Name',
  'property.description': 'es|Description',
  'property.location': 'es|Location',
  'property.manager': 'es|Manager',
  'property.status': 'es|Status',
  'property.status.active': 'es|Active',
  'property.status.inactive': 'es|Inactive',
  'property.status.suspended': 'es|Suspended',
  'property.tenants': 'es|Tenants',
  'property.vacant': 'es|Vacant Rooms',
  'property.occupied': 'es|Occupied Rooms',
  'property.advances': 'es|Advance Payments',
  'property.arrears': 'es|Rent Arrears',
  'property.rent': 'es|Rent P.m',
  'property.security-deposit': 'es|Security Deposit',
  'property.other-charges': 'es|Other Charges',
  'property.room-number': 'es|Room No',
  'property.rooms': 'es|Rooms',
  'property.room-history': 'es|Room History',
  'property.occupancy': 'es|Occupancy',
  'property.collections-today': 'es|Collections Today',
  'property.collections': 'es|Rent Collections',
  'property.billing-day': 'es|Billing Cycle',
  'property.actual-rent': 'es|Actual Rent',
  'property.projected-rent': 'es|Projected Rent',
  'property.choose-room-types': 'es|Choose Room Types',
  'property.choose-amenities': 'es|Choose Amenities',
  'property.describe': 'es|Describe The Property',
  'property.manage-rooms': 'es|Manage Rooms',

  'amenities.water': 'es|Water',
  'amenities.internet': 'es|Internet',
  'amenities.garbage-collection': 'es|Garbage Collection',
  'amenities.security': 'es|Security',
  'amenities.cleaning': 'es|Cleaning',
  'amenities.electricity': 'es|Electricity',  
  'amenities.electronic-repairs': 'es|Electronic Repairs',
  'amenities.flooring-maintenance': 'es|Flooring Maintenance',
  'amenities.landscaping': 'es|Landscaping',
  'amenities.masonry-services': 'es|Masonry Services',
  'amenities.interior-design': 'es|Interior Design',
  'amenities.painting-services': 'es|Painting Services',
  'amenities.plumbing-services': 'es|Plumbing Services',
  'amenities.roofing-repairs': 'es|Roofing Repairs',
  'amenities.windows-maintenance': 'es|Windows Maintenance',
  'amenities.others': 'es|Others',

  'room.type': 'es|Room Type',
  'room.type.1BR': 'es|1 Bedroom',
  'room.type.2BR': 'es|2 Bedroom',
  'room.type.3BR': 'es|3 Bedroom',
  'room.type.SNR': 'es|Single Room',
  'room.type.DBR': 'es|Double Room',
  'room.type.BST': 'es|Bedsitter',
  'room.status': 'es|Room Status',
  'room.status.occupied': 'es|Occupied',
  'room.status.vacant': 'es|Vacant',
  'room.add': 'es|Add Room',
  'room.edit': 'es|Edit Room',
  'room.search.placeholder': 'es|Search Property',

  'tenant.name': 'es|Tenant Name',  
  'tenant.current': 'es|Current Tenant',  
  'tenant.past': 'es|Past Tenants',  
  'tenant.profile': 'es|Profile',
  'tenant.account-statement': 'es|Account Statement',
  'tenant.add': 'es|Add Tenant',
  'tenant.edit': 'es|Edit Tenant',
  'tenant.suspend': 'es|Suspend Tenant',
  'tenant.activate': 'es|Activate Tenant',
  'tenant.status': 'es|Tenant Status',
  'tenant.status.active': 'es|Active',
  'tenant.status.inactive': 'es|Inctive',
  'tenant.leases': 'es|Tenant Leases',

  'lease': 'es|Lease',
  'lease.details': 'es|Lease Details',
  'lease.days-left': 'es|Days Left',
  'lease.type': 'es|Lease Type',
  'lease.rent-cycle': 'es|Rent Cycle',
  'lease.type.fixed': 'es|Fixed',
  'lease.type.rollover': 'es|Fixed With RollOver',
  'lease.type.monthly': 'es|Month To Month',
  'lease.status': 'es|Lease Status',
  'lease.status.active': 'es|Active',
  'lease.status.expired': 'es|Expired',
  'lease.agreement': 'es|Agreement',
  'lease.add': 'es|Add Lease',
  'lease.edit': 'es|Edit Lease',
  'lease.renew': 'es|Renew',
  'lease.receive-payment': 'es|Receive Payment',
  'lease.search-lease': 'es|Search Lease',

  'profile': 'es|Profile',
  'profile.type': 'es|Profile Type',
  'profile.type.admin': 'es|Administrator',
  'profile.type.data-admin': 'es|Data Admin',
  'profile.type.investigator': 'es|Investigator',
  'profile.type.accountant': 'es|Accountant',
  'profile.type.customer-admin': 'es|Customer Admin',
  'profile.type.customer-user': 'es|Customer User',
  'profile.name': 'es|Full Name', 
  'profile.email': 'es|Email',
  'profile.phone': 'es|Phone',
  'profile.address': 'es|Address',
  'profile.active': 'es|Is Active',
  'profile.contact-info': 'es|Contact Info',  
  'profile.personal-info': 'es|Personal Info',  
  'profile.public-info': 'es|Public Info',
  'profile.email-settings': 'es|Email Settings',
  'profile.language-settings': 'es|Language Settings',
  'profile.add': 'es|Add Profile',
  'profile.edit': 'es|Edit Profile',

  'transactions': 'es|Transactions', 
  'transactions.count': 'es|No. of Transactions',
  'transactions.volume': 'es|Volume of Transactions',
  'transactions.fees': 'es|Transaction Fees',
  'transactions.reconciled': 'es|Reconciled Transactions',
  'transactions.pending': 'es|Pending Transactions',
  
  'transaction.reference': 'es|A/C REF',
  'transaction.message': 'es|Enter Transaction Message',
  'transaction.new-payment': 'es|New Payment',
  'transaction.new-message': 'es|You can add a missing payment below. Paste a raw transaction message from the Bank or Mpesa',
  'transaction.transact': 'es|Transact',
  'transaction.date': 'es|Date',
  'transaction.amount': 'es|Amount',
  'transaction.balance': 'es|Balance',
  'transaction.type': 'es|Item Type',
  'transaction.code': 'es|Transaction Code',
  'transaction.receipt-number': 'es|Receipt No.',
  'transaction.particulars': 'es|Particulars',
  'transaction.type.payment': 'es|Payment',
  'transaction.type.rent': 'es|Rent Bill',
  'transaction.type.water': 'es|Water Bill',
  'transaction.type.internet': 'es|Internet Bill',
  'transaction.type.garbage-collection': 'es|Garbage Collection',
  'transaction.type.security': 'es|Security Bill',
  'transaction.type.cleaning': 'es|Cleaning Bill',
  'transaction.type.electricity': 'es|Electricity Bill',
  'transaction.type.other': 'es|Other',

  'arrears.due-this-month': 'es|Due This Month',
  'arrears.past-arrears': 'es|Past Arrears',
  'arrears.total-arrears': 'es|Total Arrears',
  'arrears.breakdown-per-category': 'es|Breakdown Per Category',

  'storage.fileName': 'es| File Name',
  'storage.rowCount': 'es| Rows',
  'storage.validCount': 'es| Valid',
  'storage.uploadDate': 'es| Upload Date',
  'storage.batchType': 'es| Batch Type',
  'storage.batchType.tenants': 'es| Tenants',
  'storage.batchType.collections': 'es| Collections',
  'storage.batchType.rooms': 'es| Rooms',

  'uploader.uploadStep.title': 'es|Upload File',
  'uploader.uploadStep.manifestTitle': 'es|Data that we expect:',
  'uploader.uploadStep.manifestDescription': 'es|(You will have a chance to rename or remove columns in next steps)',
  'uploader.selectHeaderStep.title': 'es|Select Header Row',
  'uploader.matchColumnsStep.title': 'es|Match Columns',
  'uploader.validationStep.title': 'es|Validate Data',
  'uploader.alerts.confirmClose.headerTitle': 'es|Exit Import',

  'vendor': 'es| Vendor Name',
  'vendor.iscompany': 'es| Is Company?',
  'vendor.category': 'es| Service Type',
  'vendor.search.placeholder': 'es| Search Vendor',
  'vendor.add': 'es|Add Vendor',
  'vendor.edit': 'es|Edit Vendor',

  'work-task': 'es| Maintenance Task',
  'work-task.add': 'es| Add Task',
  'work-task.edit': 'es| Edit Task',
  'work-task.subject': 'es| Subject',
  'work-task.description': 'es| Work To Be Performed',
  'work-task.assignee': 'es| Assigned To',
  'work-task.collaborators': 'es| Collaborators',
  'work-task.due-date': 'es| Due Date',
  'work-task.date-created': 'es| Date Created',
  'work-task.recurring': 'es| Is Recurring',
  'work-task.status': 'es| Status',
  'work-task.status.new': 'es| New',
  'work-task.status.in-progress': 'es| In Progress',
  'work-task.status.completed': 'es| Completed',
  'work-task.status.deffered': 'es| Deffered',
  'work-task.status.closed': 'es| Closed',
  'work-task.type': 'es| Request Type',
  'work-task.type.un-categorized': 'es| Uncategorized',
  'work-task.type.complaint': 'es| Complaint',
  'work-task.type.feedback-suggestion': 'es| Feedback/Suggestion',
  'work-task.type.general-inquiry': 'es| General Inquiry',
  'work-task.type.maintenance-request': 'es| Maintenance Request',
  'work-task.type.others': 'es| Others',
  'work-task.type.closed': 'es| Closed',
  'work-task.priority': 'es| Priority',
  'work-task.priority.low': 'es| Low',
  'work-task.priority.medium': 'es| Medium',
  'work-task.priority.high': 'es| High',
  'work-task.last-update': 'es| Last Update',

  'work-order': 'es| Work Order',
  'work-order.add': 'es| Add Work Order',
  'work-order.edit': 'es| Edit Work Order',
  'work-order.entry-allowed': 'es| Entry Allowed?',
  'work-order.entry-contact': 'es| Entry Contact',
  'work-order.labor-boqs': 'es| Labor & Materials',
  'work-order.vendor-notes': 'es| Vendor Notes',

  'security': 'es| Security',
  'security.logs': 'es| Security Logs',
  'security.logs.resource': 'es| Resource',
  'security.logs.accessed-by': 'es| Accessed By',
  'security.logs.ip-address': 'es| IP Address',
  'security.logs.time': 'es| Time',

  'register.company-info':'es|Company Info',
  'register.title': 'es|Registration',
  'register.sign-up': 'es|Sign Up',
  'register.sign-up-as': 'es|Welcome to AI Vetting Software. You can get get started by providing details below and a few steps',
  'register.company-info.instruction': 'es|Please provide the details below to proceed to next step',
  'register.choose-package': 'es|Choose Package',
  'register.choose-package.instruction': 'es|Please choose your preferred package and proceed to next step',
  'register.add-users':'es|Add Users',
  'register.add-users.instruction': 'es|Please add your application users below to proceed to next step',
  'register.checkout': 'es|Checkout',
  'register.checkout.instruction': 'es|Please choose payment method to pay for the selected package',
  'register.finish': 'es|Finish',
  'register.thank-you': 'es|Thank You!',
  'register.completed': 'es|Your registration completed successfully!',
  'register.error': 'es|An error occured registering account. Please re-check before submitting!',
  'register.creating': 'es|Creating account...',
  'register.change-password': 'es|Password Change',
  'register.change-password.description': 'es|Please use the form below to change your password. A password change is a MUST for new accounts',
  'register.reset-now': 'es|Reset Now',
  'register.forgot-password': 'es|Lost Password?',
  'register.forgot-password.description': 'es|Please enter your email or phone to receive a temporary password. You must change this temporary password once you login',

  'billing.info':'es|Billing Information',
  'billing.next-date': 'es|Next Billing',
  'billing.estimated-volume': 'es|Estimated Volume of Checks Per Cycle',
  'billing.package': 'es|Package',
  'billing.description': 'es|Description',
  'billing.sub-total': 'es|SubTotal',
  'billing.total': 'es|Total',
  'billing.total-cost': 'es|Total Cost',
  'billing.no-of-users': 'es|No of users',
  'billing.volume': 'es|Volume of Checks',
  'billing.method': 'es|Payment Method',
  'billing.method.choose': 'es|Choose Payment Method',
  'billing.method.mpesa-express': 'es|Mpesa Express',
  'billing.method.mpesa': 'es|Mpesa Legacy',
  'billing.method.bank': 'es|Bank Cheque',
  'billing.method.paypal': 'es|Paypal',
  'billing.methods': 'es|Payment Methods',
  'billing.mpesa-already-paid': 'es|MPESA receipt number (If aready Paid)',
  'billing.statement.account': 'es|Account Statements',
  'billing.advance-payments': 'es|Advance Payments',
  'billing.current-invoice': 'es|Current Invoice',
  'billing.past-arrears': 'es|Past Arrears',
  'billing.promo.apply': 'es|Apply',
  'billing.promo.code': 'es|Promo Code',
  'billing.promo.description': 'es|If you have a promo code, you may apply here!',
  'billing.status': 'es|Status',
  'billing.transaction-type.receipt': 'es|Receipt',
  'billing.transaction-type.promo': 'es|Promo',
  'billing.transaction-type.invoice': 'es|Invoice',

  'due-diligence.search': 'es|Enter text to search cases...', 
  'due-diligence.case-number': 'es|Case Number', 
  'due-diligence.purpose': 'es|Purpose', 
  'due-diligence.category': 'es|Category', 
  'due-diligence.category.kyc': 'es|KYC Due Diligence', 
  'due-diligence.category.individual': 'es|Individual Due Diligence', 
  'due-diligence.category.business': 'es|Business Due Diligence', 
  'due-diligence.subject': 'es|Subject', 
  'due-diligence.status': 'es|Status', 
  'due-diligence.new-request': 'es|New Request', 
  'due-diligence.add': 'es|Add Due Diligence',
  'due-diligence.edit': 'es|Edit Due Diligence',
  'due-diligence.documents': 'es|Subject Documents',
  'due-diligence.tax-compliance': 'es|Tax Compliance',
  'due-diligence.tax-pin': 'es|Tax PIN',
  'due-diligence.incorporation-certificate': 'es|Incorporation Certificate',
  'due-diligence.national-id-director': 'es|National ID (Director)',
  'due-diligence.national-id-shareholder': 'es|National ID (Shareholder)',
  'due-diligence.business-permit': 'es|Business Permit',
  'due-diligence.others': 'es|Others',
  'due-diligence.submitted-on': 'es|Submitted On',

  'bcs': 'es|Background Checks',
  'bcs.upload.resume': 'es|Attach Resume', 
  'bcs.upload.others': 'es|Attach Document', 
  'bcs.all-checks': 'es|All Checks',
  'bcs.search': 'es|Enter text to search candidates...',
  'bcs.raise.completed': 'es|Background check request has been submitted successfully.',
  'bcs.raise.description': 'es|Customize background check, bulk upload candidate information and submit it in one workflow',
  'bcs.raise.customer':'es|Customer',
  'bcs.raise.customer.instruction': 'es|Please search the beneficiary customer details to get started.',
  'bcs.raise.customize':'es|Customize',
  'bcs.raise.customize.instruction': 'es|Please choose your package or select particular checks from the available below',
  'bcs.raise.candidates':'es|Candidates',
  'bcs.raise.candidates.instruction': 'es|Please add your candidates users or submit through the excel bulk upload below',
  'bcs.date': 'es|Date',
  'bcs.name': 'es|Name',
  'bcs.level': 'es|Level of Check',
  'bcs.levels': 'es|Levels of Check',
  'bcs.reference-number': 'es|Reference Number',
  'bcs.consent.via': 'es|Request Consent Via',
  'bcs.status': 'es|Status',
  'bcs.status.clear': 'es|Clear Results', 
  'bcs.status.with-alerts': 'es|Results With Alerts',
  'bcs.status.new': 'es|New',
  'bcs.status.awaiting-info': 'es|Awaiting Info', 
  'bcs.status.awaiting-info.candidate': 'es|Awaiting Info (From Candidate)', 
  'bcs.status.missing-info': 'es|Missing Info', 
  'bcs.status.processing': 'es|Results Processing',
  'bcs.status.unable-to-verify': 'es|Unable To Verify',
  'bcs.status.minified.clear': 'es|OK', 
  'bcs.status.minified.with-alerts': 'es|Alerts',
  'bcs.status.minified.awaiting-info': 'es|Awaiting Info', 
  'bcs.status.minified.awaiting-info.candidate': 'es|Awaiting Info', 
  'bcs.status.minified.missing-info': 'es|Missing Info', 
  'bcs.status.minified.processing': 'es|Processing',
  'bcs.status.minified.unable-to-verify': 'es|Unverifiable',
  'bcs.document.type': 'es|Document Type',
  'bcs.document.choose': 'es|Choose Document Type',
  'bcs.candidate.count': 'es|Candidate Count',
  'bcs.candidate.documents': 'es|Candidate Documents',
  'bcs.candidate.resume': 'es|Resume', 
  'bcs.candidate.good-conduct': 'es|Good Conduct',
  'bcs.candidate.national-id': 'es|National ID', 
  'bcs.candidate.birth-certificate': 'es|Birth Certificate', 
  'bcs.candidate.academic-certificate': 'es|Academic Certificate',
  'bcs.candidate.others': 'es|Others',
  'bcs.candidate.consent': 'es|Candidate Consent',
  'bcs.candidate.add': 'es|Add Candidate',
  'bcs.candidate.edit': 'es|Edit Candidate',
  'bcs.load-resume': 'es|Load Resume',
  'bcs.print-pdf': 'es|View PDF',
  'bcs.education.institution': 'es|Institution',
  'bcs.education.contacts': 'es|Contacts',
  'bcs.education.email': 'es|Email',
  'bcs.education.address': 'es|Address',
  'bcs.education.contact-person': 'es|Contact Person',
  'bcs.education.qualification': 'es|Area Of Study',
  'bcs.education.isgraduated': 'es|Graduated?',
  'bcs.education.period': 'es|Period',
  'bcs.work-history.period': 'es|Period',
  'bcs.work-history.job-position': 'es|Job Position',
  'bcs.work-history.employer': 'es|Employer',
  'bcs.checks.final-comments': 'es|Final Comments',
  'bcs.checks.comments': 'es|Previous Comments',
  'bcs.checks.comments.title': 'es|Comment Title',
  'bcs.checks.comments.add': 'es|Add Comments',
  'bcs.checks.documents': 'es|Previous Documents',
  'bcs.checks.emails': 'es|Previous Emails',
  'bcs.checks.emails.send': 'es|Send Email',

  'consents.sent-on': 'es|Sent On',
  'consents.subject': 'es|Subject',
  'consents.actioned-on': 'es|Actioned On',
  'consents.isconsented': 'es|Is Consented?',
  'consents.actions.approve': 'es|Approve',
  'consents.actions.reject': 'es|Reject',
  'consents.candidate.description': 'es|Verify candidate supplied information and candidate consent',
  'consents.candidate.intro': 'es|Candidate Intro',
  'consents.candidate.intro.instruction': 'es|Welcome {subject}. {customer} is using AI Vetting to run your background checks. Please fill in the details below.',
  'consents.candidate.work-history': 'es|Work History',
  'consents.candidate.work-history.instruction': 'es|Please fill in and correct missing details in the pre-filled form generated from from your resume',
  'consents.candidate.education': 'es|Education',
  'consents.candidate.education.instruction': 'es|Please fill in and correct missing details in the pre-filled form generated from from your resume',
  'consents.candidate.sign': 'es|Sign Consent',
  'consents.candidate.sign.instruction': 'es|Welcome {subject}. {customer} is using AI Vetting to run your background checks. Please read this agreement below',
  'consents.candidate.finish': 'es|Finish',
  'consents.candidate.agree': 'es|I hereby agree, to the best of my knowledge, to provide true and accurate information',
  'consents.candidate.completed': 'es|Your background check consent and info has been completed successfully.',

  'info-bot.request': 'es|Search people or organizations...',
  'info-bot.response.title': 'es|Search Results',
  'info-bot.exact-matches': 'es|Exact Matches',
  'info-bot.other-matches': 'es|Other Relevant Info',
  'info-bot.no-exact-matches': 'es|We couldn\'t find exact matches for based on your search criteria. Please view other relevant info below',

  'customers.date': 'es|Date Created',
  'customers.name': 'es|Customer Name',
  'customers.package': 'es|Active Package',
  'customers.status': 'es|Status',
  'customers.ispostpay': 'es|Is Postpay Account',
  'customers.status.pending': 'es|Pending',
  'customers.status.onboarding': 'es|Onboarding',
  'customers.status.suspended': 'es|Suspended',
  'customers.status.active': 'es|Active',
  'customers.status.dormant': 'es|Dormant',
  'customers.add': 'es|Add Customer',
  'customers.edit': 'es|Edit Customer',

  'orders.add': 'es|New Order',
  'orders.edit': 'es|Edit Order',
  'orders.date': 'es|Order Date',
  'orders.order-number': 'es|Order Number',
  'orders.status': 'es|Status',
  'orders.status.pending': 'es|Pending',
  'orders.status.submitted': 'es|Submitted',
  'orders.status.partial': 'es|Partial',
  'orders.status.paid': 'es|Paid',
  'orders.status.active': 'es|Active',
  'orders.status.past': 'es|Past',
};
export default enMessages;
