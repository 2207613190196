import { SERVICE_URL } from 'config/config';
import { BccStatus } from 'constants.js';
import api from '../api';

export const candidateList = [
  {candidateID: '61e3e5880b965916e0822eba', submittedOn: '2024-03-01', subject: 'Kevin Carlton Kimberly', status: BccStatus.AwaitingInfo},
  {candidateID: '61e3e5880b965916e0822ebb', submittedOn: '2024-03-01', subject: 'Benard Mint', status: BccStatus.AwaitingInfo},
  {candidateID: '61e3e5880b965916e0822ebc', submittedOn: '2024-03-01', subject: 'James Stark Justin', status: BccStatus.Clear},
  {candidateID: '61e3e5880b965916e0822ebd', submittedOn: '2024-03-01', subject: 'Devin Giffen Desk', status: BccStatus.Processing},
  {candidateID: '61e3e5880b965916e0822ebe', submittedOn: '2024-03-01', subject: 'Betty Grant', status: BccStatus.WithAlerts},
  {candidateID: '61e3e5880b965916e0822ebf', submittedOn: '2024-03-01', subject: 'James Bush', status: BccStatus.Processing},
];

api.onGet(`${SERVICE_URL}/candidates`).reply(200, candidateList);

export const checkTree = [
	{
		"ctm_id": 1,
		"division": "BCC",
		"check_title": "Academic Checks",
		"code": "AC",
		"active": "Y",
		"position": 1,
		"items": [
			{
				"id": 1,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
					{
						"doc_id": 34329,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34329"
					},
					{
						"doc_id": 34330,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34330"
					}
				],
				"id_parent": 0,
				"text": "Certificate Verification 1",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": 
				{
					"bcm_id": 22556,
					"bcc_id": 13228,
					"rqc_id": 2912410,
					"comment": "From:  Prof. Laila Abubakar <vc@tum.ac.ke> \nSent:  Monday, 22 April 2024 17: 51\nTo:  bsein@spectrumnetworkpi.com; risk@spectrumnetworkpi.com;\ninfo@spectrumnetworkpi.com\nSubject:  VERIFICATION OF ACADEMIC CERTIFICATE FOR DICKSON MAINA KAROKI<\/o: p><\/p>\n\n<\/o: p><\/p>"
				},
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912410,
				"bcc_id": 13228,
				"ctm_id": 1,
				"tmi_id": 1,
				"remarks": "Bachelor of Technology in Applied Physics",
				"disabled": "N",
				"action_time": 1713780936,
				"path": "",
				"children": [
					{
						"id": 13,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 1,
						"text": "Institution",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912413,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 13,
						"remarks": "Technical University of Mombasa",
						"disabled": "N",
						"action_time": 1713780683,
						"path": "Certificate Verification 1 ● "
					},
					{
						"id": 21,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 1,
						"text": "Contact Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912415,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 21,
						"remarks": 710314233,
						"disabled": "N",
						"action_time": 1713780859,
						"path": "Certificate Verification 1 ● "
					},
					{
						"id": 20,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 1,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912414,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 20,
						"remarks": "P.O Box 90420 - 80100",
						"disabled": "N",
						"action_time": 1713783544,
						"path": "Certificate Verification 1 ● "
					},
					{
						"id": 2,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 1,
						"text": "Email",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912411,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 2,
						"remarks": "vc@tum.ac.ke",
						"disabled": "N",
						"action_time": 1713780870,
						"path": "Certificate Verification 1 ● "
					},
					{
						"id": 3,
						"editable": "Y",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 1,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912412,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 3,
						"remarks": "Vice-Chancellor office",
						"disabled": "N",
						"action_time": 1713780883,
						"path": "Certificate Verification 1 ● "
					},
					{
						"id": 73,
						"editable": "N",
						"position": 6,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 1,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912418,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 73,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 1 ● ",
						"children": [
							{
								"id": 24,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 73,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912416,
								"bcc_id": 13228,
								"ctm_id": 1,
								"tmi_id": 24,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Certificate Verification 1 ● Details ● ",
								"children": [
									{
										"id": 77,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 24,
										"text": "Dates Attended",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912419,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 77,
										"remarks": "Graduated on 14th July 2022",
										"disabled": "N",
										"action_time": 1713850325,
										"path": "Certificate Verification 1 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 78,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 24,
										"text": "Certificate",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912420,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 78,
										"remarks": "Bachelor of Technology in Applied Physics",
										"disabled": "N",
										"action_time": 1713850362,
										"path": "Certificate Verification 1 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 27,
								"editable": "Y",
								"position": 3,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 73,
								"text": "Institution Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912417,
								"bcc_id": 13228,
								"ctm_id": 1,
								"tmi_id": 27,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Certificate Verification 1 ● Details ● ",
								"children": [
									{
										"id": 81,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 27,
										"text": "Certificate",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912422,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 81,
										"remarks": "Graduated on 14th July 2022",
										"disabled": "N",
										"action_time": 1713850330,
										"path": "Certificate Verification 1 ● Details ● Institution Verified Information ● "
									},
									{
										"id": 80,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 27,
										"text": "Dates Attended",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912421,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 80,
										"remarks": "Bachelor of Technology in Applied Physics",
										"disabled": "N",
										"action_time": 1713850362,
										"path": "Certificate Verification 1 ● Details ● Institution Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 138,
				"editable": "Y",
				"position": 2,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Certificate Verification 2",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912423,
				"bcc_id": 13228,
				"ctm_id": 1,
				"tmi_id": 138,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713781043,
				"path": "",
				"children": [
					{
						"id": 139,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 138,
						"text": "Institution",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912424,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 139,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 2 ● "
					},
					{
						"id": 140,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 138,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912425,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 140,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 2 ● "
					},
					{
						"id": 141,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 138,
						"text": "Contact Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912426,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 141,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 2 ● "
					},
					{
						"id": 142,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 138,
						"text": "Email",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912427,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 142,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 2 ● "
					},
					{
						"id": 143,
						"editable": "Y",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 138,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912428,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 143,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 2 ● "
					},
					{
						"id": 144,
						"editable": "Y",
						"position": 6,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 138,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912429,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 144,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 2 ● ",
						"children": [
							{
								"id": 145,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 144,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912430,
								"bcc_id": 13228,
								"ctm_id": 1,
								"tmi_id": 145,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Certificate Verification 2 ● Details ● ",
								"children": [
									{
										"id": 146,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 145,
										"text": "Dates Attended",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912431,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 146,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 2 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 147,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 145,
										"text": "Certificate",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912432,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 147,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 2 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 148,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 144,
								"text": "Institution Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912433,
								"bcc_id": 13228,
								"ctm_id": 1,
								"tmi_id": 148,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Certificate Verification 2 ● Details ● ",
								"children": [
									{
										"id": 149,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 148,
										"text": "Dates Attended",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912434,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 149,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 2 ● Details ● Institution Verified Information ● "
									},
									{
										"id": 150,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 148,
										"text": "Certificate",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912435,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 150,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 2 ● Details ● Institution Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 177,
				"editable": "Y",
				"position": 3,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Certificate Verification 3",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912436,
				"bcc_id": 13228,
				"ctm_id": 1,
				"tmi_id": 177,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713781045,
				"path": "",
				"children": [
					{
						"id": 178,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 177,
						"text": "Institution",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912437,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 178,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 3 ● "
					},
					{
						"id": 179,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 177,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912438,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 179,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 3 ● "
					},
					{
						"id": 180,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 177,
						"text": "Contact Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912439,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 180,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 3 ● "
					},
					{
						"id": 181,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 177,
						"text": "Email",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912440,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 181,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 3 ● "
					},
					{
						"id": 182,
						"editable": "Y",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 177,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912441,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 182,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 3 ● "
					},
					{
						"id": 183,
						"editable": "N",
						"position": 6,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 177,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912442,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 183,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 3 ● ",
						"children": [
							{
								"id": 184,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 183,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912443,
								"bcc_id": 13228,
								"ctm_id": 1,
								"tmi_id": 184,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Certificate Verification 3 ● Details ● ",
								"children": [
									{
										"id": 185,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 184,
										"text": "Dates Attended",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912444,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 185,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 3 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 186,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 184,
										"text": "Certificate",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912445,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 186,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 3 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 187,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 183,
								"text": "Institution Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912446,
								"bcc_id": 13228,
								"ctm_id": 1,
								"tmi_id": 187,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Certificate Verification 3 ● Details ● ",
								"children": [
									{
										"id": 188,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 187,
										"text": "Dates Attended",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912447,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 188,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 3 ● Details ● Institution Verified Information ● "
									},
									{
										"id": 189,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 187,
										"text": "Certificate",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912448,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 189,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 3 ● Details ● Institution Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 270,
				"editable": "Y",
				"position": 4,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Certificate Verification 4",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912449,
				"bcc_id": 13228,
				"ctm_id": 1,
				"tmi_id": 270,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713781047,
				"path": "",
				"children": [
					{
						"id": 271,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 270,
						"text": "Institution",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912450,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 271,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 4 ● "
					},
					{
						"id": 272,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 270,
						"text": "Contact Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912451,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 272,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 4 ● "
					},
					{
						"id": 273,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 270,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912452,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 273,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 4 ● "
					},
					{
						"id": 274,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 270,
						"text": "Email",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912453,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 274,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 4 ● "
					},
					{
						"id": 275,
						"editable": "Y",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 270,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912454,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 275,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 4 ● "
					},
					{
						"id": 276,
						"editable": "N",
						"position": 6,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 270,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912455,
						"bcc_id": 13228,
						"ctm_id": 1,
						"tmi_id": 276,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Certificate Verification 4 ● ",
						"children": [
							{
								"id": 277,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 276,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912456,
								"bcc_id": 13228,
								"ctm_id": 1,
								"tmi_id": 277,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Certificate Verification 4 ● Details ● ",
								"children": [
									{
										"id": 279,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 277,
										"text": "Dates Attended",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912458,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 279,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 4 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 281,
										"editable": "N",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 277,
										"text": "Certificate",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912460,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 281,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 4 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 278,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 276,
								"text": "Institution Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912457,
								"bcc_id": 13228,
								"ctm_id": 1,
								"tmi_id": 278,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Certificate Verification 4 ● Details ● ",
								"children": [
									{
										"id": 280,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 278,
										"text": "Dates Attended",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912459,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 280,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 4 ● Details ● Institution Verified Information ● "
									},
									{
										"id": 282,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 278,
										"text": "Certificate",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912461,
										"bcc_id": 13228,
										"ctm_id": 1,
										"tmi_id": 282,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776414,
										"path": "Certificate Verification 4 ● Details ● Institution Verified Information ● "
									}
								]
							}
						]
					}
				]
			}
		],
		"brc_id": 125950,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": 2,
		"disabled": "N",
		"action_time": 1713850716
	},
	{
		"ctm_id": 2,
		"division": "BCC",
		"check_title": "Reference Checks",
		"code": "RC",
		"active": "Y",
		"position": 3,
		"items": [
			{
				"id": 33,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
					{
						"doc_id": 34315,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34315"
					},
					{
						"doc_id": 34316,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34316"
					},
					{
						"doc_id": 34317,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34317"
					}
				],
				"id_parent": 0,
				"text": "1st Reference",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": 
				{
					"bcm_id": 22547,
					"bcc_id": 13228,
					"rqc_id": 2912464,
					"comment": "From: <\/b> COM <corori@tum.ac.ke>\n\nSent: <\/b> Friday, 19 April 2024 08: 37\nTo: <\/b> BEATRICE <bsein@spectrumnetworkpi.com>\nSubject: <\/b> Re:  URGENT BACKGROUND CHECK FOR DICKSON KARORI<\/o: p><\/p>\n\n<\/o: p><\/p>"
				},
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912464,
				"bcc_id": 13228,
				"ctm_id": 2,
				"tmi_id": 33,
				"remarks": "Dr.Cliff Mosiro",
				"disabled": "N",
				"action_time": 1713783468,
				"path": "",
				"children": [
					{
						"id": 51,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 33,
						"text": "Occupation",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912465,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 51,
						"remarks": "Physics exam coordinator",
						"disabled": "N",
						"action_time": 1713783526,
						"path": "1st Reference ● "
					},
					{
						"id": 52,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 33,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912466,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 52,
						"remarks": "P.O Box 90420 - 80100",
						"disabled": "N",
						"action_time": 1713784029,
						"path": "1st Reference ● "
					},
					{
						"id": 53,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 33,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912467,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 53,
						"remarks": 716633719,
						"disabled": "N",
						"action_time": 1713783564,
						"path": "1st Reference ● "
					},
					{
						"id": 151,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 33,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912474,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 151,
						"remarks": "corori@tum.ac.ke",
						"disabled": "N",
						"action_time": 1713783584,
						"path": "1st Reference ● "
					}
				]
			},
			{
				"id": 31,
				"editable": "Y",
				"position": 2,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
					{
						"doc_id": 34318,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34318"
					},
					{
						"doc_id": 34319,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34319"
					}
				],
				"id_parent": 0,
				"text": "2nd reference",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": {
					"bcm_id": 22548,
					"bcc_id": 13228,
					"rqc_id": 2912462,
					"comment": "From: <\/b> Jobunga Eric\n<ejobunga@tum.ac.ke> \nSent: <\/b> Friday, 19 April 2024 14: 58\nTo: <\/b> BEATRICE <bsein@spectrumnetworkpi.com>\nSubject: <\/b> Re:  URGENT BACKGROUND CHECK FOR DICKSON KARORI<\/o: p><\/p>\n\n<\/o: p><\/p>"
				},
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912462,
				"bcc_id": 13228,
				"ctm_id": 2,
				"tmi_id": 31,
				"remarks": "Dr. Erick Jobunga",
				"disabled": "N",
				"action_time": 1713783986,
				"path": "",
				"children": [
					{
						"id": 55,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 31,
						"text": "Occupation",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912468,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 55,
						"remarks": "Former Math and Physics chairperson",
						"disabled": "N",
						"action_time": 1713784019,
						"path": "2nd reference ● "
					},
					{
						"id": 57,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 31,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912469,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 57,
						"remarks": "P.O Box 90420 - 80100",
						"disabled": "N",
						"action_time": 1713784032,
						"path": "2nd reference ● "
					},
					{
						"id": 152,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 31,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912475,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 152,
						"remarks": "ejobunga@tum.ac.ke",
						"disabled": "N",
						"action_time": 1713784063,
						"path": "2nd reference ● "
					},
					{
						"id": 58,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 31,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912470,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 58,
						"remarks": 731853492,
						"disabled": "N",
						"action_time": 1713784105,
						"path": "2nd reference ● "
					}
				]
			},
			{
				"id": 32,
				"editable": "Y",
				"position": 3,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "3rd reference",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912463,
				"bcc_id": 13228,
				"ctm_id": 2,
				"tmi_id": 32,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713784108,
				"path": "",
				"children": [
					{
						"id": 60,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 32,
						"text": "Occupation",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912471,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 60,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "3rd reference ● "
					},
					{
						"id": 61,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 32,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912472,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 61,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "3rd reference ● "
					},
					{
						"id": 62,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 32,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912473,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 62,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "3rd reference ● "
					},
					{
						"id": 153,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 32,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912476,
						"bcc_id": 13228,
						"ctm_id": 2,
						"tmi_id": 153,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "3rd reference ● "
					}
				]
			}
		],
		"brc_id": 125951,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": 2,
		"disabled": "N",
		"action_time": 1713784563
	},
	{
		"ctm_id": 3,
		"division": "BCC",
		"check_title": "Previous Employment",
		"code": "PE",
		"active": "Y",
		"position": 2,
		"items": [
			{
				"id": 30,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
					{
						"doc_id": 34333,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34333"
					}
				],
				"id_parent": 0,
				"text": "Company 1",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": 
				{
					"bcm_id": 22565,
					"bcc_id": 13228,
					"rqc_id": 2912477,
					"comment": "From: <\/b> Natures Cool\n<naturescoolltd@gmail.com> \nSent: <\/b> Tuesday, 23 April 2024 11: 07\nTo: <\/b> BEATRICE <bsein@spectrumnetworkpi.com>\nSubject: <\/b> Re:  RE:  URGENT BACKGROUND CHECK FOR DICKSON KARORI MAINA<\/o: p><\/p>"
				},
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912477,
				"bcc_id": 13228,
				"ctm_id": 3,
				"tmi_id": 30,
				"remarks": "Natures Cool Limited",
				"disabled": "N",
				"action_time": 1713781075,
				"path": "",
				"children": [
					{
						"id": 40,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 30,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912480,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 40,
						"remarks": "Nairobi",
						"disabled": "N",
						"action_time": 1713782741,
						"path": "Company 1 ● "
					},
					{
						"id": 63,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 30,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912483,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 63,
						"remarks": "naturescoolltd@gmail.com",
						"disabled": "N",
						"action_time": 1713781465,
						"path": "Company 1 ● "
					},
					{
						"id": 41,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 30,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912481,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 41,
						"remarks": "Human Resource",
						"disabled": "N",
						"action_time": 1713781523,
						"path": "Company 1 ● "
					},
					{
						"id": 95,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 30,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912490,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 95,
						"remarks": 780885008,
						"disabled": "N",
						"action_time": 1713781542,
						"path": "Company 1 ● "
					},
					{
						"id": 107,
						"editable": "N",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 30,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912501,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 107,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Company 1 ● ",
						"children": [
							{
								"id": 96,
								"editable": "N",
								"position": 5,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 107,
								"text": "Applicant Supplied information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912491,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 96,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Company 1 ● Details ● ",
								"children": [
									{
										"id": 97,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 96,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912492,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 97,
										"remarks": "November 2022 to February2023;",
										"disabled": "N",
										"action_time": 1713782515,
										"path": "Company 1 ● Details ● Applicant Supplied information ● "
									},
									{
										"id": 98,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 96,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912493,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 98,
										"remarks": "Technical Machine",
										"disabled": "N",
										"action_time": 1713782538,
										"path": "Company 1 ● Details ● Applicant Supplied information ● "
									},
									{
										"id": 99,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 96,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912494,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 99,
										"remarks": "Not stated",
										"disabled": "N",
										"action_time": 1713782543,
										"path": "Company 1 ● Details ● Applicant Supplied information ● "
									},
									{
										"id": 100,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 96,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912495,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 100,
										"remarks": "Not stated",
										"disabled": "N",
										"action_time": 1713782549,
										"path": "Company 1 ● Details ● Applicant Supplied information ● "
									}
								]
							},
							{
								"id": 102,
								"editable": "N",
								"position": 6,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 107,
								"text": "Company Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912496,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 102,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776414,
								"path": "Company 1 ● Details ● ",
								"children": [
									{
										"id": 103,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 102,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912497,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 103,
										"remarks": "7months",
										"disabled": "N",
										"action_time": 1713860311,
										"path": "Company 1 ● Details ● Company Verified Information ● "
									},
									{
										"id": 104,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 102,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912498,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 104,
										"remarks": "Machine Operator",
										"disabled": "N",
										"action_time": 1713860342,
										"path": "Company 1 ● Details ● Company Verified Information ● "
									},
									{
										"id": 105,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 102,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912499,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 105,
										"remarks": "Ksh15,000",
										"disabled": "N",
										"action_time": 1713861110,
										"path": "Company 1 ● Details ● Company Verified Information ● "
									},
									{
										"id": 106,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 102,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912500,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 106,
										"remarks": "Desire for change",
										"disabled": "N",
										"action_time": 1713861134,
										"path": "Company 1 ● Details ● Company Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 38,
				"editable": "Y",
				"position": 2,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
					{
						"doc_id": 34348,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34348"
					},
					{
						"doc_id": 34349,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34349"
					}
				],
				"id_parent": 0,
				"text": "Company 2",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": 
				{
					"bcm_id": 22576,
					"bcc_id": 13228,
					"rqc_id": 2912478,
					"comment": "From: <\/b> sfitzwanga@kmfri.go.ke\n<sfitzwanga@kmfri.go.ke> \nSent: <\/b> Tuesday, 23 April 2024 15: 20\nTo: <\/b> bsein <bsein@spectrumnetworkpi.com>\nCc: <\/b> risk <risk@spectrumnetworkpi.com>; risk <risk@spectrumnetworkpi.com>\nSubject: <\/b> BACKGROUND CHECK FOR DICKSON KARORI MAINA FROM KMFRI<\/o: p><\/p>\n\n<\/o: p><\/p>"
				},
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912478,
				"bcc_id": 13228,
				"ctm_id": 3,
				"tmi_id": 38,
				"remarks": "Kenya Marine and fisheries Research Institute",
				"disabled": "N",
				"action_time": 1713861465,
				"path": "",
				"children": [
					{
						"id": 64,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 38,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912484,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 64,
						"remarks": "P.O. Box 81651 - 80100. Mombasa, KENYA",
						"disabled": "N",
						"action_time": 1713782823,
						"path": "Company 2 ● "
					},
					{
						"id": 65,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 38,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912485,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 65,
						"remarks": "sfitzwanga@gmail.com",
						"disabled": "N",
						"action_time": 1713782842,
						"path": "Company 2 ● "
					},
					{
						"id": 66,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 38,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912486,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 66,
						"remarks": "Human resource",
						"disabled": "N",
						"action_time": 1713782850,
						"path": "Company 2 ● "
					},
					{
						"id": 124,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 38,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912502,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 124,
						"remarks": "+254 (20) 8021561",
						"disabled": "N",
						"action_time": 1713782861,
						"path": "Company 2 ● "
					},
					{
						"id": 125,
						"editable": "Y",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 38,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912503,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 125,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 2 ● ",
						"children": [
							{
								"id": 127,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 125,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912504,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 127,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 2 ● Details ● ",
								"children": [
									{
										"id": 128,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 127,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912505,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 128,
										"remarks": "April-August 2022",
										"disabled": "N",
										"action_time": 1713876984,
										"path": "Company 2 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 130,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 127,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912506,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 130,
										"remarks": "Instrumentation and control undergraduate trainee",
										"disabled": "N",
										"action_time": 1713782887,
										"path": "Company 2 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 131,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 127,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912507,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 131,
										"remarks": "Not stated",
										"disabled": "N",
										"action_time": 1713782918,
										"path": "Company 2 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 132,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 127,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912508,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 132,
										"remarks": "Not stated",
										"disabled": "N",
										"action_time": 1713782923,
										"path": "Company 2 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 133,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 125,
								"text": "Company Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912509,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 133,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 2 ● Details ● ",
								"children": [
									{
										"id": 134,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 133,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912510,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 134,
										"remarks": "2nd June 2022-9th Sept 2022",
										"disabled": "N",
										"action_time": 1713875824,
										"path": "Company 2 ● Details ● Company Verified Information ● "
									},
									{
										"id": 135,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 133,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912511,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 135,
										"remarks": "Attach\u00e9",
										"disabled": "N",
										"action_time": 1713876053,
										"path": "Company 2 ● Details ● Company Verified Information ● "
									},
									{
										"id": 136,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 133,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912512,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 136,
										"remarks": "No salary earned",
										"disabled": "N",
										"action_time": 1713876134,
										"path": "Company 2 ● Details ● Company Verified Information ● "
									},
									{
										"id": 137,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 133,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912513,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 137,
										"remarks": "Attachment period expired",
										"disabled": "N",
										"action_time": 1713876164,
										"path": "Company 2 ● Details ● Company Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 39,
				"editable": "Y",
				"position": 3,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Company 3",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912479,
				"bcc_id": 13228,
				"ctm_id": 3,
				"tmi_id": 39,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713783411,
				"path": "",
				"children": [
					{
						"id": 67,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 39,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912487,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 67,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Company 3 ● "
					},
					{
						"id": 68,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 39,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912488,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 68,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Company 3 ● "
					},
					{
						"id": 69,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 39,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912489,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 69,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776414,
						"path": "Company 3 ● "
					},
					{
						"id": 154,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 39,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912514,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 154,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 3 ● "
					},
					{
						"id": 155,
						"editable": "Y",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 39,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912515,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 155,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 3 ● ",
						"children": [
							{
								"id": 156,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 155,
								"text": "Applicant Supplied information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912516,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 156,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 3 ● Details ● ",
								"children": [
									{
										"id": 157,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 156,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912517,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 157,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 3 ● Details ● Applicant Supplied information ● "
									},
									{
										"id": 158,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 156,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912518,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 158,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 3 ● Details ● Applicant Supplied information ● "
									},
									{
										"id": 159,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 156,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912519,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 159,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 3 ● Details ● Applicant Supplied information ● "
									},
									{
										"id": 160,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 156,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912520,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 160,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 3 ● Details ● Applicant Supplied information ● "
									}
								]
							},
							{
								"id": 161,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 155,
								"text": "Company Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912521,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 161,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 3 ● Details ● ",
								"children": [
									{
										"id": 162,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 161,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912522,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 162,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 3 ● Details ● Company Verified Information ● "
									},
									{
										"id": 163,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 161,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912523,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 163,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 3 ● Details ● Company Verified Information ● "
									},
									{
										"id": 164,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 161,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912524,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 164,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 3 ● Details ● Company Verified Information ● "
									},
									{
										"id": 165,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 161,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912525,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 165,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 3 ● Details ● Company Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 205,
				"editable": "Y",
				"position": 4,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Company 4",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912526,
				"bcc_id": 13228,
				"ctm_id": 3,
				"tmi_id": 205,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713783417,
				"path": "",
				"children": [
					{
						"id": 206,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 205,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912527,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 206,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 4 ● "
					},
					{
						"id": 207,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 205,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912528,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 207,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 4 ● "
					},
					{
						"id": 208,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 205,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912529,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 208,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 4 ● "
					},
					{
						"id": 209,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 205,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912530,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 209,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 4 ● "
					},
					{
						"id": 210,
						"editable": "Y",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 205,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912531,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 210,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 4 ● ",
						"children": [
							{
								"id": 211,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 210,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912532,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 211,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 4 ● Details ● ",
								"children": [
									{
										"id": 212,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 211,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912533,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 212,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 4 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 213,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 211,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912534,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 213,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 4 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 214,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 211,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912535,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 214,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 4 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 215,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 211,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912536,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 215,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 4 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 216,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 210,
								"text": "Company Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912537,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 216,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 4 ● Details ● ",
								"children": [
									{
										"id": 217,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 216,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912538,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 217,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 4 ● Details ● Company Verified Information ● "
									},
									{
										"id": 218,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 216,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912539,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 218,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 4 ● Details ● Company Verified Information ● "
									},
									{
										"id": 219,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 216,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912540,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 219,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 4 ● Details ● Company Verified Information ● "
									},
									{
										"id": 220,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 216,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912541,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 220,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 4 ● Details ● Company Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 221,
				"editable": "Y",
				"position": 5,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Company 5",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912542,
				"bcc_id": 13228,
				"ctm_id": 3,
				"tmi_id": 221,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713783422,
				"path": "",
				"children": [
					{
						"id": 222,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 221,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912543,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 222,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 5 ● "
					},
					{
						"id": 223,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 221,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912544,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 223,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 5 ● "
					},
					{
						"id": 224,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 221,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912545,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 224,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 5 ● "
					},
					{
						"id": 225,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 221,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912546,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 225,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 5 ● ",
						"children": [
							{
								"id": 241,
								"editable": "Y",
								"position": 4,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 225,
								"text": "Phone No",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912562,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 241,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 5 ● Phone Number ● "
							}
						]
					},
					{
						"id": 226,
						"editable": "Y",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 221,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912547,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 226,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 5 ● ",
						"children": [
							{
								"id": 227,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 226,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912548,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 227,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 5 ● Details ● ",
								"children": [
									{
										"id": 228,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 227,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912549,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 228,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 5 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 229,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 227,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912550,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 229,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 5 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 230,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 227,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912551,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 230,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 5 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 231,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 227,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912552,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 231,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 5 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 232,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 226,
								"text": "Company Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912553,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 232,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 5 ● Details ● ",
								"children": [
									{
										"id": 233,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 232,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912554,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 233,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 5 ● Details ● Company Verified Information ● "
									},
									{
										"id": 234,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 232,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912555,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 234,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 5 ● Details ● Company Verified Information ● "
									},
									{
										"id": 235,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 232,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912556,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 235,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 5 ● Details ● Company Verified Information ● "
									},
									{
										"id": 236,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 232,
										"text": "Reasons for Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912557,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 236,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 5 ● Details ● Company Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 237,
				"editable": "Y",
				"position": 6,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Company 6",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912558,
				"bcc_id": 13228,
				"ctm_id": 3,
				"tmi_id": 237,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713783424,
				"path": "",
				"children": [
					{
						"id": 238,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 237,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912559,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 238,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 6 ● "
					},
					{
						"id": 239,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 237,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912560,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 239,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 6 ● "
					},
					{
						"id": 240,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 237,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912561,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 240,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 6 ● "
					},
					{
						"id": 269,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 237,
						"text": "Phone No",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912590,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 269,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 6 ● "
					},
					{
						"id": 242,
						"editable": "N",
						"position": 6,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 237,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912563,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 242,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 6 ● ",
						"children": [
							{
								"id": 243,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 242,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912564,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 243,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 6 ● Details ● ",
								"children": [
									{
										"id": 245,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 243,
										"text": "Dates Of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912566,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 245,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 6 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 246,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 243,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912567,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 246,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 6 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 247,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 243,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912568,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 247,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 6 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 248,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 243,
										"text": "Reasons For Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912569,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 248,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 6 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 244,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 242,
								"text": "Company Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912565,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 244,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 6 ● Details ● ",
								"children": [
									{
										"id": 249,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 244,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912570,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 249,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 6 ● Details ● Company Verified Information ● "
									},
									{
										"id": 250,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 244,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912571,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 250,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 6 ● Details ● Company Verified Information ● "
									},
									{
										"id": 252,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 244,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912573,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 252,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 6 ● Details ● Company Verified Information ● "
									},
									{
										"id": 251,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 244,
										"text": "Reasons For Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912572,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 251,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 6 ● Details ● Company Verified Information ● "
									}
								]
							}
						]
					}
				]
			},
			{
				"id": 253,
				"editable": "Y",
				"position": 7,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Company 7",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912574,
				"bcc_id": 13228,
				"ctm_id": 3,
				"tmi_id": 253,
				"remarks": null,
				"disabled": "Y",
				"action_time": 1713783426,
				"path": "",
				"children": [
					{
						"id": 254,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 253,
						"text": "Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912575,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 254,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 7 ● "
					},
					{
						"id": 255,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 253,
						"text": "Email Address",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912576,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 255,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 7 ● "
					},
					{
						"id": 256,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 253,
						"text": "Contact Person",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912577,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 256,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 7 ● "
					},
					{
						"id": 257,
						"editable": "Y",
						"position": 4,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 253,
						"text": "Phone Number",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912578,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 257,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 7 ● "
					},
					{
						"id": 258,
						"editable": "N",
						"position": 5,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 253,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912579,
						"bcc_id": 13228,
						"ctm_id": 3,
						"tmi_id": 258,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Company 7 ● ",
						"children": [
							{
								"id": 259,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 258,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912580,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 259,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 7 ● Details ● ",
								"children": [
									{
										"id": 261,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 259,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912582,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 261,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 7 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 262,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 259,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912583,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 262,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 7 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 263,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 259,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912584,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 263,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 7 ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 264,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 259,
										"text": "Reasons For Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912585,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 264,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 7 ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 260,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 258,
								"text": "Company Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2912581,
								"bcc_id": 13228,
								"ctm_id": 3,
								"tmi_id": 260,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Company 7 ● Details ● ",
								"children": [
									{
										"id": 265,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 260,
										"text": "Dates of Work",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912586,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 265,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 7 ● Details ● Company Verified Information ● "
									},
									{
										"id": 266,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 260,
										"text": "Position",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912587,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 266,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 7 ● Details ● Company Verified Information ● "
									},
									{
										"id": 267,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 260,
										"text": "Salary",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912588,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 267,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 7 ● Details ● Company Verified Information ● "
									},
									{
										"id": 268,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 260,
										"text": "Reasons For Leaving",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2912589,
										"bcc_id": 13228,
										"ctm_id": 3,
										"tmi_id": 268,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713776415,
										"path": "Company 7 ● Details ● Company Verified Information ● "
									}
								]
							}
						]
					}
				]
			}
		],
		"brc_id": 125952,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": 2,
		"disabled": "N",
		"action_time": 1713876633
	},
	{
		"ctm_id": 4,
		"division": "BCC",
		"check_title": "Social Media Checks",
		"code": "SM",
		"active": "Y",
		"position": 4,
		"items": [
			{
				"id": 34,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
					{
						"doc_id": 34320,
						"download_path": "https: \/\/smartvetting.spectrumnetworkpi.com: 443\/api\/bcc-check-documents\/download\/34320"
					}
				],
				"id_parent": 0,
				"text": "Linkedin",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912591,
				"bcc_id": 13228,
				"ctm_id": 4,
				"tmi_id": 34,
				"remarks": "Linkedin",
				"disabled": "N",
				"action_time": 1713784694,
				"path": "",
				"children": [
					{
						"id": 43,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 34,
						"text": "Name",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912596,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 43,
						"remarks": "Dickson Maina Karoki",
						"disabled": "N",
						"action_time": 1713784887,
						"path": "Linkedin ● "
					},
					{
						"id": 44,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 34,
						"text": "URL",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912597,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 44,
						"remarks": "https: \/\/www.linkedin.com\/in\/dickson-karoki-229991267\/?originalSubdomain=ke",
						"disabled": "N",
						"action_time": 1713784731,
						"path": "Linkedin ● "
					},
					{
						"id": 45,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 34,
						"text": "User Name",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912598,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 45,
						"remarks": "Dickson Karoki",
						"disabled": "N",
						"action_time": 1713784757,
						"path": "Linkedin ● "
					}
				]
			},
			{
				"id": 35,
				"editable": "Y",
				"position": 2,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Facebook",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912592,
				"bcc_id": 13228,
				"ctm_id": 4,
				"tmi_id": 35,
				"remarks": "Facebook",
				"disabled": "N",
				"action_time": 1713784782,
				"path": "",
				"children": [
					{
						"id": 42,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 35,
						"text": "Name",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912595,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 42,
						"remarks": "Dickson Maina Karoki",
						"disabled": "N",
						"action_time": 1713784799,
						"path": "Facebook ● "
					},
					{
						"id": 46,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 35,
						"text": "URL",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912599,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 46,
						"remarks": "No account matches the subject's official details",
						"disabled": "N",
						"action_time": 1713784897,
						"path": "Facebook ● "
					},
					{
						"id": 47,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 35,
						"text": "User Name",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912600,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 47,
						"remarks": "No account matches the subject's official details",
						"disabled": "N",
						"action_time": 1713784871,
						"path": "Facebook ● "
					}
				]
			},
			{
				"id": 36,
				"editable": "Y",
				"position": 3,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Twitter",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912593,
				"bcc_id": 13228,
				"ctm_id": 4,
				"tmi_id": 36,
				"remarks": "X",
				"disabled": "N",
				"action_time": 1713784876,
				"path": "",
				"children": [
					{
						"id": 48,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 36,
						"text": "Name",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912601,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 48,
						"remarks": "Dickson Maina Karoki",
						"disabled": "N",
						"action_time": 1713784893,
						"path": "Twitter ● "
					},
					{
						"id": 49,
						"editable": "Y",
						"position": 2,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 36,
						"text": "URL",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912602,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 49,
						"remarks": "No account matches the subject's official details",
						"disabled": "N",
						"action_time": 1713784901,
						"path": "Twitter ● "
					},
					{
						"id": 50,
						"editable": "Y",
						"position": 3,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 36,
						"text": "User Name",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912603,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 50,
						"remarks": "No account matches the subject's official details",
						"disabled": "N",
						"action_time": 1713784904,
						"path": "Twitter ● "
					}
				]
			},
			{
				"id": 37,
				"editable": "Y",
				"position": 4,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "General Google Search",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912594,
				"bcc_id": 13228,
				"ctm_id": 4,
				"tmi_id": 37,
				"remarks": "General Google Search",
				"disabled": "N",
				"action_time": 1713784915,
				"path": "",
				"children": [
					{
						"id": 71,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 37,
						"text": "URL",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "N",
						"rqc_id": 2912604,
						"bcc_id": 13228,
						"ctm_id": 4,
						"tmi_id": 71,
						"remarks": "No adverse information found",
						"disabled": "N",
						"action_time": 1713784925,
						"path": "General Google Search ● "
					}
				]
			}
		],
		"brc_id": 125953,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": 2,
		"disabled": "N",
		"action_time": 1713784928
	},
	{
		"ctm_id": 5,
		"division": "BCC",
		"check_title": "Criminal Checks",
		"code": "CC",
		"active": "Y",
		"position": 6,
		"items": [
			{
				"id": 111,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Criminal Checks",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912605,
				"bcc_id": 13228,
				"ctm_id": 5,
				"tmi_id": 111,
				"remarks": null,
				"disabled": "N",
				"action_time": 1713776415,
				"path": "",
				"children": [
					{
						"id": 193,
						"editable": "N",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 111,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912610,
						"bcc_id": 13228,
						"ctm_id": 5,
						"tmi_id": 193,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Criminal Checks ● ",
						"children": [
							{
								"id": 112,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 193,
								"text": "Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912606,
								"bcc_id": 13228,
								"ctm_id": 5,
								"tmi_id": 112,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Criminal Checks ● Details ● ",
								"children": [
									{
										"id": 114,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 112,
										"text": "Source",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912607,
										"bcc_id": 13228,
										"ctm_id": 5,
										"tmi_id": 114,
										"remarks": "Directorate of Criminal Investigations",
										"disabled": "N",
										"action_time": 1713785461,
										"path": "Criminal Checks ● Details ● Verified Information ● "
									},
									{
										"id": 115,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 112,
										"text": "Search Criteria",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912608,
										"bcc_id": 13228,
										"ctm_id": 5,
										"tmi_id": 115,
										"remarks": "We are waiting the candidate to provide current certificate of Good conduct.",
										"disabled": "N",
										"action_time": 1713785474,
										"path": "Criminal Checks ● Details ● Verified Information ● "
									},
									{
										"id": 116,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 112,
										"text": "Status",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912609,
										"bcc_id": 13228,
										"ctm_id": 5,
										"tmi_id": 116,
										"remarks": "We are waiting the candidate to provide current certificate of Good conduct.",
										"disabled": "N",
										"action_time": 1713785478,
										"path": "Criminal Checks ● Details ● Verified Information ● "
									}
								]
							}
						]
					}
				]
			}
		],
		"brc_id": 125954,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": 1,
		"disabled": "N",
		"action_time": 1713785510
	},
	{
		"ctm_id": 6,
		"division": "BCC",
		"check_title": "Credit\/Financial Checks",
		"code": "CF",
		"active": "Y",
		"position": 7,
		"items": [
			{
				"id": 117,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Credit\/Financial Checks",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2913439,
				"bcc_id": 13228,
				"ctm_id": 6,
				"tmi_id": 117,
				"remarks": null,
				"disabled": "N",
				"action_time": 1713785512,
				"path": "",
				"children": [
					{
						"id": 192,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 117,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2913444,
						"bcc_id": 13228,
						"ctm_id": 6,
						"tmi_id": 192,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713785512,
						"path": "Credit\/Financial Checks ● ",
						"children": [
							{
								"id": 123,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 192,
								"text": "Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2913443,
								"bcc_id": 13228,
								"ctm_id": 6,
								"tmi_id": 123,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713785512,
								"path": "Credit\/Financial Checks ● Details ● ",
								"children": [
									{
										"id": 119,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 123,
										"text": "Source",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2913440,
										"bcc_id": 13228,
										"ctm_id": 6,
										"tmi_id": 119,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785512,
										"path": "Credit\/Financial Checks ● Details ● Verified Information ● "
									},
									{
										"id": 120,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 123,
										"text": "Search Criteria",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2913441,
										"bcc_id": 13228,
										"ctm_id": 6,
										"tmi_id": 120,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785512,
										"path": "Credit\/Financial Checks ● Details ● Verified Information ● "
									},
									{
										"id": 122,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 123,
										"text": "Status",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2913442,
										"bcc_id": 13228,
										"ctm_id": 6,
										"tmi_id": 122,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785512,
										"path": "Credit\/Financial Checks ● Details ● Verified Information ● "
									}
								]
							}
						]
					}
				]
			}
		],
		"brc_id": 125992,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": null,
		"disabled": "Y",
		"action_time": 1713785512
	},
	{
		"ctm_id": 7,
		"division": "BCC",
		"check_title": "Identity Checks",
		"code": "IC",
		"active": null,
		"position": 5,
		"items": [
			{
				"id": 110,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Identity check",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2912625,
				"bcc_id": 13228,
				"ctm_id": 7,
				"tmi_id": 110,
				"remarks": null,
				"disabled": "N",
				"action_time": 1713776415,
				"path": "",
				"children": [
					{
						"id": 108,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 110,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2912623,
						"bcc_id": 13228,
						"ctm_id": 7,
						"tmi_id": 108,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713776415,
						"path": "Identity check ● ",
						"children": [
							{
								"id": 109,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 108,
								"text": "Verified information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "Y",
								"rqc_id": 2912624,
								"bcc_id": 13228,
								"ctm_id": 7,
								"tmi_id": 109,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713776415,
								"path": "Identity check ● Details ● ",
								"children": [
									{
										"id": 83,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Source",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912611,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 83,
										"remarks": "National Registration Bureau",
										"disabled": "N",
										"action_time": 1713785063,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 84,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Search Criteria",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912612,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 84,
										"remarks": "ID Number-35139363",
										"disabled": "N",
										"action_time": 1713785111,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 85,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Name",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912613,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 85,
										"remarks": "Dickson Maina Karoki",
										"disabled": "N",
										"action_time": 1713785138,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 86,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Date of Birth",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912614,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 86,
										"remarks": "29\/09\/1997",
										"disabled": "N",
										"action_time": 1713785176,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 87,
										"editable": "Y",
										"position": 5,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "District",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912615,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 87,
										"remarks": "Nyandarua",
										"disabled": "N",
										"action_time": 1713785197,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 88,
										"editable": "Y",
										"position": 6,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Division",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912616,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 88,
										"remarks": "Olkalau",
										"disabled": "N",
										"action_time": 1713785208,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 89,
										"editable": "Y",
										"position": 7,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Location",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912617,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 89,
										"remarks": "Olkalau",
										"disabled": "N",
										"action_time": 1713785320,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 90,
										"editable": "Y",
										"position": 8,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Sub Location",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912618,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 90,
										"remarks": "Upper Gilgil",
										"disabled": "N",
										"action_time": 1713785348,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 91,
										"editable": "Y",
										"position": 9,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Village",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912619,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 91,
										"remarks": "Uhuru",
										"disabled": "N",
										"action_time": 1713785359,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 92,
										"editable": "Y",
										"position": 10,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Father's Name",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912620,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 92,
										"remarks": "George Karoki Kariuki",
										"disabled": "N",
										"action_time": 1713785379,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 93,
										"editable": "Y",
										"position": 11,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Mothers Name",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912621,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 93,
										"remarks": "Teresia Wanjiku Karoki",
										"disabled": "N",
										"action_time": 1713785400,
										"path": "Identity check ● Details ● Verified information ● "
									},
									{
										"id": 94,
										"editable": "Y",
										"position": 12,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 109,
										"text": "Home Address",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2912622,
										"bcc_id": 13228,
										"ctm_id": 7,
										"tmi_id": 94,
										"remarks": "Othaya",
										"disabled": "N",
										"action_time": 1713785412,
										"path": "Identity check ● Details ● Verified information ● "
									}
								]
							}
						]
					}
				]
			}
		],
		"brc_id": 125955,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": 2,
		"disabled": "N",
		"action_time": 1713785426
	},
	{
		"ctm_id": 8,
		"division": "BCC",
		"check_title": "Residential Checks",
		"code": "RC",
		"active": null,
		"position": 8,
		"items": [
			{
				"id": 167,
				"editable": "N",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Residental Check",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2913445,
				"bcc_id": 13228,
				"ctm_id": 8,
				"tmi_id": 167,
				"remarks": null,
				"disabled": "N",
				"action_time": 1713785523,
				"path": "",
				"children": [
					{
						"id": 168,
						"editable": "Y",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 167,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2913446,
						"bcc_id": 13228,
						"ctm_id": 8,
						"tmi_id": 168,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713785523,
						"path": "Residental Check ● ",
						"children": [
							{
								"id": 194,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 168,
								"text": "Applicant Supplied Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2913452,
								"bcc_id": 13228,
								"ctm_id": 8,
								"tmi_id": 194,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713785523,
								"path": "Residental Check ● Details ● ",
								"children": [
									{
										"id": 195,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 194,
										"text": "Name of Landlord",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2913453,
										"bcc_id": 13228,
										"ctm_id": 8,
										"tmi_id": 195,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785523,
										"path": "Residental Check ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 196,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 194,
										"text": "Contacts of Landlord",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2913454,
										"bcc_id": 13228,
										"ctm_id": 8,
										"tmi_id": 196,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785523,
										"path": "Residental Check ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 197,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 194,
										"text": "Location",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2913455,
										"bcc_id": 13228,
										"ctm_id": 8,
										"tmi_id": 197,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785523,
										"path": "Residental Check ● Details ● Applicant Supplied Information ● "
									},
									{
										"id": 198,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 194,
										"text": "Period of Residence",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2913456,
										"bcc_id": 13228,
										"ctm_id": 8,
										"tmi_id": 198,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785523,
										"path": "Residental Check ● Details ● Applicant Supplied Information ● "
									}
								]
							},
							{
								"id": 169,
								"editable": "Y",
								"position": 2,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 168,
								"text": "Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2913447,
								"bcc_id": 13228,
								"ctm_id": 8,
								"tmi_id": 169,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713785523,
								"path": "Residental Check ● Details ● ",
								"children": [
									{
										"id": 170,
										"editable": "Y",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 169,
										"text": "Name of Landlord",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2913448,
										"bcc_id": 13228,
										"ctm_id": 8,
										"tmi_id": 170,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785523,
										"path": "Residental Check ● Details ● Verified Information ● "
									},
									{
										"id": 172,
										"editable": "Y",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 169,
										"text": "Contacts of Landlord",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2913450,
										"bcc_id": 13228,
										"ctm_id": 8,
										"tmi_id": 172,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785523,
										"path": "Residental Check ● Details ● Verified Information ● "
									},
									{
										"id": 171,
										"editable": "Y",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 169,
										"text": "Location",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2913449,
										"bcc_id": 13228,
										"ctm_id": 8,
										"tmi_id": 171,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785523,
										"path": "Residental Check ● Details ● Verified Information ● "
									},
									{
										"id": 174,
										"editable": "Y",
										"position": 4,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 169,
										"text": "Period of residence",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "Y",
										"rqc_id": 2913451,
										"bcc_id": 13228,
										"ctm_id": 8,
										"tmi_id": 174,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785523,
										"path": "Residental Check ● Details ● Verified Information ● "
									}
								]
							}
						]
					}
				]
			}
		],
		"brc_id": 125993,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": null,
		"disabled": "Y",
		"action_time": 1713785523
	},
	{
		"ctm_id": 10,
		"division": "BCC",
		"check_title": "World (Global) Sanction Checks",
		"code": "WG",
		"active": "Y",
		"position": 9,
		"items": [
			{
				"id": 176,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "World (Global) Sanction Checks",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2913457,
				"bcc_id": 13228,
				"ctm_id": 10,
				"tmi_id": 176,
				"remarks": null,
				"disabled": "N",
				"action_time": 1713785526,
				"path": ""
			}
		],
		"brc_id": 125994,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": null,
		"disabled": "Y",
		"action_time": 1713785526
	},
	{
		"ctm_id": 11,
		"division": "BCC",
		"check_title": "Litigation Checks",
		"code": "LC",
		"active": "Y",
		"position": 10,
		"items": [
			{
				"id": 199,
				"editable": "Y",
				"position": 1,
				"emails": [
				],
				"variable_width": "N",
				"separate_text": "N",
				"use_editor": "N",
				"documents": [
				],
				"id_parent": 0,
				"text": "Litigation Checks",
				"icon": "fa fa-folder icon-lg icon-state-danger",
				"comments": [
				],
				"state": 
				{
					"opened": false
				},
				"children_inline": "N",
				"rqc_id": 2913458,
				"bcc_id": 13228,
				"ctm_id": 11,
				"tmi_id": 199,
				"remarks": null,
				"disabled": "N",
				"action_time": 1713785527,
				"path": "",
				"children": [
					{
						"id": 200,
						"editable": "N",
						"position": 1,
						"emails": [
						],
						"variable_width": "N",
						"separate_text": "N",
						"use_editor": "N",
						"documents": [
						],
						"id_parent": 199,
						"text": "Details",
						"icon": "fa fa-folder icon-lg icon-state-success",
						"comments": [
						],
						"state": 
						{
							"opened": false
						},
						"children_inline": "Y",
						"rqc_id": 2913459,
						"bcc_id": 13228,
						"ctm_id": 11,
						"tmi_id": 200,
						"remarks": null,
						"disabled": "N",
						"action_time": 1713785527,
						"path": "Litigation Checks ● ",
						"children": [
							{
								"id": 201,
								"editable": "Y",
								"position": 1,
								"emails": [
								],
								"variable_width": "N",
								"separate_text": "N",
								"use_editor": "N",
								"documents": [
								],
								"id_parent": 200,
								"text": "Verified Information",
								"icon": "fa fa-folder icon-lg icon-state-success",
								"comments": [
								],
								"state": 
								{
									"opened": false
								},
								"children_inline": "N",
								"rqc_id": 2913460,
								"bcc_id": 13228,
								"ctm_id": 11,
								"tmi_id": 201,
								"remarks": null,
								"disabled": "N",
								"action_time": 1713785527,
								"path": "Litigation Checks ● Details ● ",
								"children": [
									{
										"id": 202,
										"editable": "N",
										"position": 1,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 201,
										"text": "Source",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2913461,
										"bcc_id": 13228,
										"ctm_id": 11,
										"tmi_id": 202,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785527,
										"path": "Litigation Checks ● Details ● Verified Information ● "
									},
									{
										"id": 203,
										"editable": "N",
										"position": 2,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 201,
										"text": "Search Criteria",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2913462,
										"bcc_id": 13228,
										"ctm_id": 11,
										"tmi_id": 203,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785527,
										"path": "Litigation Checks ● Details ● Verified Information ● "
									},
									{
										"id": 204,
										"editable": "N",
										"position": 3,
										"emails": [
										],
										"variable_width": "N",
										"separate_text": "N",
										"use_editor": "N",
										"documents": [
										],
										"id_parent": 201,
										"text": "Status",
										"icon": "fa fa-folder icon-lg icon-state-success",
										"comments": [
										],
										"state": 
										{
											"opened": false
										},
										"children_inline": "N",
										"rqc_id": 2913463,
										"bcc_id": 13228,
										"ctm_id": 11,
										"tmi_id": 204,
										"remarks": null,
										"disabled": "N",
										"action_time": 1713785527,
										"path": "Litigation Checks ● Details ● Verified Information ● "
									}
								]
							}
						]
					}
				]
			}
		],
		"brc_id": 125995,
		"bcc_id": 13228,
		"remarks": null,
		"status_id": null,
		"disabled": "Y",
		"action_time": 1713785527
	}
];