import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import LayoutFullpage from 'layout/LayoutFullpage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import { setIdentity } from 'reducers/authSlice';
import { DEFAULT_PATHS, REACT_HELMET_PROPS } from 'config/config';
import { SocialLoginTypesColors, UserTypes, USER_ROLE } from 'constants.js';
import { SocialLoginTypes } from 'constants.js';
import { authenticate } from 'actions/authorization';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export const AuthLeftSide = () => {
  const { formatMessage: f } = useIntl();
  return (
    <div className="min-h-100 d-flex align-items-center">
      <div className="w-100 w-lg-100 w-xxl-50">
        <div>
          <div className="mb-5 mt-2">
            <img alt="" height="200" src={REACT_HELMET_PROPS.logo} />
          </div>
          <div className="d-block">
            { f({ id: 'login.motto'}) }
          </div>
        </div>
      </div>
    </div>
  );
}

const Login = () => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().min(6, 'Must be at least 6 chars!').required('Password is required'),
  });

  const initialValues = { username:'', password: '', clientCode: ''};
  
  const onSubmit = async (values) => { 
    const {username,password,clientCode:company_code} = values;
    const {payload} = await dispatch(authenticate({username,password,company_code})); 

    if(payload?.token){
      const token = payload.token; 
      const {id,icon:thumb,userType,otpOn,completeSignup,passwordVerified, extraData} = payload; 
      const role = [UserTypes.CustomerAdmin,UserTypes.CustomerUser].indexOf(userType)>-1? USER_ROLE.Customer : USER_ROLE.System;

      dispatch(setIdentity({ 
        isLogin: true, 
        token, 
        otpOn, 
        otpValidated: 'N',
        completeSignup,
        passwordVerified, 
        user: { id, username, thumb, userType, role, extraData } 
      }));
    }
  }

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const rightSide = (
    <div className="sw-lg-100 min-h-100 bg-foreground d-flex justify-content-center align-items-center py-5">
      <div className="sw-lg-50 px-5">
        <div className="mb-5 h-100 d-flex flex-column justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-center mb-5 d-sm-none">
            <img alt="" height="100" src={REACT_HELMET_PROPS.brandLogo} />
          </div>
          <div className="mb-2 mt-5">
            <p className="h6">
              {f({id: 'login.via'})}
            </p>
          </div>
          <div className="d-flex flex-row m-3 gap-2">
            { 
              Object.keys(SocialLoginTypes).map(key => {
                return (
                  <div as="a" key={key}>
                    <div className="sw-5 sh-5 rounded-xl d-flex justify-content-center align-items-center border border-primary">
                      <i className={`d-inline-block text-primary icon-20 
                        ${SocialLoginTypesColors.get(SocialLoginTypes[key]).icon}`} />
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="mb-5 d-flex flex-column align-items-center">
          <p className="h6">{f({id: 'login.use-credentials'})}</p>
        </div>
        <div className='justify-content-between align-items-center'>
          <form id="loginForm" className="tooltip-end-bottom" onSubmit={handleSubmit}>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="user" />
              <Form.Control type="text" name="username" placeholder={f({id: 'username'})} value={values.username} onChange={handleChange} />
              {errors.username && touched.username && <div className="d-block invalid-tooltip">{errors.username}</div>}
            </div>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="key" />
              <Form.Control type="password" name="password" onChange={handleChange} value={values.password} placeholder={f({id: 'password'})} />
              <NavLink className="text-small position-absolute t-3 e-3" to="/forgot-password">
                {f({id: 'login.forgot'})}
              </NavLink>
              {errors.password && touched.password && <div className="d-block invalid-tooltip">{errors.password}</div>}
            </div>
            <Button size="lg" type="submit" className="w-100 mt-3">
              {f({id: 'login.sign-in'})}
            </Button>
            <div className="mt-5">
              <span className="text-muted">Are you a new user? please&nbsp;</span>
              <Link to={DEFAULT_PATHS.SIGNUP}>{f({id: 'menu.register'}).toLowerCase()}</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HtmlHead title={f({id:'menu.login'})} description={f({id:'menu.login'})} />
      <LayoutFullpage left={<AuthLeftSide />} right={rightSide} />
    </>
  );
};

export default Login;
