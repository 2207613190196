import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react';

const HtmlHead = ({ title = '', description = '', children = null }) => {
  return (
    <HelmetProvider>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {children && children}
      </Helmet>
    </HelmetProvider>
  );
};
export default HtmlHead;
