import api from './api';
import './data/apps.chat';
import './data/apps.contacts';
import './data/apps.events';
import './data/notifications';
import './data/module';
import './data/pricing.info';
import './data/feature';
import './data/currency.converter';
import './data/payment.provider';
import './data/package';
import './data/countries';
import './data/tax-code';

api.onAny().passThrough();
