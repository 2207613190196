import { DEFAULT_PATHS } from 'config/config';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const RouteItem = ({ redirect = false, exact = false, path, to, component }) => {
  const {isLogin,otpOn,otpValidated,completeSignup,passwordVerified} = useSelector((state) => state.identity);

  if (!path && !to) {
    return () => {};
  }

  /* redirect to login if it not any of these routes */
  if([DEFAULT_PATHS.SIGNUP,DEFAULT_PATHS.LOGIN,DEFAULT_PATHS.FORGOT_PASSWORD,DEFAULT_PATHS.RESET_PASSWORD,DEFAULT_PATHS.SIGN_CONSENT].indexOf(path)<0 && !isLogin){
      return <Redirect to = {DEFAULT_PATHS.LOGIN} />;
  }
  
  if(isLogin){
    /* redirect to dashboard if logged-in and accessing login*/
    if(path===DEFAULT_PATHS.LOGIN)
    {
      return <Redirect to = {DEFAULT_PATHS.USER_WELCOME} />;
    }
    else
    { 
      /* redirect to change password if password not verified */
      if(passwordVerified!=='Y' && path!==DEFAULT_PATHS.CHANGE_MY_PASSWORD){
        return <Redirect to = {DEFAULT_PATHS.CHANGE_MY_PASSWORD} />;
      }else{
        /* redirect to registration if signup is NOT complete */
        if(passwordVerified==='Y' && completeSignup!==true && path!==DEFAULT_PATHS.REGISTER){
          return <Redirect to = {DEFAULT_PATHS.REGISTER} />;
        }else{
          /* redirect to 2-factor authentication if not validated yet */
          if(passwordVerified==='Y' && completeSignup===true && otpOn==='Y' && otpValidated!=='Y' && path!==DEFAULT_PATHS.TWO_FACTOR){ 
            return <Redirect to = {DEFAULT_PATHS.TWO_FACTOR} />;
          }
          if(passwordVerified==='Y' && completeSignup===true && otpValidated==='Y' && path===DEFAULT_PATHS.TWO_FACTOR){ 
            return <Redirect to = {DEFAULT_PATHS.USER_WELCOME} />;
          }
        }
      }
    }
  }

  if (redirect) {
    const props = {};
    if (path) props.from = path;
    if (exact) props.exact = exact;
    if (to) props.to = to;
    return <Redirect {...props} />;
  }
  const props = {};
  if (path) props.path = path;
  if (exact) props.exact = exact;
  if (component) props.component = component;

  return <Route {...props} />;
};

export default memo(RouteItem);
