import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
  checkTree: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
  currentCheck: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
}

const candidateAiSlice = createSlice({
  name: 'candidateAi',
  initialState: initialState,
  reducers: {
    setCandidate: (state,action)=>{ 
      state.profile.data = action?.payload || state.profile.data;
    },
    setCandidates: (state,action)=>{ 
      state.all.data = action?.payload || state.all.data;
    },
    setCheckTree: (state,action)=>{ 
      state.checkTree.data = action?.payload || state.checkTree.data;
    },
    setCheckCurrentItem: (state,action)=>{ 
      state.currentCheck.data = action?.payload || state.currentCheck.data;
    },
  },
  extraReducers: (builder) => {
    
  }
});

const candidateAiReducer = candidateAiSlice.reducer;
export const { setCandidate, setCandidates, setCheckTree, setCheckCurrentItem } = candidateAiSlice.actions;
export default candidateAiReducer;