import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    isLoading: false,
    message: '',
    error: false,
    data: {}
  },
  all: {
    isLoading: false,
    message: '',
    error: false,
    data: []
  },
}

const customerAiSlice = createSlice({
  name: 'customerAi',
  initialState: initialState,
  reducers: {
    setCustomer: (state,action)=>{ 
      state.profile.data = action?.payload || state.data;
    },
    setCustomers: (state,action)=>{ 
      state.all.data = action?.payload || state.data;
    }
  },
  extraReducers: (builder) => {

  }
});

const customerAiReducer = customerAiSlice.reducer;
export const { setCustomer, setCustomers } = customerAiSlice.actions;
export default customerAiReducer;